var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.rowData,"loading":_vm.isLoading,"items-per-page":_vm.limit,"page":_vm.page,"server-items-length":_vm.paginationData.total_data || 0,"loading-text":"Loading... Please wait"},on:{"update:items-per-page":_vm.handleLimitChange,"update:page":_vm.handlePageChanged},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"capitalize my-3",attrs:{"color":_vm.getStatusColor(item.status),"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-3 d-flex"},[_c('div',{staticClass:"mr-3 d-flex justify-center align-center"},[_c('img',{staticStyle:{"width":"70%"},attrs:{"src":require("@/assets/images/materai-icon.png"),"alt":""}})]),_c('div',[(item.product === 'e-Meterai')?_c('h4',[_vm._v("EMET")]):_c('h4',[_vm._v("ESGN")]),_c('div',{staticStyle:{"border-top":"0px"}},_vm._l((_vm.getJson(item.productDescription)),function(facilities,index){return _c('div',{key:'A' + index},[_c('p',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(facilities)+" ")])])}),0)])])]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"capitalize"},[_vm._v(_vm._s(item.payment))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',[_vm._v("Rp. "+_vm._s(_vm.formatToPrice(item.amount)))])])]}},{key:"item.invoiceId",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('a',{attrs:{"href":item.invoice_url,"target":"_blank"}},[_vm._v(_vm._s(item.invoiceId))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-underline",attrs:{"to":{ name: 'detail-purchase', params: { id: item.id } }}},[_vm._v("Invoice")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }