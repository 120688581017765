<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">
      {{ name }}
    </v-card-title>
    <v-card class="pricing-card">

      <div>
        <section class="pricing-wrapper" v-if="!isLoading">
          <div>
            <div class="pricing-card">
              <div>
                <div class="pricing-card-header">
                  <img
                    class="pricing-card-header__img"
                    src="@/assets/images/materai-icon.png"
                    alt="icon"
                    draggable="false"
                    loading="lazy"
                  />
                  <div>
                    <h1 class="pricing-card-header__title">
                      {{ pricings.name }}
                    </h1>
                    <p class="pricing-header__price">
                      Rp. {{ formatToPrice(pricings.price) }}
                    </p>
                  </div>
                </div>
                <div class="pricing-card-feature">
                  <ul class="pricing-card-feature-list">
                    <li
                      v-for="(facilities, index) in pricings.description"
                      :key="index"
                      class="pricing-card-feature-item"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="pricing-card-feature-item__icon"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <p class="pricing-card-feature-item__title">
                        {{ facilities }}
                      </p>
                    </li>
                  </ul>
                </div>

                <v-divider></v-divider>

                <!-- <div class="pricing-card-counter-price">
                  <div>
                    <h2 class="card-header__card-subtitle-h2">
                      80 <span style="color: #FBA733; text-transform: uppercase;"> emet</span>
                    </h2>
                  </div>
                  <div>
                    <v-btn-toggle>
                      <v-btn>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      </v-btn>

                      <v-btn>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M20 12H4"
                          />
                        </svg>
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </div> -->
                <div class="pricing-card-counter-price" style="align-items: center;">
                  <div class="d-flex" style="align-items: baseline;">
                    <h2 class="mr-1">
                      {{ this.selectedItem.qty || 0 }}
                    </h2>
                    <h4>
                      <span v-if="pricings.name === 'e-Meterai'" style="color: #FBA733">EMET</span>
                      <span v-else style="color: #25BCD6">ESGN</span>
                      <!-- <span v-if="pricings.name === 'e-signature'" style="color: #25BCD6">ESGN</span> -->
                    </h4>
                  </div>
                  <div>
                    <v-btn-toggle>
                      <v-btn @click="addToCart()">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      </v-btn>

                      <v-btn @click="reduceToCart()" :disabled="this.selectedItem.qty === 0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M20 12H4"
                          />
                        </svg>
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </div>

                <v-divider></v-divider>

                <div class="pricing-card-price">
                  <h3>Rp {{ formatToPrice(pricings.price * pricings.qty) }}</h3>
                </div>

                <v-divider></v-divider>

              </div>
              <v-card-actions class="pricing-card-action">
                <v-btn
                  class="pricing-card-action__button"
                  width="100%"
                  height="90px"
                  color="primary"
                  :to="{
                    name: 'detail-paket-signature',
                    params: {id: pricings.id},
                  }"
                >
                  Beli e-Meterai
                </v-btn>
              </v-card-actions>
            </div>
          </div>
        </section>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import {onMounted, reactive, toRefs} from "@vue/composition-api";
import axios from "axios";
import {formatPrice} from "../../../lib/formatDate";

export default {
  setup(props, ctx) {
  // setup() {
    const route = ctx.root._route;
    const state = reactive({
      name: "Beli e-Meterai",
      pricings: {},
      isLoading: false,
      tabs: "all",
      addToCartDisabled: false,
      createOrder: {
        product_id: 0,
        qty: 0,
      },
      selectedItem: {},
    });

    const changeTab = (val) => {
      state.tabs = val;
    };

    onMounted(async () => {
      state.isLoading = true;
      let {data} = await axios.get(
        "econtract/inventory/pricing/" + route.params.id
        // "https://momofin.herokuapp.com/v1/e-signature/pricing/" + route.params.id
      );
      state.pricings = data.data;
      // console.log('object', state.pricings);
      state.isLoading = false;
    });

    const addToCart = () => {
      // state.selectedItem = state.pricings[id];
      console.log(state.selectedItem)
      if(!state.selectedItem.qty) {
        state.selectedItem.qty = 1;
      } else {
        state.selectedItem.qty += 1;
      }
      // state.pricings[id] = state.selectedItem;
      // console.log(state.pricings[id]);
    };

    const reduceToCart = () => {
      // state.selectedItem = state.pricings[id];
      state.selectedItem.qty -= 1;
      // state.pricings[id] = state.selectedItem;
    };

    const formatToPrice = (value) => {
      return formatPrice(value);
    };

    return {
      formatToPrice,
      changeTab,
      addToCart,
      reduceToCart,
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
  padding: 0;
}
.pricing-wrapper {
  padding: 0 10px;
  margin: 40px 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (min-width: 768px) {
    gap: 43px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.pricing {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    &__tabs {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 400px;
      li {
        font-size: small;
      }
    }
  }
  &-card {
    border-radius: 12px;
    padding: 10px;
    border: solid 1px #e0e0e0 !important;
    &-header {
      display: flex;
      place-items: center;
      padding: 20px 0;
      padding-top: 10;
      &__img {
        margin-right: 0.5rem;
      }
      &__title {
        font-size: 1rem;
      }
      &__price {
        font-size: 0.5rem;
        font-weight: bold;
      }
    }
    &-feature {
      &-list {
        border-top: solid #e0e0e0 1px;
        list-style: none;
        min-height: 100px;
        padding: 20px 0 !important;
      }
      &-item {
        display: flex;
        place-items: center;
        padding: 5px;
        &__title {
          margin: 0;
          margin-bottom: 0 !important;
        }
        &__icon {
          margin-right: 5px;
          width: 20px;
          color: #9ad4fb;
        }
      }
    }
    &-counter-price {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
    }
    &-action {
      padding: 20px 0;
      &__button {
        height: 44px !important;
        border-radius: 6px;
      }
    }
    &-price h3 {
      margin: 1rem 0;
    }
  }
}
</style>
