<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="text-black pt-0 pb-0 px-0 fs-20 font-weight-bold">Inventory</v-card-title>

    <v-card-text class="px-0">
      <div class="mt-5">
        <div class="table">          
          <v-card class="pb-1">
            <v-card-title class="font-weight-regular">
              <div class="row">
                <div class="col-md-6 d-flex align-center">
                  <p class="fs-16 text-black font-weight-medium mb-0">List of Your Item</p>
                </div>
                <div class="col-md-6 text-right">
                  <v-btn color="primary" class="white--text ma-1" large :to="{ name: 'e-signature-pricing'}">
                    BELI PRODUK LENGKAP
                  </v-btn>
                </div>
              </div>
            </v-card-title>

            <!-- <div v-if="!isLoading" class="pricing-wrapper"> -->
            <div class="pricing-wrapper">
              <div v-for="(pricing, index) in pricings" :key="index">
                <div class="pricing-card">
                  <div>
                    <div class="pricing-card-header">
                      <img
                        class="pricing-card-header__img"
                        :src="require(`@/assets/images/${pricing.img}.png`)"
                        alt="icon"
                        draggable="false"
                        loading="lazy"
                      />
                      <div>
                        <h1 class="pricing-card-header__title">
                          {{ pricing.title }}
                        </h1>
                      </div>
                    </div>
                    <div class="pricing-card-feature">
                      <template v-if="pricing.type == 'emet'">
                        <h2 class="card-header__card-subtitle-h2">
                          {{ pricing.price }} <span style="color: #FBA733"> {{ pricing.type }}</span>
                        </h2>
                      </template>
                      <template v-else-if="pricing.type == 'esgn'">
                        <h2 class="card-header__card-subtitle-h2">
                          {{ pricing.price }} <span style="color: #25BCD6"> {{ pricing.type }}</span>
                        </h2>
                      </template>
                      <template v-else>
                        <h2 class="card-header__card-subtitle-h2">
                          {{ pricing.price }} <span style="color: #189C08"> {{ pricing.type }}</span>
                        </h2>
                      </template>
                    </div>
                  </div>
                  <v-card-actions class="pricing-card-action">
                    <v-btn
                      class="pricing-card-action__button"
                      width="100%"
                      height="90px"
                      color="primary"
                      :to="{ name: 'detail-paket-meterai', params: { id: pricing.id }}"
                    >
                      <template v-if="pricing.id == 1">
                        Beli e-Meterai
                      </template>
                      <template v-else-if="pricing.id == 2">
                        Beli e-Signature
                      </template>
                      <template v-else>
                        Beli e-Stamp
                      </template>
                    </v-btn>

                    <v-btn
                      class="pricing-card-action__button mt-3"
                      width="100%"
                      height="90px"
                      :to="{ name: 'detail-paket-meterai', params: { id: pricing.id }}"
                    >
                      <template v-if="pricing.id == 1">
                        Jual e-Meterai
                      </template>
                      <template v-else-if="pricing.id == 2">
                        Jual e-Signature
                      </template>
                      <template v-else>
                        Jual e-Stamp
                      </template>
                    </v-btn>
                  </v-card-actions>
                </div>
              </div>
            </div>
          </v-card>
        </div>        
      </div>
    </v-card-text>

  </v-card>
</template>

<script>
  import {reactive, toRefs, onMounted} from "@vue/composition-api";
  // import { formatNumber } from "@/lib/formatDate";
  // import axios from 'axios';

  const pricing = [
    {
      id: 1,
      img: "inventory",
      title: "e-Meterai",
      price: "1.180",
      type: "emet",
      action: 'Beli Bundling'
    },
    {
      id: 2,
      img: "api-seeting",
      title: "e-Signature",
      price: "2.200",
      type: "esgn",
      action: 'Beli Bundling'
    },
    {
      id: 3,
      img: "api-seeting",
      title: "e-Stamp",
      price: "2.430",
      type: "estp",
      action: 'Beli Bundling'
    },
  ];

  export default {
    setup() {
      const state = reactive({
        pricings: null,
        isLoading: true
      });

      onMounted(async ()=>{
        state.isLoading = true;
        // let { data:data } = await axios.get('https://momofin.herokuapp.com/v1/e-signature/pricing')
        state.pricings = pricing
        state.isLoading = false
      })

      return {
        ...toRefs(state),
      };
    },
  };
</script>

<style lang="scss" scoped>
  p {
    margin: 0;
    padding: 0;
  }
  .pricing-wrapper {
    padding: 0 10px;
    margin: 40px 0;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @media (min-width: 768px) {
      gap: 43px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  .pricing {
    &-header {
      display: flex;
      justify-content: space-between;
      padding: 20px 10px;
    }
    &-card {
      border-radius: 12px;
      text-align: center;
      padding: 10px;
      border: solid 1px #e0e0e0 !important;
      &-header {
        display: flex;
        justify-content: center;
        place-items: center;
        padding: 20px 0;
        padding-top:10;
        &__img {
          margin-right: 0.5rem;
        }
        &__title {
          font-size: 1rem;
        }
        &__price {
          font-size: 0.5rem;
          font-weight: bold;
        }
      }
      &-feature {
        &__poin {
          color: #4C6583;
          font-weight: bold;
        }
        & span {
          text-transform: uppercase;
        }
      }
      &-action {
        padding: 20px 0;
        flex-direction: column;
        &__button {
          height: 44px !important;
          border-radius: 6px;
        }
      }
    }
  }
</style>