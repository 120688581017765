<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="text-black pt-0 pb-0 px-0 fs-20 font-weight-bold"
      >Beli Paket Bundling C</v-card-title
    >

    <v-row>
      <v-col cols="8">
        <v-card-text class="px-0">
          <v-form ref="form" @submit.prevent="submit" v-model="valid">
            <v-card elevation="1">
              <div class="px-3 py-3">
                <v-row class="px-3 py-4">
                  <v-col cols="12">
                    <div class="d-flex">
                      <div class="mr-5">
                        <img
                          src="/assets/emet.png"
                          alt=""
                          draggable="false"
                        />
                        <img
                          src="/assets/api-seeting.png"
                          alt=""
                          style="width:30px;position: absolute;left: 46px;top: 50px;"
                          draggable="false"
                        />
                      </div>
                      <div>
                        <p class="text-black font-weight-thin fs-14 mb-0">
                          {{ pricing && pricing.title }}
                        </p>
                        <h3 class="text-black mb-2">
                          Rp. {{ formatPrice(pricing && pricing.price) }}
                        </h3>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row v-if="!isLoading" class="pb-3 mt-3 px-3">
                  <v-col cols="12">
                    <ul class="list-paket">
                      <li
                        v-for="(price, list) in pricing.items"
                        :key="list"
                        class="d-flex align-center"
                      >
                        <img src="/assets/checklist.png" alt="" />
                        <span class="ml-3">{{ price }}</span>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <div class="px-3 py-3">
                <v-row class="py-3 px-3">
                  <v-col cols="12">
                    <p class="font-weight-bold text-black mb-0">
                      Select a funding source
                    </p>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <div class="px-3 py-3 bg-grey">
                <v-row class="py-6 px-3">
                  <v-col cols="6">
                    <p class="font-weight-thin text-black mb-0">Your Balance</p>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex align-center justify-content-end"
                  >
                    <div class="d-flex">
                      <h2 class="text-black mr-2">
                        Rp. {{ formatPrice(20000000) }}
                      </h2>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <div class="px-3 py-3">
                <v-row class="py-4 px-3">
                  <v-col cols="12">
                    <v-radio-group
                      v-model="serverParams.payment_channel"
                      row
                      :rules="[(v) => !!v || 'Type disbursement is required']"
                      class="mt-0"
                      @change="checkBankTransfer(serverParams.payment_channel)"
                    >
                      <v-radio
                        :disabled="disabled"
                        class="type_fund"
                        label="Use Balance"
                        value="balance"
                      ></v-radio>
                      <v-radio
                        class="type_fund"
                        label="Bank Transfer"
                        value="transfer"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row class="pb-6 px-3 mt-0" v-show="showBankList">
                  <v-col cols="12" class="d-flex list-bank">
                    <v-radio-group
                      v-model="serverParams.bank_id"
                      row
                      v-for="(list, index) in listBank"
                      :key="index"
                    >
                      <v-radio class="deposit" :value="list.id">
                        <template v-slot:label>
                          <div><img :src="list.image" /></div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <div class="px-3 py-3">
                <v-row class="py-4 px-3">
                  <v-col cols="12">
                    <p class="font-weight-thin text-black mb-0">
                      You have the option to process the transaction with your
                      balance or with a direct bank transfer. If your balance is
                      insufficient, your transaction will be completed by bank
                      transfer.
                    </p>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <v-card-actions>
                <div class="row">
                  <div class="col-sm-12 text-right px-0">
                    <v-btn
                      color="primary"
                      outlined
                      class="mr-2 px-5"
                      :to="{name: 'inventory'}"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      class="px-3"
                      @click="confirmTransfer()"
                      :loading="submiting"
                    >
                      Continue
                    </v-btn>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>

          <v-snackbar v-model="isError">
            {{ showMessage }}
            <template v-slot:action="{attrs}">
              <v-btn color="pink" text v-bind="attrs" @click="isError = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card-text>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="700" persistent>
      <v-card class="">
        <div class="text-end">
          <v-btn
            icon
            dark
            @click="dialog = false"
            color="#333"
            class="float-right"
          >
            <v-icon>ri-close-fill</v-icon>
          </v-btn>
        </div>

        <v-card-text class="my-3 text-center py-5">
          <img src="@/assets/images/img-otp.png" class="mb-3" />
          <p class="text-black fs-16 font-weight-medium">
            Please input your PIN
          </p>
          <v-row>
            <v-col id="kode" cols="12" sm="8" class="mx-auto">
              <form @submit.prevent="handleSubmit()" ref="pinForm">
                <PincodeInput autocomplete="false" autofocus v-model="code" secure change="" :value="code" :length="6" />
                <!-- <input
                  v-for="input in 6"
                  :key="input"
                  ref="pin1"
                  required
                  class="pin-input"
                  type="password"
                  :maxlength="1"
                  min="0"
                  @focus="focusIndex = input"
                  @keydown.delete="onDeletePin(input)"
                 
                /> -->

              <v-btn
                color="primary"
                dark
                type="submit"
                class="mt-4"
                :loading="isLoading"
              >
                Continue
              </v-btn>
              </form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogLoading" width="700" persistent>
      <v-card>
        <v-icon
          class="px-4 py-4"
          small
          color="#CFD8DC"
          @click="dialogLoading = false"
          >ri-close-line</v-icon
        >
        <v-row class="my-0 mx-0 px-5 py-5">
          <v-col cols="12">
            <div
              class="d-flex align-center justify-content-center"
              style="gap: 20px;"
            >
              <img src="@/assets/images/biru.png" />
              <img src="@/assets/images/loading.png" />
              <img src="@/assets/images/orange.png" />
            </div>
            <div class="mt-5 text-center">
              <p class="font-weight-bold mb-2 text-black">
                Kami sedang mengecek Transferanmu
              </p>
              <p class="font-weight-thin mb-0 text-black">
                It may take a while to complete the transaction
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {reactive, toRefs, onMounted} from "@vue/composition-api";
import axios from "axios";
import {formatPrice} from "@/lib/formatDate";
import PincodeInput from "vue-pincode-input";

export default {
  setup(props, ctx) {
    const route = ctx.root._route;
    const state = reactive({
      pricing: null,
      isLoading: true,
    });

    onMounted(async () => {
      state.isLoading = true;
      let {data: data} = await axios.get(
        "https://momofin.herokuapp.com/v1/e-signature/pricing/" + route.params.id
      );
      state.pricing = data.data;
      state.isLoading = false;
    });

    return {
      ...toRefs(state),
    };
  },
  components: {
    PincodeInput,
  },
  data: () => ({
    valid: true,
    dialogLoading: false,
    dialog: false,
    isError: false,
    // isLoading: false,
    submiting: false,
    showMessage: "",
    disabled: false,
    showBankList: false,
    paramOtp: {
      disbursement_id: "",
    },
    code: "",
    dataDetail: {},
    nameRules: [
      (v) => !!v || "Pin is required",
      (v) => (v && v.length == 6) || "Pin must be 6 characters",
    ],
    serverParams: {
      disbursement_id: "",
      otp_code: "",
      payment_channel: "",
    },
    listBank: [],
    focusIndex: 0,
  }),
  mounted() {
    // this.getListDisbursmentDetail();
    this.getListBankDestination();
  },
  watch: {
    code() {
      if(this.code.length === 6 ) this.handleSubmit()
    }
  },
  computed: {
    idParam: {
      get() {
        return this.$route.params.id;
      },
    },
  },
  methods: {
    getListBankDestination() {
      this.$store
        .dispatch("getListBankDestination")
        .then((response) => {
          // const { data } = response;
          this.listBank = response.data.data;
          // console.log('cek', this.idParam, this.typeParam );
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async confirmTransfer() {
      this.dialog = true;
    },

    async handleSubmit() {
      this.dialogLoading = true;
      setTimeout(()=> {
        this.code = ""
      }, 2000)
    },

    checkBankTransfer(type) {
      if (type === "transfer") {
        this.disabled = true;
        this.showBankList = true;
      }
    },

    formatPrice(value) {
      return formatPrice(value);
    },

    validateField() {
      this.$refs.form.validate();
    },

    onDeletePin() {
      this.$refs.pinForm[this.focusIndex - 2].focus();
    },

    onInputPin() {
      if (this.code.length === 6) this.handleSubmit();
    },
  },
};
</script>

<style scoped lang="scss">
div.vue-pincode-input-wrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  * {
    border-bottom: solid 2px #e4e4e7 !important;
    padding: 30px 10px !important;
    font-size: 20px !important;
    text-align: center;
  }
  input.vue-pincode-input {
    border-bottom: solid 2px #e4e4e7 !important;
    padding: 30px 10px !important;
    font-size: 20px !important;
    text-align: center;
    max-width: auto;
    padding: 27px;
  }
}
input.vue-pincode-input {
  border-bottom: solid 2px #e4e4e7 !important;
  padding: 30px 10px !important;
  font-size: 20px !important;
  text-align: center;
  max-width: auto;
  padding: 27px;
}
/* .pin {
  &-wraper {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  &-input {
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
    border-bottom: solid 2px #e4e4e7;
    padding: 30px 10px;
    font-size: 20px;
    text-align: center;
  }
  input[type="number"] {
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
  }
} */

.justify-content-end {
  justify-content: end;
}
.justify-content-center {
  justify-content: center;
}
.bg-grey {
  background-color: #f9f9f9;
}
.v-radio.type_fund.v-radio--is-disabled {
  border-color: #e0e0e0;
  background-color: #bdbdbd;
  color: #eeeeee !important;
}
.v-radio.type_fund.v-item--active {
  border-color: #0068d6;
  background-color: #0068d6;
  color: #fff !important;
}
.v-radio.type_fund:hover {
  border-color: #0159b6;
  background-color: #0159b6;
  color: #fff !important;
}

.v-radio.type_fund {
  padding: 10px 20px 10px 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
}
.list-paket {
  list-style: none;
  padding-left: 0;
}
.list-paket li {
  margin-bottom: 5px;
}
</style>

<style>
.v-radio.type_fund .v-input--selection-controls__input {
  display: none !important;
}
.type_fund .theme--light.v-label {
  color: inherit;
}

.v-radio.deposit.theme--light.v-item--active {
  border: 2px solid #409eff;
}
.v-radio.deposit:hover {
  border-color: #409eff;
}
.v-radio.deposit .v-input--selection-controls__input {
  display: none;
}
.v-radio.deposit {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
}
.v-radio.deposit img {
  width: 100px;
}
.deposit .theme--light.v-label {
  color: inherit;
}
.d-flex.list-bank .v-input {
  margin: 0;
}
</style>
