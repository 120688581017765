export default { 
  History: require('./history').default,

  Disbursement: require('./disbursement').default,
  DisbursementDetail: require('./disbursementDetail').default,
  DisbursementDetailNeedValidation: require('./disbursementDetailNeedValidation').default,
  DisbursementDetailNeedApproval: require('./disbursementDetailNeedApproval').default,
  DisbursementDetailApproved: require('./disbursementDetailApproved').default,
  DisbursementRilis: require('./disbursementRilis').default,
  DisbursementCreate: require('./disbursementCreate').default,
  DisbursementEdit: require('./disbursementEdit').default,
  SingleDisbursement: require('./singleDisbursement').default,

  Bulk: require('./bulkDisbursement').default,
  BulkDisbursementCreate: require('./bulkDisbursementCreate').default,
  BulkDisbursementEdit: require('./bulkDisbursementEdit').default,
  BulkCreate: require('./bulkCreate').default,
  BulkDetailAll: require('./bulkDetailAll').default,
  BulkDetail: require('./bulkDetail').default,
  BulkDetailNeed: require('./bulkDetailNeedApprov').default,
  BulkDetailApproved: require('./bulkDetailApproved').default,
  BulkRilisDisbursement: require('./bulkRilisDisbursement').default,

  Schedule: require('./schedule').default,
  ScheduleDetail: require('./scheduleDetail').default,
  ScheduleCreate: require('./scheduleCreate').default,
  ReceiveDetail: require('./receiveDetail').default,
  ReceiveCreate: require('./receiveCreate').default,
  ReceivePayment: require('./receivePayment').default,
  ReceiveIncoming: require('./receiveIncoming').default,
  ReceiveInvoice: require('./receiveInvoice').default,
  ReceiveInvoiceCreate: require('./receiveInvoiceCreate').default,
  ReceiveInvoiceDetail: require('./receiveInvoiceDetail').default,
  receiveEmailInvoice: require('./receiveEmailInvoice').default,
}