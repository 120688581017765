<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">
      Buat Sertifikat
    </v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="table my-3">
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <v-row align="center">
                <v-col lg="6">
                  <p class="fs-16 text-black font-weight-medium">
                    Choose the Certificate Template You Want to Use
                  </p>
                </v-col>

                <v-col
                  id="search"
                  lg="6"
                  class="justify-end align-end text-right"
                >
                </v-col>
              </v-row>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    outlined
                    append-icon="$search"
                    class="mt-1 ms-2 mx-width-85"
                    placeholder="Search"
                    solo
                    v-model="search"
                    @change="searchQuery"
                    flat
                  ></v-text-field>
                </div>
                <div class="col-md-6 text-end align-end">
                  <v-text-field
                    outlined
                    append-icon="ri-filter-fill"
                    class="mt-1 ms-2 mx-width-85 float-right"
                    placeholder="Filter"
                    solo
                    flat
                  ></v-text-field>
                </div>
              </div>
            </v-card-title>
            <div class="col-md-12 px-0 py-4">
              <v-divider></v-divider>
            </div>
            <div v-if="!data.template || isLoading" class="my-5">
              <div class="col-md-6 mx-auto text-center" v-if="!isLoading">
                <img class="mx-auto" src="@/assets/images/certif-empty.svg" />
                <p class="font-weight-bold text-black mb-2">
                  Empty Certificate Template
                </p>
                <p class="text-semu">
                  Create a new certificate or try to narrow the search if you
                  can't find the certificate you're looking for
                </p>
              </div>
              <div class="row px-10 py-10" v-else>
                <v-skeleton-loader
                  v-for="n in 24"
                  :key="n"
                  class="col-md-4"
                  type="card"
                ></v-skeleton-loader>
              </div>
            </div>
            <div v-else class="template px-2">
              <div v-if="data.template.length < 1">
                <div class="col-md-6 mx-auto">
                  <img src="@/assets/images/certif-empty.svg" />
                  <p class="font-weight-bold text-black mb-2">
                    Sertifikat Kosong
                  </p>
                  <p class="text-semu">
                    Create a new certificate or try to narrow the search if you
                    can't find the certificate you're looking for
                  </p>
                </div>
              </div>
              <div class="row wraper ">
                <div v-if="data.template" class="col-md-4 mx-auto">
                  <router-link
                    :to="{
                      name: 'create-template',
                    }"
                  >
                    <div class="create-card">
                      <div>
                        <img src="@/assets/images/create.png" alt="" />
                        <h1>
                          Create New Template
                        </h1>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div
                  class="col-md-4"
                  v-for="(template, i) in data.template"
                  :key="i"
                >
                  <label :id="template.id">
                    <input
                      class="select-template"
                      v-model="templateId"
                      :id="template.id"
                      :value="template.id"
                      type="radio"
                    />
                    <v-card class="template" elevation="1">
                      <img
                        :src="
                          template.preview || template || 'https://www.russorizio.com/wp-content/uploads/2016/07/ef3-placeholder-image.jpg'"
                        style="width: 100%; min-height: 250px; object-fit: cover; max-height: 250px;"
                      />
                      <p class="px-3 fs-16 text-black font-weight-medium">
                        {{ template && template.title }}
                      </p>
                      <v-card-text class="py-1 px-3">
                        <v-row align="center" class="mx-0">
                          <div class="col-md-6 px-0">
                            <p class="text-semu fs-12 font-weight-medium mb-2">
                              <v-icon small>ri-calendar-check-line</v-icon>
                              {{
                                template.created_at
                                  | moment("dddd, D MMMM YYYY")
                              }}
                            </p>
                          </div>
                          <div class="col-md-6 px-0">
                            <p class="text-semu fs-12 font-weight-medium mb-2">
                              <v-icon small>ri-user-fill</v-icon>
                              {{ template.recipient }} Recipients
                            </p>
                          </div>
                        </v-row>
                        <p class="text-semu font-italic fs-12">
                          Last Updated:
                          {{
                            template.updated_at | moment("dddd, D MMMM YYYY")
                          }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </label>
                </div>
              </div>
            </div>
            <div v-if="!isLoading && !hasError" class="my-10">
              <v-pagination
                class="py-10"
                v-model="page"
                :length="data.pagination.total_page || 1"
                @input="nextPage"
              ></v-pagination>
            </div>
          </v-card>
        </div>
      </div>
      <div class="col-md-12 px-0 py-4 align-end text-end">
        <v-divider class="py-2"></v-divider>
        <v-btn
          class="text-right my-2 mr-2 px-8"
          color="primary"
          elevation="0"
          :to="{
            name: 'list-certificate',
          }"
          outlined
        >
          Cancel
        </v-btn>
        <v-btn
          class="text-right my-2 px-8"
          color="primary"
          elevation="0"
          :disabled="!templateId"
          :to="{
            name: 'create-certificate-receive',
            params: {id: templateId},
          }"
        >
          Next
        </v-btn>
        <v-divider class="mt-3"></v-divider>
      </div>
      <v-snackbar v-model="hasError">
        {{ message }}
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
import {onMounted, reactive, toRefs} from "@vue/composition-api";
import useFetch from "../../../hooks/useFetch";
import api from "../../../store/api";

export default {
  created() {
    document.title = "Create Certificate-Momofin Admin";
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  setup(props) {
    const {fetchData} = useFetch();

    const state = reactive({
      templateId: "",
      data: [],
      page: 1,
      dialog: false,
      menu: false,
      items: [
        {
          text: "E-Certificate",
          disabled: false,
          href: "#",
        },
        {
          text: "Template",
          disabled: true,
          href: "#",
        },
      ],
      search: "",
      headers: [
        {
          text: "JUDUL SERTIFIKAT",
          align: "start",
          sortable: false,
          value: "judul",
        },
        {text: "TANGGAL DITERBITKAN", value: "date"},
        {text: "JUMLAH PENERIMA", value: "jumlah"},
        {text: "", value: "aksi"},
      ],
      isLoading: true,
      hasError: false,
      message: "",
    });

    const fetch = async (page) => {
      state.isLoading = true;
      const data = await fetchData({
        url: api.certificate_template + `?page=${page || 1}&limit=11`,
        method: "get",
      });
      if (!data.hasError) {
        state.data = data.response.data;
      } else {
        state.hasError = true;
        state.message = data.message;
      }
      state.isLoading = false;
    };

    const nextPage = () => {
      fetch(state.page);
    };

    onMounted(() => {
      fetch();
    });

    const searchQuery = async () => {
      state.isLoading = true;
      let data = await fetchData({
        url: api.template_list + `?search=${state.search}`,
      });
      if (!data.hasError) {
        state.data = data.response.data;
      } else {
        state.hasError = true;
      }
      state.isLoading = false;
    };

    return {
      ...toRefs(state),
      nextPage,
      searchQuery,
      props,
    };
  },
};
</script>

<style lang="scss">
.select-template[type="radio"] {
  display: none;
}
.select-template[type="radio"]:checked + .template {
  background-color: #dadada !important;
}
.create-card {
  border: dashed 2px #0068d6;
  height: 100%;
  min-height: 5rem;
  display: flex;
  justify-content: center;
  place-items: center;
  border-radius: 6px;
  text-align: center;
  h1 {
    font: 600 14px;
    margin-top: 30px;
    color: #0068d6;
  }
}
.wraper {
  min-height: 29rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>
