/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import axios from "axios";
import url from "./api";

const state = () => {
    return {
        inputForm: {},
        balance: "",
        total_outgoing: "",
        total_incoming: "",
        total_transaction: "",
    };
};

const mutations = {
    SET_inputForm: (state, value) => {
        state.inputForm = value
    },
    SET_BALANCE: (state, value) => {
        state.balance = value;
    },
    SET_TOTAL_OUTGOING: (state, value) => {
        state.total_outgoing = value;
    },
    SET_TOTAL_INCOMING: (state, value) => {
        state.total_incoming = value;
    },
    SET_TOTAL_TRANSACTION: (state, value) => {
        state.total_transaction = value;
    },
};

const getters = {
    getBalance: state => {
        return state.balance;
    },
    getTotOutgoing: state => {
        return state.total_outgoing;
    },
    getTotIncoming: state => {
        return state.total_incoming;
    },
    getTotTransaction: state => {
        return state.total_transaction;
    },
};

const actions = {
    getListTransaction({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_transaction_history + 'status=' + inputForm.status + '&transaction_type=' + inputForm.type + '&date=' + inputForm.date + '&limit=100' )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    getListDeposit({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_transaction_history + 'type=in' + '&status=' + inputForm.status + '&transaction_type=deposit' + '&date=' + inputForm.date + '&limit=100' )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    getBalanceTransaction({ commit }) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.balance_transaction_history)
            .then(response => {
                const { data } = response;
                commit("SET_BALANCE", data.result.data.balance);
                commit("SET_TOTAL_OUTGOING", data.result.data.outgoing);
                commit("SET_TOTAL_INCOMING", data.result.data.incoming);
                commit("SET_TOTAL_TRANSACTION", data.result.data.total_transaction);
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
};

export default { state, mutations, getters, actions };
