<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Certificate</v-card-title>

    <v-card-text class="px-0">
      <div class="content">        
        <div class="table my-3">          
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <v-row align="center">
                <v-col lg="6">
                  <p class="fs-16 text-black font-weight-medium">Certificate Bootcamp UI/UX Design</p>                  
                </v-col>

                <v-col id="search" lg="6" class="justify-end align-end text-right">                  
                  <v-btn
                    class="text-right"
                    color="primary"
                    elevation="0"
                    :to="{
                      name: 'create-certificate'
                    }"
                  >
                    Buat Sertifikat
                  </v-btn>
                </v-col>
              </v-row>
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-4 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-0">
                      <v-list three-line>                  
                        <v-list-item class="pl-0">
                          <img src="@/assets/images/example.png" class="img-table mr-1 ms-0">
                          <v-list-item-content>
                            <p class="text-semu fs-12 mb-2 wb">JUDUL SERTIFIKAT</p>
                            <p class="fs-14 text-black font-weight-medium wb">Product Design (UX/UI) Bootcamp for Beginners</p>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <p class="text-semu fs-12 mb-2 wb">TANGGAL DITERBITKAN</p>
                      <p class="fs-14 text-black font-weight-medium wb">22 Agustus 2021</p>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <p class="text-semu fs-12 mb-2 wb">PENERIMA</p>
                      <p class="fs-14 text-black font-weight-medium wb">123 Penerima</p>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <p class="text-semu fs-12 mb-2 wb">TERAKHIR DIUBAH</p>
                      <p class="fs-14 text-black font-weight-medium wb">23 Agustus 2021</p>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <v-btn block outlined color="primary" class="mb-2" >Pratinjau</v-btn>
                      <v-btn block outlined color="primary" class="" >Ubah</v-btn>
                      
                    </v-card-text>
                  </v-card>
                </div>
              </div>
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-6">                  
                  <v-text-field
                      outlined
                      append-icon="$search"
                      class="mt-1 ms-2 mx-width-85"
                      placeholder="Search"
                      solo
                      flat
                    ></v-text-field>
                </div>
                <div class="col-md-6 text-end align-end">
                  <v-text-field
                    outlined
                      append-icon="ri-filter-fill"
                      class="mt-1 ms-2 mx-width-85 float-right"
                      placeholder="Filter"
                      solo
                      flat
                  ></v-text-field>                                  
                </div>
              </div>                          
            </v-card-title>
            <div v-if="!desserts" class="col-md-6 mx-auto my-2">
              <img src="@/assets/images/certif-empty.svg"> 
              <p class="font-weight-bold text-black mb-2">Sertifikat Kosong</p>
              <p class="text-semu">Buat sertifikat baru atau coba persempit pencarian jika anda tidak dapat menemukan sertifikat yang anda cari</p>              
            </div>
            <v-data-table
              v-else
              :headers="headers"
              :items="desserts"
              :search="search"
            >              
              <template v-slot:item.status="{ item }">
                <v-chip
                  v-if="item.status === 'Issued'"
                  color="success"
                  outlined 
                  small                     
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else
                  :color="item.status == 'Prececced' ? '#F0AB0B' : '#2665DE'"
                  outlined
                  small                      
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </div>        
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    data: () => ({
      items: [
        {
          text: 'E-Certificate',
          disabled: false,
          href: '#',
        },
        {
          text: 'Certificate',
          disabled: false,
          href: '/list-certificate',
        }, 
        {
          text: 'Certificate Bootcamp UI/UX Design',
          disabled: true,
          href: '#',
        },        
      ],
      search: '',
        headers: [
          {
            text: 'PENERIMA',
            align: 'start',
            sortable: false,
            value: 'penerima',
          },
          { text: 'EMAIL', value: 'email' },
          { text: 'JUDUL SERTIFIKAT', value: 'judul' },
          { text: 'TANGGAL DITERBITKAN', value: 'date' },
          { text: 'TANGGAL KADALUARSA', value: 'exp' },
          { text: 'STATUS', value: 'status' },          
        ],
        desserts: [
          {
            penerima:'Arlene McCoy',
            email:'geovany.maggio@shields.com',
            judul:'Certificate Bootcamp UI/UX Design',
            date:'05 Agustus 2021',
            exp:'22 Agustus 2024',
            status:'Issued'
          },
          {
            penerima:'Ralph Edwards',
            email:'mac17@west.biz',
            judul:'Certificate Bootcamp UI/UX Design',
            date:'05 Agustus 2021',
            exp:'22 Agustus 2024',
            status:'Prececced'
          },
          {
            penerima:'Wade Warren',
            email:'flakin@considine.com',
            judul:'Certificate Bootcamp UI/UX Design',
            date:'05 Agustus 2021',
            exp:'22 Agustus 2024',
            status:'Claimed'
          },
        ],
    }),
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  computed: {      
    },
  methods: {
    
  },
};
</script>

<style>
</style>