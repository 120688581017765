<template>
  <v-row class="white" no-gutters justify="center" style="min-height: 100vh">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" lg="4" md="6" sm="8">
          <v-card class="pa-4" outlined>
            <v-row no-gutters>
              <v-col>
                <v-row no-gutters align="center">
                  <v-progress-circular
                    class="mr-4"
                    indeterminate
                    size="24"
                    color="primary"
                  ></v-progress-circular>
                  <div class="text-h5">Mohon tunggu sebentar</div>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import { verify } from "@/model/new-auth";

export default {
  data: () => ({}),
  mounted() {
    this.verifyUser();
  },
  methods: {
    verifyUser() {
      verify(this.$route.params.code)
        .then((res) => res.data)
        .then((res) => {
          this.$store.commit(
            "SET_USER",
            { token: res.data.token, user: { token: res.data.token } },
            { root: true }
          );
          this.$router.push({ name: "register-data" });
        })
        .catch((e) => {
          this.$router.push("/404");
          console.log(e);
        });
    },
  },
};
</script>
