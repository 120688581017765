<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold"
      >Buat Sertifikat</v-card-title
    >

    <v-card-text class="px-0">
      <div class="content">
        <div class="table my-3">
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <v-row align="center">
                <v-col lg="12">
                  <p class="fs-16 text-black font-weight-medium">
                    Certificate Bootcamp UI/UX Design
                  </p>
                </v-col>
              </v-row>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-4 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-0">
                      <v-list three-line>
                        <v-list-item class="pl-0">
                          <img
                            src="@/assets/images/example.png"
                            class="img-table mr-1 ms-0"
                          />
                          <v-list-item-content>
                            <p class="text-semu fs-12 mb-2 wb">
                              JUDUL SERTIFIKAT
                            </p>
                            <p class="fs-14 text-black font-weight-medium wb">
                              Product Design (UX/UI) Bootcamp for Beginners
                            </p>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <p class="text-semu fs-12 mb-2 wb">TANGGAL DITERBITKAN</p>
                      <p class="fs-14 text-black font-weight-medium wb">
                        22 Agustus 2021
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <p class="text-semu fs-12 mb-2 wb">PENERIMA</p>
                      <p class="fs-14 text-black font-weight-medium wb">
                        123 Penerima
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <p class="text-semu fs-12 mb-2 wb">TERAKHIR DIUBAH</p>
                      <p class="fs-14 text-black font-weight-medium wb">
                        23 Agustus 2021
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <v-btn block outlined color="primary" class="mb-2"
                        >Pratinjau</v-btn
                      >
                      <v-btn block outlined color="primary" class=""
                        >Ubah</v-btn
                      >
                    </v-card-text>
                  </v-card>
                </div>
              </div>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p class="text-black fs-16 font-weight-medium mb-0">
                    Tambahkan Penerima
                  </p>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    outlined
                    append-icon="$search"
                    class="mt-1 ms-2 mx-width-85"
                    placeholder="Search"
                    solo
                    flat
                  ></v-text-field>
                </div>
                <div class="col-md-6 text-end align-end">
                  <v-btn
                    color="grey darken-4"
                    class="white--text ma-1"
                    @click="add = true"
                  >
                    <v-icon class="mr-2">ri-user-add-fill </v-icon> Add
                    Recipient Manualy
                  </v-btn>
                  <v-btn
                    color="grey darken-4"
                    class="white--text ma-1"
                    @click="exp = true"
                  >
                    <v-icon class="mr-2">ri-upload-2-fill </v-icon> Upload
                    Excel/CSV
                  </v-btn>
                </div>
              </div>
            </v-card-title>
            <div v-if="!desserts" class="col-md-6 mx-auto my-2">
              <img src="@/assets/images/certif-empty.svg" />
              <p class="font-weight-bold text-black mb-2">Sertifikat Kosong</p>
              <p class="text-semu">
                Buat sertifikat baru atau coba persempit pencarian jika anda
                tidak dapat menemukan sertifikat yang anda cari
              </p>
            </div>
            <v-data-table
              v-else
              :headers="headers"
              :items="desserts"
              :search="search"
            >
              <template v-slot:item.status="{item}">
                <v-chip
                  v-if="item.status === 'Issued'"
                  color="success"
                  outlined
                  small
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else
                  :color="item.status == 'Prececced' ? '#F0AB0B' : '#2665DE'"
                  outlined
                  small
                >
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:item.action="{item}">
                <v-icon small class="mr-2" @click="editItem(item)">
                  ri-pencil-fill
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                  ri-delete-bin-6-fill
                </v-icon>
              </template>
            </v-data-table>
            <v-divider></v-divider>
            <v-card-actions class="py-5">
              <v-spacer></v-spacer>
              <v-btn color="primary" class="text-center px-3" outlined>
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="terbit = true"
                class="text-center px-3"
              >
                Terbitkan Sertifikat
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </v-card-text>
    <v-dialog v-model="add" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 text-black">
          Add Recipient
        </v-card-title>

        <v-card-text class="my-3">
          <v-row>
            <v-col class="py-0">
              <div class="subtitle-2 mb-2 text-black mt-3">Recipient Name</div>
              <v-text-field
                filled
                required
                outlined
                hide-details="auto"
                placeholder="Enter Full Name of Recipient"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <div class="subtitle-2 mb-2 text-black mt-3">Recipient Email</div>
              <v-text-field
                filled
                required
                outlined
                hide-details="auto"
                placeholder="Enter Email Address of Recipient"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="add = false"
            class="text-center px-3"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn color="primary" @click="add = false" class="text-center px-3">
            Add Recipient
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="exp" width="600" persistent>
      <v-card>
        <v-card-title class="text-h5 text-black">
          Terbitkan Sertifikat
        </v-card-title>

        <v-card-text class="my-3">
          <v-alert outlined type="warning" class="fs-12">
            Sebelum sertifikat anda diterbitkan, harap masukkan tanggal
            kedaluwarsa untuk sertifikat yang akan diterbitkan. Biarkan kosong
            jika sertifikat anda tidak memiliki tanggal kedaluwarsa!
          </v-alert>
          <v-row>
            <v-col class="py-0">
              <div class="subtitle-2 mb-2 mt-3">Tanggal Kadaluarsa</div>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                elevation="0"
              >
                <v-date-picker
                  ref="picker"
                  v-model="date"
                  min="1950-01-01"
                  :max="new Date().toISOString()"
                  outlined
                  required
                ></v-date-picker>
                <template v-slot:activator="{on, attrs}">
                  <v-text-field
                    v-model="date"
                    append-icon="ri-calendar-line"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    placeholder="DD-MM-YYYY"
                  ></v-text-field>
                </template>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="exp = false"
            class="text-center px-3"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn color="primary" @click="exp = false" class="text-center px-3">
            Add Recipient
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="terbit" width="600" persistent>
      <v-card>
        <v-card-title class="text-h5 text-black">
          Terbitkan Sertifikat
        </v-card-title>

        <v-card-text class="my-3">
          <v-alert outlined type="warning" class="fs-12">
            Anda akan menerbitkan sertifikat ke daftar penerima. Anda masih
            dapat menerbitkan sertifikat tambahan setelah proses ini.
          </v-alert>
          <v-row>
            <v-col class="py-0">
              <div class="fs-16 font-weight-medium mb-2 mt-3">
                Apakah anda yakin ingin menerbitkan sertifikat untuk 3 penerima?
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-5">
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            @click="terbit = false"
            class="text-center px-3"
          >
            Terbitkan Sekarang
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        text: "E-Certificate",
        disabled: false,
        href: "#",
      },
      {
        text: "Certificate",
        disabled: false,
        href: "/list-certificate",
      },
      {
        text: "Buat Sertifikat",
        disabled: true,
        href: "#",
      },
    ],
    search: "",
    headers: [
      {
        text: "PENERIMA",
        align: "start",
        sortable: false,
        value: "penerima",
      },
      {text: "EMAIL", value: "email"},
      {text: "TANGGAL DITERBITKAN", value: "date"},
      {text: "STATUS", value: "status"},
      {text: "ACTION", value: "action"},
    ],
    desserts: [
      {
        penerima: "Arlene McCoy",
        email: "geovany.maggio@shields.com",
        date: "05 Agustus 2021",
        status: "Issued",
      },
      {
        penerima: "Ralph Edwards",
        email: "mac17@west.biz",
        date: "05 Agustus 2021",
        status: "Prececced",
      },
      {
        penerima: "Wade Warren",
        email: "flakin@considine.com",
        date: "05 Agustus 2021",
        status: "Prececced",
      },
    ],
    add: false,
    exp: false,
    terbit: false,
    menu: false,
    date: null,
  }),
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
