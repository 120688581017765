import axios from "axios";

const BASE_URL = new URL(process.env.VUE_APP_BASE_URL).origin + "/v2";

const API = {
  REGISTER: `${BASE_URL}/auth/dashboard/register`,
  VERIFY: (code) => `${BASE_URL}/auth/dashboard/verification-account/${code}`,
  PROFILE: `${BASE_URL}/auth/dashboard/profile`,
  DOMAIN_CHECK: `${BASE_URL}/auth/dashboard/domain/check`,
  DOMAIN: `${BASE_URL}/auth/dashboard/domain`,
  TEAM: `${BASE_URL}/auth/dashboard/team`,
  REGISTER_STATUS: `${BASE_URL}/auth/dashboard/status-registartion`,
};

function register({ email, password }) {
  if (!email && !password) throw new Error("Email and password is required");
  return axios.post(API.REGISTER, { email, password });
}

function verify(code) {
  return axios.get(API.VERIFY(code));
}

function createProfile({
  full_name,
  company_name,
  phone,
  province: { province_id, province_name },
  city: { city_id, city_name },
}) {
  return axios.post(API.PROFILE, {
    full_name,
    company_name,
    phone,
    province: {
      id: province_id,
      name: province_name,
    },
    city: {
      id: city_id,
      name: city_name,
    },
  });
}

function getProfile() {
  return axios.get(API.PROFILE);
}

function checkDomain({ domain }) {
  return axios.post(API.DOMAIN_CHECK, { search: domain });
}

function createDomain({ domain }) {
  return axios.post(API.DOMAIN, { domain });
}

function getDomain() {
  return axios.get(API.DOMAIN);
}

function createTeam(team) {
  return axios.post(API.TEAM, { team });
}

function registerStatus() {
  return axios.get(API.REGISTER_STATUS);
}

export {
  register,
  verify,
  createProfile,
  getProfile,
  checkDomain,
  createDomain,
  getDomain,
  createTeam,
  registerStatus,
};
