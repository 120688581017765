<template>
  <v-row align-content="center" class="">
    <v-col class="py-0 mx-auto" md="10" style="min-height: 100vh;">
      <v-row>
        <v-col class="mx-auto" md="6">
          <v-spacer class="py-10"></v-spacer>
          <div class="mx-auto text-center">            
            <img src="@/assets/images/img-logo-black.png" class="mb-5">          
          </div>
          <v-form
            ref="form"
            class="px-8 white rounded"
            @submit.prevent="submit"
            v-model="valid"            
          > 
            <div class="mx-auto text-center mb-3 text-black py-3">
              <p class="font-weight-bold title-login">Perbarui kata sandi</p>
            </div>
            <v-row>
              <v-col cols="12" class="py-0 text-center">
                <p class="text-muted">Masukkan kata sandi baru Anda</p>
              </v-col>
            </v-row>
            <v-alert
              class="mt-2"
                type="error"
                text
                dense
                prominent
                v-show="response !== null"
                >{{ response }}</v-alert
              >          
            <v-row>
              <v-col class="">
                <div class="subtitle-2 mb-2 text-black mt-1">Kata sandi baru</div>
                <v-text-field
                    v-model="password"
                    :rules="pswRules"
                    required
                    outlined
                    hide-details="auto"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                    @click:append="show = !show"
                  ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col class="">
                <div class="subtitle-2 mb-2 text-black mt-1">Konfirmasi kata sandi</div>
                <v-text-field
                    v-model="confirm_password"
                    :rules="cnfrmPswRules"
                    outlined
                    hide-details="auto"
                    required
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                    @click:append="show2 = !show2"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  block
                  @click="submit()"
                >
                  Perbarui kata sandi
                </v-btn>
                
              </v-col>              
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import validationMixin from "../_mixins/validation";

export default {
  mixins: [validationMixin],
  name: "reset-password",
  data() {
    return {
      valid: true,
      errors: null,      
      submiting: false,
      show:false,
      show2:false,
      response:null,
      password:'',
      confirm_password:'',
      pswRules: [
        (v) => !!v || "Kata sandi tidak boleh kosong",
      ],

    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
    cnfrmPswRules() {
      return [
        () =>
          this.password === this.confirm_password ||
          "Konfirmasi kata sandi tidak sama",
        (v) => !!v || "Konfirmasi kata sandi tidak boleh kosong",
      ];
    },
  },
  methods: {
    submit() {      
      setTimeout(() => {
      if (this.$refs.form.validate()) {
        this.submiting = true;
        let data = { 
          token:this.$route.params.id,
          new_password:this.password,
          new_password_confirm:this.confirm_password
        };

        this.axios
          .post(
            // `auth/users/reset_password`,data,
            `auth/dashboard/forgot_password_dashboard`,data,
            {
              headers: {"Content-Type": "application/json"},
            })
          .then(() => {
            this.submiting = false;            
            this.$router.push({ name: 'reset-success'})            
          })
          .catch((error) => {
            this.submiting = false;
            this.$refs.form.reset();
            // console.log('er',error.response)            
            this.response = error.response.data.message;
            setTimeout( () => {                
              this.response=null;
            }
            , 3000);
          });
        } 
      }, 50);
    },
  },
};
</script>

<style>
</style>