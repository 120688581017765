export default {
  Dashboard: require('./Dashboard').default,
  Subdomain: require('./subdomain').default,
  // Pie: require('./Pie').default,
  Deposit: require('./deposit').default,
  BalanceDeposit: require('./balance-deposit').default,
  BalanceDepositDetail: require('./balance-deposit-detail').default,
  UpdatePass: require('./update-password').default,
  Nda: require('./nda').default,
  Package: require('./package').default,
  Success: require('./package-success').default,
};
