/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import axios from 'axios';
import url from './api';

const state = () => {
  return {
    inputForm: {},
    deposit_balance: '',
    deposit_incoming: '',
    deposit_outgoing: '',
    deposit_total_transaction: '',
    detailSub: null,
  };
};

const mutations = {
  SET_inputForm: (state, value) => {
    state.inputForm = value;
  },
  SET_GET_DEPOSIT_BALANCE: (state, value) => {
    state.deposit_balance = value;
  },
  SET_GET_DEPOSIT_INCOMING: (state, value) => {
    state.deposit_incoming = value;
  },
  SET_GET_DEPOSIT_OUTGOING: (state, value) => {
    state.deposit_outgoing = value;
  },
  SET_GET_TOTAL_TRANSACTION: (state, value) => {
    state.deposit_total_transaction = value;
  },
  SET_DETAIL_SUB: (state, value) => {
    state.detailSub = value;
  },
};

const getters = {
  getDepositBalance: (state) => {
    return state.deposit_balance;
  },
  getDepositIncoming: (state) => {
    return state.deposit_incoming;
  },
  getDepositOutgoing: (state) => {
    return state.deposit_outgoing;
  },
  getDepositTotalTransaction: (state) => {
    return state.deposit_total_transaction;
  },
};

const actions = {
  requestDepositConfirmation({ commit }, inputForm) {
    return new Promise((resolve, reject) => {
      axios
        .post(url.request_deposit_confirmation, inputForm, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  requestDeposit({ commit }, inputForm) {
    return new Promise((resolve, reject) => {
      axios
        .post(url.request_deposit, inputForm, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getListBankDestination({ commit }, inputForm) {
    return new Promise((resolve, reject) => {
      axios
        .get(url.get_company_bank)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSummaryDeposit({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(url.get_deposit_balance)
        .then((response) => {
          const { data } = response;
          commit('SET_GET_DEPOSIT_BALANCE', data.result.data.balance);
          commit('SET_GET_DEPOSIT_INCOMING', data.result.data.incoming);
          commit('SET_GET_DEPOSIT_OUTGOING', data.result.data.outgoing);
          commit('SET_GET_TOTAL_TRANSACTION', data.result.data.total_transaction);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default { state, mutations, getters, actions };
