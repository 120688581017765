<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">All Transaction Detail</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="row mb-5 mt-5 white">
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">DISBURSEMENT TITLE</p>
                <p class="fs-16 text-black font-weight-medium">{{ dataDetail.title }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">DESCRIPTION</p>
                <p class="fs-16 text-black font-weight-medium">{{ dataDetail.description }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-3 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">INPUT BY</p>
                <p class="fs-16 text-black font-weight-medium"><span class="text-capitalize">{{ dataDetail.creator_user_name }}</span> on {{ dataDetail.approved_at }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-3 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">APPROVED BY</p>
                <!-- <p class="fs-16 text-black font-weight-medium text-capitalize"><v-icon color="#FFC234">ri-error-warning-fill</v-icon> {{ dataDetail.status }}</p> -->
                <p class="fs-16 text-black font-weight-medium text-capitalize"><v-icon color="#FFC234">ri-error-warning-fill</v-icon> {{ dataDetail.approved_user_name }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">STATUS</p>
                <p class="fs-16 text-black font-weight-medium">
                  <!-- <v-chip
                    class="text-uppercase"                    
                    color="primary"
                    outlined
                  >
                    {{ dataDetail.status }}
                  </v-chip> -->
                  <v-chip
                    v-if="dataDetail.status === 'wait validation'"
                    color="#FF6F00"
                    outlined
                    class="text-uppercase"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else-if="dataDetail.status === 'wait approval'"
                    color="#F0AB0B"
                    outlined
                    class="text-uppercase"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else-if="dataDetail.status === 'failed'"
                    color="#E53935"
                    outlined
                    class="text-uppercase"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else-if="dataDetail.status === 'canceled'"
                    color="#D81B60"
                    outlined
                    class="text-uppercase"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else-if="dataDetail.status === 'completed'"
                    color="#0277BD"
                    outlined
                    class="text-uppercase"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else
                    :color="dataDetail.status == 'approved' ? 'success' : '#ADADAD'"
                    outlined
                    class="text-uppercase"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                </p>
              </v-card-text>
            </v-card>
          </div>
        </div>
        
        <div class="table col-md-12 px-0">          
          <v-card class="px-2" elevation="1">            
            <div class="row mb-3">
              <div class="col-md-6 align-middle">
                <p class="fs-14 text-black font-weight-medium mt-2 ps-2">List of Transaction</p>                
              </div>
            </div>

            <v-data-table
              v-model="selectedRows"
              :headers="headers"
              :items="dataDetailTransaction"
              item-key="id"
              class="elevation-1"
              @input="rowSelected()"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.icon`]="{ item }">
                <v-icon
                  v-if="item.icon === 'error'"
                  small
                  class="mr-2"
                  color="#FF3434"
                >
                  ri-error-warning-fill
                </v-icon>
                <v-icon
                  v-else
                  small
                  class="mr-2"
                  color="success"
                >
                  ri-checkbox-circle-line
                </v-icon>
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                <span>{{ formatPrice(item.amount) }}</span>
              </template>

              <template v-slot:[`item.email`]="{ item }">
                <span>{{ item.email || '-' }}</span>
              </template>

              <template v-slot:[`item.note`]="{ item }">
                <span>{{ item.note || '-' }}</span>
              </template>
              
            </v-data-table>
          </v-card>
        </div>        
      </div>
    </v-card-text>    
  </v-card>
</template>

<script>
import { formatPrice } from "@/lib/formatDate";
import axios from "axios";

export default {
  data: () => ({
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Bulk Disbursement',
        disabled: false,
        href: '/bulk-disbursement?tab=all-transaction',
      },
      {
        text: 'All Transaction Detail',
        disabled: true,
        href: '#',
      },
    ],
    headers: [
      { text: '', value: 'icon' },
      { text: 'BANK CODE', value: 'receipient_bank_name' },
      { text: 'ACCOUNT NO.', value: 'receipient_account_number' },
      { text: 'ACCOUNT NAME', value: 'receipient_account_name' },
      { text: 'AMOUNT', value: 'amount' },
      { text: 'EMAIL', value: 'email' },
      { text: 'NOTES', value: 'note' },
    ],
    singleSelect: false,
    selectedRows: [], 
    dataDetail: {},
    dataDetailTransaction: [],
    // isEditDelete: false,
    // changeData:false,
    // showChange:true,
    editedIndex: -1,
    editedItem: {
      id: 0,
      receipient_bank_name: '',
      receipient_account_number: 0,
      receipient_account_name: '',
      amount: 0,
      email: '',
      note: '',
    },
    defaultItem: {
      id: 0,
      receipient_bank_name: '',
      receipient_account_number: 0,
      receipient_account_name: '',
      amount: 0,
      email: '',
      note: '',
    },
    isLoading:false,
  }),
  mounted() {
    this.getListBulkDisbursmentDetail2();
    this.loadBankName();
  },
  computed: {
    getBankName() {
      return this.$store.state.sendMoney.getBankName;
    },
    idParam: {
      get() {
        return this.$route.params.id;
      }
    }
  },
  methods: {
    getListBulkDisbursmentDetail2() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
          axios
          .get(`etransfer/disbursement/bulk/receipients/${this.idParam}` )
          .then(response => {
            this.dataDetail = response.data.result.detail;
            this.dataDetailTransaction = response.data.result.receipients.data;
            this.isLoading = false;
            resolve(response);
          })
          .catch(error => {
              reject(error);
              this.isLoading = false;
          });
      });
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.dataDetailTransaction[this.editedIndex], this.editedItem);
        console.log('object',this.editedItem);
      }
      this.close()
    },
    close () {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300)
    },
    editItem (item) {
      this.editedIndex = this.dataDetailTransaction.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    deleteItem (item) {
      const index = this.dataDetailTransaction.indexOf(item);
      confirm('Are you sure you want to delete this item?') && this.dataDetailTransaction.splice(index, 1);
    },

    loadBankName() {
      this.$store.dispatch("getBankName");
    },

    rowSelected() {
      console.log(this.selectedRows.map(e => e.id));
      if (this.selectedRows.length == this.dataDetailTransaction) {
        alert('selectedRows all')
      }
    },
    deleteAllItem () {
      for(var i = 0; i < this.selectedRows.length; i++){
        const index = this.dataDetailTransaction.indexOf(this.selectedRows[i]);
        console.log('object',index);
        this.dataDetailTransaction.splice(index, 1);
      }
      this.dialog = false
    },

    // editSelected(item) {
    //   if(item.id) {
    //     console.log(this.selectedRows);
    //     this.isEditDelete = true;
    //   }
    // },
    // closeSelected(item) {
    //   console.log(item.id)
    //   this.isEditDelete = false;
    // },
    // deleteSelected(item) {
    //   alert(item.id)
    // },

    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style>
</style>