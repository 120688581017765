<template>
  <v-row>
    <v-col class="pa-0 white" cols="12">
      <v-container class="py-0">
        <v-row
          justify="space-between"
          align="center"
          no-gutters
          style="height: 64px"
        >
          <v-img :src="LOGO" height="24" contain position="left"></v-img>
          <a href="https://wa.me/628112955660" target="_blank">
            <v-btn rounded text>
              Membutuhkan bantuan?
              <v-icon right>ri-message-line</v-icon>
            </v-btn>
          </a>
        </v-row>
      </v-container>
      <v-divider />
    </v-col>

    <v-col cols="12" class="white" style="min-height: calc(100vh - 64px)">
      <v-container>
        <v-row justify="center">
          <v-col v-if="screen == 0" cols="12" md="5">
            <div class="mx-4 mb-8">
              <div class="text-h6 mb-2">Paket Langganan Anda Saat Ini</div>
              <v-card outlined>
                <v-row no-gutters class="pa-4">
                  <v-col cols="12">
                    <div class="text-h6">Basic</div>
                    <div style="font-size: 12px">Gratis</div>
                  </v-col>
                </v-row>
                <v-card-actions class="pa-4">
                  <v-row no-gutters justify="space-between">
                    <div class="mb-2">
                      <div class="mb-1">
                        Hanya 3 anggota (internal & eksternal)
                      </div>

                      <div class="mb-1">
                        <a
                          href="#"
                          @click.prevent="showDetailMain = !showDetailMain"
                        >
                          <span v-if="showDetailMain">Tutup Detail</span>
                          <span v-else>Lihat Detail</span>
                        </a>
                      </div>

                      <v-expand-transition>
                        <div
                          v-if="showDetailMain"
                          style="text-indent: -1.75rem; margin-left: 1.75rem;"
                        >
                          <div
                            v-for="item in basic.description"
                            :key="item"
                            class="my-1"
                          >
                            <v-icon :size="16" color="success" left
                              >ri-checkbox-circle-line</v-icon
                            >
                            <span>{{ item }}</span>
                          </div>
                        </div>
                      </v-expand-transition>
                    </div>

                    <v-btn color="primary" @click="handleContinueBasic"
                      >Lanjut Dengan Basic</v-btn
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </div>

            <v-card color="#F2FAFF" rounded="lg">
              <v-row no-gutters class="pa-6">
                <v-col>
                  <div class="text-h6 mb-2">Tingkatkan Paket Langganan Anda</div>
                  <div class="mb-8">
                    Anda dapat melakukan tingkatkan paket langganan untuk menikmati
                    fitur dan manfaat yang lebih beragam
                  </div>

                  <v-card
                    v-if="packagesLoading"
                    outlined
                    class="pa-4 text-center"
                  >
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-card>

                  <v-card
                    class="mb-4"
                    outlined
                    v-for="paket in otherPackages"
                    :key="paket.id"
                  >
                    <v-row no-gutters class="pa-4">
                      <v-col cols="12">
                        <div class="text-h6">
                          <v-row no-gutters justify="space-between">
                            <span
                              :class="
                                paket == 2 ? 'yellow--text text--darken-3' : ''
                              "
                            >
                              {{ paket.name }}
                            </span>
                            <v-chip
                              v-if="paket.id == 3"
                              small
                              color="yellow darken-3"
                              dark
                              label
                              >Paling Populer</v-chip
                            >
                          </v-row>
                        </div>
                        <div style="font-size: 12px">
                          Mulai dari
                          {{
                            paket.price
                              .toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })
                              .slice(0, -3)
                          }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-card-actions class="pa-4 pb-2">
                      <v-row no-gutters justify="space-between">
                        <div class="mb-2">
                          <div v-if="!showDetail[paket.id]" class="mb-1">
                            {{ paket.description[0] }}
                          </div>

                          <div class="mb-1">
                            <a
                              href="#"
                              @click.prevent="
                                $set(
                                  showDetail,
                                  paket.id,
                                  !showDetail[paket.id]
                                )
                              "
                            >
                              <span v-if="showDetail[paket.id]"
                                >Tutup Detail</span
                              >
                              <span v-else>Lihat Detail</span>
                            </a>
                          </div>

                          <v-expand-transition>
                            <div
                              v-if="showDetail[paket.id]"
                              style="text-indent: -1.75rem; margin-left: 1.75rem;"
                            >
                              <div
                                v-for="detail in paket.description"
                                :key="detail"
                                class="my-1"
                              >
                                <v-icon :size="16" color="success" left
                                  >ri-checkbox-circle-line</v-icon
                                >
                                <span>{{ detail }}</span>
                              </div>
                            </div>
                          </v-expand-transition>
                        </div>

                        <v-btn outlined @click="handleChoosePackage(paket)"
                          >Pilih Paket</v-btn
                        >
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col v-else-if="screen == 1" cols="12" md="7">
            <div class="mb-12">
              <v-btn outlined @click="handleBack" :disabled="isCheckoutLoading">
                <v-icon size="16" left>ri-arrow-left-line</v-icon>
                Kembali
              </v-btn>
            </div>

            <v-card v-if="detailLoading" outlined class="pa-4 text-center">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-card>

            <template v-else>
              <div class="mb-4">
                <div class="text-h5 mb-1">Pilih Durasi</div>
                <div>
                  Silahkan pilih durasi untuk paket {{ detailPackage.name }} di
                  bawah ini
                </div>
              </div>

              <v-row>
                <v-col
                  v-for="durasi in details"
                  :key="durasi.id"
                  cols="12"
                  sm="6"
                >
                  <v-card outlined>
                    <v-row
                      no-gutters
                      class="flex-row-reverse pa-4"
                      justify="space-between"
                      align="start"
                    >
                      <div>
                        <v-radio-group
                          :disabled="isCheckoutLoading"
                          class="mt-0"
                          v-model="selectedDuration"
                        >
                          <v-radio :value="durasi.id"></v-radio>
                        </v-radio-group>
                      </div>
                      <div>
                        <div class="font-weight-bold mb-2">
                          {{ durasi.time_period }} Bulan
                        </div>
                        <div class="text-h4">
                          {{
                            durasi.price
                              .toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })
                              .slice(0, -3)
                          }}
                        </div>
                      </div>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card outlined class="pa-4" color="grey lighten-4">
                    <div class="text-h5 mb-4">Detail Paket</div>
                    <div
                      v-for="item in detailPackage.description"
                      :key="item"
                      class="mb-1"
                    >
                      <v-icon :size="16" color="success" left
                        >ri-checkbox-circle-line</v-icon
                      >
                      <span>{{ item }}</span>
                    </div>
                  </v-card>
                </v-col>

                <v-col cols="12" class="text-center mt-4">
                  <v-btn
                    large
                    color="primary"
                    :loading="isCheckoutLoading"
                    @click="handleCheckout"
                    >Lanjut ke Pembayaran
                    <v-icon right>ri-arrow-right-line</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-col>

          <v-col v-else-if="screen == 2" cols="12" md="7">
            <div class="text-center mb-4">
              <v-icon size="48" color="success">ri-checkbox-circle-fill</v-icon>
            </div>

            <div class="text-h5 text-center mb-2">
              Anda Telah Berlangganan Paket Basic
            </div>

            <div class="text-center mb-6">
              Saat ini paket langganan basic Anda telah aktif dan Anda dapat
              langsung menggunakan layanan momofin
            </div>

            <v-card
              outlined
              class="pa-4 mx-0 mx-sm-16 mb-8"
              color="grey lighten-4"
            >
              <div class="text-h5 mb-4">Detail Paket</div>
              <div
                v-for="detail in basic.description"
                :key="detail"
                class="mb-1"
              >
                <v-icon :size="16" color="success" left
                  >ri-checkbox-circle-line</v-icon
                >
                <span>{{ detail }}</span>
              </div>
            </v-card>

            <div class="text-center mb-8">*Untuk paket Basic semua dokumen Anda akan kadaluarsa dalam 60 hari</div>

            <div class="text-center">
              <v-btn
                color="primary"
                @click="$router.push({ name: 'dashboard' })"
              >
                <span>Lanjut</span>
                <v-icon>ri-arrow-right-line</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-col>

    <v-dialog v-model="showConfirmBasic" width="480px" max-width="100vw">
      <v-card style="position: relative">
        <v-btn
          x-small
          icon
          outlined
          @click="showConfirmBasic = false"
          style="top: 1rem; right: 1rem; position: absolute"
        >
          <v-icon size="16">ri-close-line</v-icon>
        </v-btn>

        <v-row class="px-4 pt-8" no-gutters>
          <v-col cols="12">
            <div class="text-h6 text-center mb-4">
              Lanjut dengan Paket Basic
            </div>
            <div class="text-center mb-4">
              Paket Basic hanya dapat menambahkan maksimal 3 pengguna ke dalam
              tim Anda
            </div>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row class="pa-4 pb-2" no-gutters justify="center">
            <v-btn class="mx-2 mb-2" @click="showConfirmBasic = false"
              >Lihat Paket Lainnya</v-btn
            >
            <v-btn class="mx-2 mb-2" color="primary" @click="handleConfirmBasic"
              >Ya, Lanjutkan</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import LOGO from "@/assets/images/logo.png";

export default {
  data: () => ({
    screen: 0,
    packagesLoading: false,
    detailLoading: false,
    isCheckoutLoading: false,
    selectedDuration: null,
    showConfirmBasic: false,
    showDetailMain: false,
    showDetail: {},
    detailPackage: {},
    details: [],
    packages: [],
  }),
  computed: {
    LOGO: () => LOGO,
    basic() {
      return this.packages.find((v) => v.id == 1);
    },
    otherPackages() {
      return this.packages.filter((v) => v.id != 1);
    },
  },
  mounted() {
    this.getPlan();
  },
  methods: {
    handleChoosePackage(paket) {
      this.screen = 1;
      this.detailPackage = paket;
      this.selectedDuration = paket.id;
      this.getDetailPlan(paket.name);
    },
    handleBack() {
      this.screen = 0;
    },
    handleContinueBasic() {
      this.showConfirmBasic = true;
    },
    async handleConfirmBasic() {
      await this.axios.post(`/subscription`, { plan_id: this.basic.id });
      await this.$store.dispatch("registerStatus");
      this.screen = 2;
      this.showConfirmBasic = false;
    },
    async getPlan() {
      this.packagesLoading = true;
      this.axios
        .get(`/subscription/plan`, { params: { period: 3 } })
        .then((response) => {
          this.packages = response.data.data;
        })
        .finally(() => {
          this.packagesLoading = false;
        });
    },
    async getDetailPlan(plan_name) {
      this.detailLoading = true;
      this.axios
        .get(`/subscription/plan/${plan_name}`)
        .then((response) => {
          this.details = response.data.data;
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },
    async handleCheckout() {
      this.isCheckoutLoading = true;
      await this.axios
        .post(`/subscription`, { plan_id: this.selectedDuration })
        .then((res) => res.data)
        .then((res) => {
          const data = res.data;
          let url = "";
          if (data.amount == 0) {
            url = data.successRedirectURL;
          } else {
            url = data.invoice_url;
          }
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.click();
          document.removeChild(anchor);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isCheckoutLoading = false;
        });
    },
  },
};
</script>
