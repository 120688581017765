<!-- @format -->

<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold"
      >Certificate</v-card-title
    >

    <v-card-text class="px-0">
      <div class="content">
        <div class="table my-3">
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <v-row align="center">
                <v-col lg="6">
                  <p class="fs-16 text-black font-weight-medium">
                    List of Certificate
                  </p>
                </v-col>

                <v-col
                  id="search"
                  lg="6"
                  class="justify-end align-end text-right"
                >
                  <v-btn
                    class="text-right"
                    color="primary"
                    elevation="0"
                    :to="{
                      name: 'create-certificate',
                    }"
                  >
                    Create Certificate
                  </v-btn>
                </v-col>
              </v-row>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    outlined
                    append-icon="$search"
                    class="mt-1 ms-2 mx-width-85"
                    placeholder="Search"
                    solo
                    @change="searchQuery"
                    v-model="search"
                    flat
                  ></v-text-field>
                </div>
                <div class="col-md-6 text-end align-end">
                  <v-text-field
                    outlined
                    append-icon="ri-filter-fill"
                    class="mt-1 ms-2 mx-width-85 float-right"
                    placeholder="Filter"
                    solo
                    flat
                  ></v-text-field>
                </div>
              </div>
            </v-card-title>
            <div
              v-if="!data.certificate && hasError && !isLoading"
              class="col-md-6 mx-auto my-2"
            >
              <img src="@/assets/images/certif-empty.svg" />
              <p class="font-weight-bold text-black mb-2">Sertifikat Kosong</p>
              <p class="text-semu">
                Create a new certificate or try to narrow the search if you
                can't find the certificate you're looking for
              </p>
            </div>
            <v-data-table
              v-else
              :loading="loadingData || isLoading"
              loading-text="Loading"
              hide-default-footer
              :headers="headers"
              :items="data.certificate"
            >
              <template v-slot:[`item.judul`]="{item}">
                <router-link
                  class="primary--text bg-none"
                  :to="{name: 'certificate-detail', params: {id: item.id}}"
                >
                  <v-list class="bg-none" three-line>
                    <v-list-item class="pl-0">
                      <img
                        :src="item.preview"
                        loading="lazy"
                        class="img-table mr-1 ms-0"
                      />
                      <v-list-item-content class="primary--text">
                        {{ item.title }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </router-link>
              </template>
              <template v-slot:[`item.issuance`]="{item}">
                {{ item.created_at | moment("dddd, D MMMM YYYY") }}
              </template>
              <template v-slot:[`item.recipient`]="{item}">
                {{ item.recipient }} {{ item.recipient == 1 ? 'Recipient' : 'Recipients' }}
              </template>
              <template v-slot:[`item.aksi`]="{item}">
                <v-btn
                  outlined
                  color="primary"
                  class="mr-2 mb-1"
                  small
                  @click="openPreview(item)"
                  >Preview
                </v-btn>
                <v-btn
                  outlined
                  color="primary"
                  class="mb-1"
                  small
                  :to="{
                    name: 'edit-create-certificate-receive',
                    params: {
                      id: item.id,
                    },
                  }"
                  >Edit</v-btn
                >
              </template>
            </v-data-table>
            <div v-if="!isLoading && !hasError" class="text-center py-5">
              <v-pagination
                @input="nextPage"
                v-model="page"
                :length="data.pagination && data.pagination.total_page"
              ></v-pagination>
            </div>
          </v-card>
        </div>
      </div>
    </v-card-text>
    <v-dialog v-model="preview" max-width="60%">
      <v-card class="d-flex justify-center items-center">
        <img
          loading="lazy"
          style="width: 100%; height: 100%; object-fit: cover;"
          :src="previewData.preview"
          alt="image"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {onMounted, reactive, toRefs} from "@vue/composition-api";
import useFetch from "../../../hooks/useFetch";
import api from "../../../store/api";
export default {
    created() {
    document.title = "Certificate-Momofin Admin";
  },
  data: () => ({
    items: [
      {
        text: "E-Certificate",
        disabled: false,
        href: "#",
      },
      {
        text: "Certificate",
        disabled: true,
        href: "#",
      },
    ],
    ex: ["1", "2", "3"],
    headers: [
      {
        text: "CERTIFICATE TITLE",
        value: "judul",
      },
      {text: "ISSUANCE DATE", value: "createdAt"},
      {text: "TOTAL RECIPIENTS", value: "recipient"},
      {text: "", value: "aksi"},
    ],
  }),
  setup() {
    const {fetchData} = useFetch();

    const state = reactive({
      data: [],
      page: 1,
      isLoading: false,
      preview: false,
      hasError: false,
      previewData: {},
      search: "",
      loadingData: false,
    });

    function openPreview(data) {
      state.previewData = data;
      state.preview = true;
    }

    onMounted(() => {
      getData();
    });

    const getData = async (page) => {
      state.isLoading = true;
      let data = await fetchData({
        url: api.certificate_list + `?limit=20&page=${page || 1}`,
        method: "GET",
      });
      if (!data.hasError) {
        state.data = data.response.data;
        state.isLoading = false;
      } else {
        state.hasError = true;
        state.isLoading = false;
      }
    };

    const searchQuery = async () => {
      state.loadingData = true;
      let res = await fetchData({
        url: api.certificate_list + `?search=${state.search}`,
        method: "GET",
      });
      state.data = res.response.data;
      state.loadingData = false;
    };

    const nextPage = () => {
      getData(state.page);
    };

    return {
      ...toRefs(state),
      nextPage,
      openPreview,
      searchQuery,
    };
  },
};
</script>

<style>
.bg-none {
  background: none !important;
}
</style>
