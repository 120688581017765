/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import axios from "axios";
import url from "./api";

const state = () => {
    return {
        inputForm: {},
        getBankName: [],
        getBankAccount: [],
        total_completed: "",
        total_failed: "",
        total_pending: "",
        count: "",
    };
};

const mutations = {
    SET_inputForm: (state, value) => {
        state.inputForm = value
    },
    SET_GET_BANK_NAME: (state, value) => {
        state.getBankName = value;
    },
    SET_GET_BANK_ACCOUNT: (state, value) => {
        state.getBankAccount = value;
    },
    SET_TOTAL_COMPLETED: (state, value) => {
        state.total_completed = value;
    },
    SET_TOTAL_FAILED: (state, value) => {
        state.total_failed = value;
    },
    SET_TOTAL_PENDING: (state, value) => {
        state.total_pending = value;
    },
    SET_COUNT: (state, value) => {
        state.count = value;
    },
};

const getters = {
    getTotCompleted: state => {
        return state.total_completed;
    },
    getTotFailed: state => {
        return state.total_failed;
    },
    getTotPending: state => {
        return state.total_pending;
    },
    getCount: state => {
        return state.count;
    },
};

const actions = {
    getBankName({ commit }) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.get_bank_name)
            .then(response => {
                const { data } = response.data;
                commit("SET_GET_BANK_NAME", data);
                // console.log('bank', data);
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    getBankAccount({ commit }) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.get_bank_account)
            .then(response => {
                const { data } = response.data;
                commit("SET_GET_BANK_ACCOUNT", data);
                // console.log('bank', data);
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    
    // LIST BULK DISBURSEMENT
    getListDisbursement({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_disbursement + 'status=' + inputForm.status + '&date=' + inputForm.date + '&limit=100' )
            .then(response => {
                // console.log('res', response.data.code);
                // console.log('res', response.data.message);
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    getListDisbursementNeedValidation({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_disbursement + 'status=wait%20validation' + '&limit=100')
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    getListDisbursementNeedApproval({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_disbursement + 'status=wait%20approval' + '&limit=100')
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    getListDisbursementApproved({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_disbursement + 'status=approved' + '&limit=100')
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    // END LIST BULK DISBURSEMENT

    getSummaryDisbursement({ commit }) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.summary_transaction_disbursement)
            .then(response => {
                const { data } = response;
                commit("SET_TOTAL_COMPLETED", data.result.data.total_completed);
                commit("SET_TOTAL_FAILED", data.result.data.total_failed);
                commit("SET_TOTAL_PENDING", data.result.data.total_pending);
                commit("SET_COUNT", data.result.data.count);
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    // LIST BULK DISBURSEMENT
    getListBulkDisbursment({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_bulk_disbursement + 'status=' + inputForm.status + '&date=' + inputForm.date + '&limit=100')
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    getListBulkDisbursmentNeedValidation({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_bulk_disbursement + 'status=wait%20validation' + '&limit=100')
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    getListBulkDisbursmentNeedApproval({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_bulk_disbursement + 'status=wait%20approval' + '&limit=100')
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    getListBulkDisbursmentApproved({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_bulk_disbursement + 'status=approved' + '&limit=100')
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    // END LIST BULK DISBURSEMENT

    validateNeedValidation({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .post(url.validate_need_validation, inputForm, { headers: {"Content-Type": "application/json"}} )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    validateNeedApproval({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .post(url.validate_need_approval, inputForm, { headers: {"Content-Type": "application/json"}} )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    validateNeedApproval2({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .post(url.approve_disbursement, inputForm, { headers: {"Content-Type": "application/json"}} )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    requestOtp({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .post(url.release_code, inputForm, { headers: {"Content-Type": "application/json"}} )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    releaseDisbursement({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .post(url.release_disbursement, inputForm, { headers: {"Content-Type": "application/json"}} )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    getListScheduleDisbursment({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_schedule_disbursement + 'status=' + inputForm.status + '&date=' + inputForm.date + '&limit=100' )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    createSchedule({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .post(url.create_schedule, inputForm, { headers: {"Content-Type": "application/json"} })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

};

export default { state, mutations, getters, actions };
