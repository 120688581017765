<template>
    <div>
        <!-- <input type="text" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true"/> -->
        <v-text-field
            :rules="[v => !!v || 'Amount is required']"
            filled
            required
            outlined
            hide-details="auto"
            type="text"
            placeholder="Rp"
            @blur="isInputActive = false" @focus="isInputActive = true"
            v-model="displayValue"
        ></v-text-field>
    </div>
</template>

<script>
export default {
    props: ["value"],
    data: function() {
        return {
            isInputActive: false
        }
    },
    computed: {
        displayValue: {
            get: function() {
                if (this.isInputActive) {
                    // Cursor is inside the input field. unformat display value for user
                    return this.value.toString()
                } else {
                    // User is not modifying now. Format display value for user interface
                    return "Rp " + this.value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
                }
            },
            set: function(modifiedValue) {
                // Recalculate value after ignoring "$" and "," in user input
                // eslint-disable-next-line no-useless-escape
                let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
                // Ensure that it is not NaN
                if (isNaN(newValue)) {
                    newValue = 0
                }
                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it
                this.$emit('input', newValue)
            }
        }
    }
}
</script>

<style scoped>
    /* input {
        border: 1px solid #888;
        font-size: 1.2rem;
        padding: 0.5rem;
    } */
</style>