<template>
  <v-row align-content="center" class="">
    <v-col class="py-0 mx-auto" md="10" style="min-height: 100vh;">
      <v-row>
        <v-col class="mx-auto" md="6">
          <v-spacer class="py-10"></v-spacer>          
          <div
            class="px-8 white rounded py-3 text-center"           
          > 
            <div class="mx-auto text-center mb-3 py-3">            
              <img src="@/assets/images/success.png" class="py-3">          
              <p class="font-weight-bold title-login text-black">Terima kasih telah mengatur ulang kata sandi Anda.</p>
            </div>
            <!-- <div class="mx-auto text-center mb-3 py-3 text-black">
              <p>Phasellus accumsan imperdiet tempor. Cras tincidunt, arcu nec eleifend porttitor, orci est vehicula velit.</p>
            </div>  -->                     
            <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  @click="redirect()"
                >
                  Lanjutkan
                </v-btn>         
          </div>
          
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
      
    };
  },
  computed: {
    
  },
  methods: {
    redirect() {
      this.$router.push({ name: 'login'})
    },
  },
};
</script>

<style>
</style>