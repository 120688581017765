import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: require('./_auth').default,
    dashboard: require('./_dashboard').default,
    receiveMoney: require('./_receiveMoney').default,
    sendMoney: require('./_sendMoney').default,
    transactionHistory: require('./_transactionHistory').default,
  }
})
