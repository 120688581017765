<template>
  <v-row>
    <v-col class="white pr-8" cols="12" style="border-bottom: solid 1px #E2E8F0 !important">
      <v-row no-gutters justify="space-between" align="center">
        <img src="@/assets/images/logo.png" alt="Avatar" class="ma-3 ml-5" style="width: 150px;" />
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-container>
        <router-link to="/dashboard">
          <v-btn text color="primary">
            <v-icon left size="20">ri-arrow-left-s-line</v-icon>
            Kembali
          </v-btn>
        </router-link>
      </v-container>
    </v-col>

    <v-col cols="12">
      <v-row no-gutters justify="center">
        <div>
          <div class="text-h5 text-center mb-8">
            Tingkatkan Paket Subskripsi Anda
          </div>
          <v-card class="pa-4" min-width="300" max-width="540" elevation="1">
            <div class="text-h5 mb-4">Ringkasan Pembelian</div>
            <v-row>
              <v-col>Paket {{ detail.name }} ({{ detail.time_period }} Bulan)</v-col>
              <v-col class="text-left text-right">{{ numberToCurrency(detail.price) }}</v-col>
            </v-row>
            <!-- <v-row>
              <v-col>PPN 11%</v-col>
              <v-col class="text-right">{{ numberToCurrency(detail.price * 0.11) }}</v-col>
            </v-row> -->
            <v-divider class="my-4" />
            <v-row>
              <v-col>
                <div class="text-h5 mb-4">Total</div>
              </v-col>
              <v-col class="text-right">{{ numberToCurrency(detail.price) }}</v-col>
            </v-row>
            <p align="center">
              Dengan mengklik <strong>"Upgrade"</strong>, Anda menyetujui
              <a target="_blank" href="https://momofin.com/terms-conditions/">
                <strong>persyaratan pelanggan</strong>
              </a>
              kami.
            </p>
            <v-btn block color="primary" :loading="loading" @click="handleUpgrade">Upgrade</v-btn>
            <p align="center" class="font-weight-medium mt-4">
              <span class="mr-2">
                Butuh bantuan?
              </span>
              <a target="_blank" href="https://wa.me/628112955660">Hubungi Kami</a>
            </p>
          </v-card>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState({ detail: (state) => state.dashboard.detailSub }),
  },
  mounted() {
    if (!this.detail) this.$router.replace('/dashboard');
  },
  methods: {
    numberToCurrency(number) {
      return parseInt(number).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' });
    },
    async handleUpgrade() {
      this.loading = true;
      const params = {
        plan_id: this.detail.id,
      };
      await this.axios
        .post(`/subscription?period=${this.toggle_exclusive}`, params)
        .then((res) => {
          this.submitting = false;
          let url = res.data.data.invoice_url;
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.click();
          document.removeChild(anchor);
        })
        .catch((error) => {
          this.submitting = false;
          let message = error.response ? error.response.data.message : error.msg;
          this.notification = {
            state: true,
            message,
          };
        });
      this.loading = false;
    },
  },
};
</script>
