<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Incoming Transfer</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="table my-3">          
          <v-card>
            <v-card-title class="font-weight-regular">
              <div class="row">
                <div class="col-md-2 px-0">
                  <v-select
                    v-model="columnFilter.status"
                    :items="ex"
                    :item-value="`value`"
                    item-text="label"
                    placeholder="Select Status"
                    class="ma-1"
                    @change="getListIncomingTransfer(columnFilter.ex)"
                  ></v-select>
                </div>
                <div class="col-md-3 px-0">
                  <v-select
                    v-model="columnFilter.type"
                    :items="transaction_type"
                    :item-value="`value`"
                    item-text="label"
                    placeholder="Select Transaction Type"
                    @change="getListIncomingTransfer(columnFilter.type)"
                    class="ma-1"
                  ></v-select>               
                </div>
                <div class="col-md-3 px-0">                  
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    elevation="0"
                  >
                    <v-date-picker
                      ref="picker"
                      v-model="columnFilter.date"
                      min="1950-01-01"
                      outlined
                      required
                      range
                      @change="getListIncomingTransfer(columnFilter.date)"
                    ></v-date-picker>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        placeholder="Start date - End date"                    
                        append-icon="ri-calendar-line"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mt-1"
                      ></v-text-field>                   
                    </template>
                  </v-menu>
                </div>
                <div class="col-md-2 px-0">                  
                  <v-text-field
                      outlined
                      v-model="search"
                      append-icon="$search"
                      class="mt-1 ms-2"
                      placeholder="Search"
                      solo
                      flat
                    ></v-text-field>
                </div>
                <div class="col-md-1 px-0">
                  <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true" style="min-height: 48px;">
                    <v-icon class="mr-2">ri-download-cloud-fill </v-icon> Export
                  </v-btn>                  
                </div>
              </div>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="listIncoming"
              :search="search"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ formatDate(item.createdAt) }}
              </template>

              <template v-slot:[`item.updatedAt`]="{ item }">
                {{ parseTime(item.updatedAt) }}
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                {{ formatPrice(item.amount) }}
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status === 'pending'"
                  color="#E91E63"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'processing'"
                  color="#3949AB"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'failed'"
                  color="#E53935"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'canceled'"
                  color="#D81B60"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'refund'"
                  color="#F0AB0B"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else
                  :color="item.status == 'completed' ? 'success' : '#ADADAD'"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </div>        
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="dialog=false"
        >
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5">
          Export Transaction History Report
        </v-card-title>

        <v-card-text class="my-3">
          <p class="mb-0">Select File Type</p>
          <v-select
            :items="ex"
            placeholder="Select Type"
            class="ma-1"
          ></v-select>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { parseTime, formatDate, formatPrice } from "@/lib/formatDate";
export default {
    data: () => ({
      dialog:false,
      menu:false,
      items: [
        {
          text: 'E-Transfer',
          disabled: false,
          href: '#',
        },
        {
          text: 'Receive Money',
          disabled: true,
          href: '#',
        },
        {
          text: 'Incoming Transfer',
          disabled: true,
          href: '#',
        }
      ],
      ex: [],
      transaction_type: [],
      search: '',
      headers: [
        {
          text: 'TRANSACTION ID',
          align: 'start',
          sortable: false,
          value: 'transaction_code',
        },
        { text: 'DATE', value: 'createdAt' },
        { text: 'TIME', value: 'updatedAt' },
        { text: 'DESCRIPTION', value: 'description' },
        { text: 'REMARK', value: 'remark' },
        { text: 'CHANNEL', value: 'payment_channel' },
        { text: 'AMOUNT (RP)', value: 'amount', align: 'right' },
        { text: 'STATUS', value: 'status' },
      ],
      listIncoming:[],
      columnFilter: {
        status: [],
        type: [],
        date: [],
      },
      isLoading:false,
    }),
  mounted() {
    this.getListIncomingTransfer();
  },
  computed: {
    dateRangeText () {
      return this.columnFilter.date.join(' ~ ')
    },
  },
  methods: {
    getListIncomingTransfer() {
      this.isLoading = true;
      this.$store.dispatch("getListIncomingTransfer", this.columnFilter)
      .then(response => {
        const { data } = response;
        this.listIncoming = data.result.transaction;
        this.ex = data.result.filter.status;
        this.transaction_type = data.result.filter.transaction_type;
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },

    formatDate(value) {
      return formatDate(value);
    },
    formatPrice(value) {
      return formatPrice(value);
    },
    parseTime(value) {
      return parseTime(value);
    },
  },
};
</script>

<style>
</style>