<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 pb-0 px-0 fs-20 font-weight-bold">Add Funds to Your Balance</v-card-title>

    <v-card-text class="px-0">
      <v-form
        ref="forms"
        @submit.prevent="submit"
        v-model="valid"
      >
        <v-card elevation="1">
          <!-- <v-row class="mt-5 px-5 py-5">
            <v-col cols="5">
              <v-subheader class="text-black font-weight-thin fs-14">Nominal Deposit</v-subheader>
            </v-col>
            <v-col cols="7">
              <v-text-field
                :rules="[v => !!v || 'Nominal deposit is required']"
                filled
                required
                outlined
                hide-details="auto"
                type="number"
                min="0"
                placeholder="Rp."
                v-model.number="serverParams.amount"
              ></v-text-field>
            </v-col>
          </v-row> -->
          
          <v-row class="mt-5 px-5 py-5">
            <v-col cols="4">
              <v-subheader class="text-black font-weight-thin fs-14">Nominal Deposit</v-subheader>
            </v-col>
            <v-col cols="8">
              <!-- <CurrencyInput v-model="serverParams.amount" :options="{ currency: 'IDR' }" :min="0"/> -->
              <MyCurrencyInput v-model="serverParams.amount"></MyCurrencyInput>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="px-5 py-5">
            <v-col cols="4">
              <v-subheader class="text-black font-weight-thin fs-14">Select Bank </v-subheader>
            </v-col>
            <v-col cols="8" class="d-flex flex-column-sm">
              <v-radio-group
                v-model="serverParams.bank_id"
                row
                v-for="(list, idx) in listBank" :key="idx"
              >
                <v-radio
                  class="deposit"
                  :value="list.id"
                >
                  <template v-slot:label>
                    <div><img :src="list.image"></div>
                  </template>
                </v-radio>
              </v-radio-group>

            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card-actions>
            <div class="row">
              <div class="col-sm-12 text-right px-0">
                <v-btn color="primary" outlined class="mr-2 px-5" @click="validateField">
                  Cancel
                </v-btn>
                <v-btn color="primary" dark class="px-3" v-on:click="nextStep()" :loading="submiting">
                  Next
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-form>

      <v-snackbar
          v-model="isError"
      >
        {{ showMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="isError = false"
          >
          Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card-text>

  </v-card>
</template>

<script>
// import MyCurrencyInput from './MyCurrencyInput';
export default {
  data: () => ({
    // components: { MyCurrencyInput },
    valid: true,
    items: [
      {
        text: 'Balance',
        disabled: false,
        href: '/deposit',
      },
      {
        text: 'Deposit',
        disabled: false,
        href: '#',
      },
    ],
    isError: false,
    submiting: false,
    showMessage: "",
    listBank: [],
    serverParams: {
      amount: 0,
      bank_id: ""
    },
    code: ""
  }),
  beforeCreate: function () {
    //   this.$options.components.CurrencyInput = require('./CurrencyInput.vue').default
    this.$options.components.MyCurrencyInput = require('./MyCurrencyInput.vue').default
  },
  mounted() {
    this.getListBankDestination();
  },
  computed: {
    
  },
  methods: {
    getListBankDestination() {
      this.$store.dispatch("getListBankDestination")
      .then(response => {
        const { data } = response;
        this.listBank = data.data;
      })
      .catch(error => {
        console.log(error);
      });
    },

    nextStep() {
      this.$store.dispatch("requestDeposit", this.serverParams)
      .then((response) => {
        this.code = response.data.data.code;
        this.isError = true;
        this.submiting = true;
        this.showMessage = response.data.message;
        setTimeout( () => {
          this.$router.push({name: 'balance-deposit-detail', params: { id: this.code }})
        }, 800);
        // this.$refs.form.reset();
        // this.submiting = false;
      })
      .catch((error) => {
        this.submiting = false;
        this.isError = true;
        this.showMessage = error.response.data.message;
      });
    },

    validateField() {
      this.$refs.forms.validate();
    }
  },
};
</script>

<style scoped lang="scss">
  .justify-content-end {
    justify-content: end;
  }
  .justify-content-center {
    justify-content: center;
  }
  .bg-grey {
    background-color: #F9F9F9;
  }
  .bank-options {
    padding: 10px 0 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }
  .bank-option {
    position: relative;
    padding: 4px;
    border: 1px solid #CACED0;
    border-radius: 0.325rem;
    img {
      width: 100px;
    }
    &:hover {
      cursor: pointer;
      background-color: #E3F2FD;
    }
  }
</style>

<style>
  .v-radio.deposit.theme--light.v-item--active {
    border: 2px solid #409eff;
  }
  .v-radio.deposit:hover {
    border-color: #409eff;
  }
  .v-radio.deposit .v-input--selection-controls__input {
    display: none;
  }
  .v-radio.deposit {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
  }
  .v-radio.deposit img {
    width: 100px;
  }
  .deposit .theme--light.v-label {
    color: inherit;
  }
  @media (max-width: 767px) {
    .flex-column-sm {
      flex-direction: column;
    }
  }
</style>