<template>
  <v-row>
    <v-col class="pa-0 white" cols="12">
      <v-container class="py-0">
        <v-row
          justify="space-between"
          class="flex-nowrap overflow-auto"
          align="center"
          no-gutters
          style="height: 64px"
        >
          <v-img :src="LOGO" height="24" contain position="left"></v-img>
          <a href="https://wa.me/628112955660" target="_blank">
            <v-btn rounded text>
              Membutuhkan bantuan?
              <v-icon right>ri-message-line</v-icon>
            </v-btn>
          </a>
        </v-row>
      </v-container>
      <v-divider />
    </v-col>

    <v-col cols="12" class="white" style="min-height: calc(100vh - 64px)">
      <v-container>
        <v-row justify="center">
          <v-col v-if="loading" cols="12" lg="6" md="8" sm="10">
            <v-card class="pa-4 text-center">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-card>
          </v-col>

          <v-col v-else cols="12" lg="6" md="8" sm="10">
            <div class="text-center mb-1">
              <v-icon size="48">ri-bookmark-fill</v-icon>
            </div>
            <div class="text-center text-h5 mb-1">
              Segera selesaikan pembayaran paket {{ detail.name }}
            </div>
            <div class="text-center mb-8 grey--text">
              untuk menikmati fitur MomofinGO secara lengkap
            </div>
            <div class="text-center mb-4">Info Langganan</div>
            <v-row no-gutters justify="center" class="mb-8">
              <v-row
                class="pa-2 mx-4 rounded grey lighten-3"
                align="center"
                style="width: 400px; max-width: 100%; flex: unset"
              >
                <v-col cols="6">
                  <div class="text-h6">Paket {{ detail.name }}</div>
                  <div>{{ detail.periode }} bulan</div>
                </v-col>
                <v-col class="text-right" cols="6">
                  <a :href="detail.invoice">
                    <v-btn color="primary">Bayar Sekarang</v-btn>
                  </a>
                </v-col>
              </v-row>
            </v-row>
            <div class="text-center grey--text mb-8">
              Jika pembayaran tidak dilakukan dalam 1x24 jam maka sistem secara
              otomatis akan mengalihkan paket Anda menjadi Basic
            </div>
            <div class="text-center">
              <v-btn @click="handleContinueWithBasic"
                >Batal, & lanjut dengan paket Basic</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import LOGO from "@/assets/images/logo.png";

export default {
  data: () => ({ detail: {}, loading: false }),
  computed: {
    LOGO: () => LOGO,
  },
  mounted() {
    this.checkPackage();
  },
  methods: {
    formatIdr(number) {
      try {
        return parseInt(number)
          .toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
          })
          .slice(0, -3);
      } catch (error) {
        return error.message;
      }
    },
    async checkPackage() {
      this.loading = true;
      this.axios
        .get("/subscription/package-active")
        .then((res) => res.data)
        .then((res) => {
          this.detail = res.data.detail || {};
          // this.$router.push({name: 'confirm-payment'})
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleContinueWithBasic() {
      await this.axios
        .post(`/subscription`, { plan_id: 1 })
        .then((res) => res.data)
        .then((res) => {
          this.submitting = false;
          const data = res.data;
          const url = data.successRedirectURL;
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.click();
          document.removeChild(anchor);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isCheckoutLoading = false;
        });
    },
  },
};
</script>
