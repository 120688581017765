<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Create Invoice</v-card-title>

    <v-card-text class="px-0">
      <div class="content col-md-12">
        <v-card class="mt-2" elevation="1">  

          <v-card-title class="font-weight-regular">
            <div class="row">
              <div class="col-md-6">
                <p class="fs-16 text-black font-weight-medium"></p>
              </div>

              <div class="col-md-6 text-right">
                <v-btn class="ma-1" v-on:click="isPreview = !isPreview">
                  <template v-if="!isPreview">                    
                    Hide Preview
                  </template>
                  <template v-else>                    
                    Show Preview
                  </template>
                </v-btn>
                <v-btn color="primary" class="white--text ma-1">
                  Save as Draft
                </v-btn>
                <v-btn color="primary" class="white--text ma-1" :to="{ name: 'payment-create'}">
                  Send Invoice
                </v-btn>
              </div>
            </div>

            <div class="col-md-12 px-0">
              <v-divider></v-divider>
            </div>
          </v-card-title>

          <v-form
            ref="form"
            @submit.prevent="submit"
            v-model="valid"
          >
            <div class="row m-0">
              <div :class="isPreview === false ? 'col-sm-6' : 'col-sm-12'">
                <v-card-title class="font-weight-regular py-0">
                  <div class="row">
                    <div class="col-sm-4 col-xs-6">
                      <p class="fs-16 text-black">Invoice Number</p>
                    </div>

                    <div class="col-sm-8 col-xs-6">
                      <p class="fs-16 text-black font-weight-medium">DF12312-002</p>
                    </div>
                  </div>

                  <div class="col-md-12 px-0">
                    <v-divider></v-divider>
                  </div>
                </v-card-title>

                <div class="table">
                  <v-card-title class="font-weight-regular">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="fs-16 text-black font-weight-medium mb-0">Customer Detail</p>
                      </div>
                    </div>
                  </v-card-title>

                  <v-row>
                    <v-col cols="5">
                      <v-subheader class="text-black font-weight-thin fs-14">Full Name</v-subheader>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        filled
                        required
                        :rules="[v => !!v || 'Full name is required']"
                        outlined
                        hide-details="auto"
                        placeholder="Name of your customer"
                        v-model="serverParams.fullname"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="5">
                      <v-subheader class="text-black font-weight-thin fs-14">Phone Number</v-subheader>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        filled
                        required
                        :rules="[v => !!v || 'Phone number is required']"
                        outlined
                        hide-details="auto"
                        type="number"
                        placeholder="+62"
                        v-model="serverParams.mobilephone"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="5">
                      <v-subheader class="text-black font-weight-thin fs-14">Email Address</v-subheader>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        filled
                        required
                        outlined
                        hide-details="auto"
                        type="email"
                        placeholder="example@mail.com"
                        v-model="serverParams.email"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <div class="col-md-12 my-3">
                    <v-divider></v-divider>
                  </div>

                  <v-card-title class="font-weight-regular">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="fs-16 text-black font-weight-medium mb-0">Invoice Detail</p>
                      </div>
                    </div>
                  </v-card-title>

                  <v-row>
                    <v-col cols="5">
                      <v-subheader class="text-black font-weight-thin fs-14">Description</v-subheader>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        filled
                        required
                        :rules="[v => !!v || 'Description is required']"
                        outlined
                        hide-details="auto"
                        placeholder="Type description here"
                        v-model="serverParams.description"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="5">
                      <v-subheader class="text-black font-weight-thin fs-14">Enable Recurring</v-subheader>
                    </v-col>
                    <v-col cols="7">
                      <v-switch
                        flat
                        v-model="serverParams.recurring"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col cols="5">
                      <v-subheader class="text-black font-weight-thin fs-14">Fund Source</v-subheader>
                    </v-col>
                    <v-col cols="7">
                      <v-combobox
                        v-model="model"
                        :filter="filter"
                        :hide-no-data="!search"
                        :items="methods"
                        :search-input.sync="search"
                        hide-selected
                        label="Search for an option"
                        multiple
                        small-chips
                        solo
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <span class="subheading">Create</span>
                            <v-chip
                              color="primary"
                              label
                              small
                              outlined
                            >
                              {{ search }}
                            </v-chip>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                          <v-chip
                            v-if="item === Object(item)"
                            v-bind="attrs"
                            color="primary"
                            :input-value="selected"
                            label
                            small
                            outlined
                          >
                            <span class="pr-2">
                              {{ item.text }}
                            </span>
                            <v-icon
                              small
                              @click="parent.selectItem(item)"
                            >
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                        <template v-slot:item="{ index, item }">
                          <v-text-field
                            v-if="editing === item"
                            v-model="editing.text"
                            autofocus
                            flat
                            background-color="transparent"
                            hide-details
                            solo
                            @keyup.enter="edit(index, item)"
                          ></v-text-field>
                          <v-chip
                            v-else
                            color="primary"
                            dark
                            label
                            small
                            outlined
                          >
                            {{ item.text }}
                          </v-chip>
                          <v-spacer></v-spacer>
                          <v-list-item-action @click.stop>
                            <v-btn
                              icon
                              @click.stop.prevent="edit(index, item)"
                            >
                              <v-icon>{{ editing !== item ? 'ri-pencil-fill' : 'ri-check-circle-fill' }}</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-combobox>   
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="5">
                      <v-subheader class="text-black font-weight-thin fs-14">Invoice Due Date</v-subheader>
                    </v-col>
                    <v-col cols="7">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="serverParams.recurring_date"
                        persistent
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="serverParams.due_date"
                            prepend-icon="ri-calendar-fill"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="serverParams.due_date"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(serverParams.due_date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>

                  <div class="col-md-12 my-3">
                    <v-divider></v-divider>
                  </div>

                  <v-card-title class="font-weight-regular">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="fs-16 text-black font-weight-medium mb-0">Items</p>
                      </div>
                    </div>
                  </v-card-title>

                  <div class="row">
                    <div class="col-md-12">
                      <template>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr v-for="(itemInvoice, idx) in itemInvoices" :key="idx">
                                <template v-if="idx !== 0">
                                  <td style="font-size: 12px;" class="text-capitalize">{{ itemInvoice.name }}</td>
                                  <td style="font-size: 12px;">{{ itemInvoice.qty }}</td>
                                  <td style="font-size: 12px;">Rp {{ formatPrice(itemInvoice.price) }}</td>
                                  <td style="font-size: 12px;"><strong>Rp {{ formatPrice(itemInvoice.total_price) }}</strong></td>
                                  <td>
                                    <v-btn small dark @click="deleteRowItems(itemInvoice)">
                                      <v-icon dark>ri-delete-bin-6-line</v-icon>
                                    </v-btn>
                                  </td>
                                </template>
                              </tr>

                              <tr>
                                <td class="py-2 px-1">
                                  <v-text-field
                                    required
                                    placeholder="Items"
                                    v-model="addItems.name"
                                  ></v-text-field>
                                </td>
                                <td class="py-2 px-0">
                                  <v-text-field
                                    required
                                    min="0"
                                    type="number"
                                    placeholder="Qty"
                                    v-model.number="addItems.qty"
                                    @change="updateQty(addItems.qty)"
                                  ></v-text-field>
                                </td>
                                <td class="py-2 px-1">
                                  <v-text-field
                                    required
                                    min="0"
                                    type="number"
                                    placeholder="Rp"
                                    v-model.number="addItems.price"
                                    @change="updatePrice(addItems.price)"
                                  ></v-text-field>
                                </td>
                                <td class="py-2" colspan="2">Rp {{ formatPrice(addItems.total_price) }}</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td><strong>Total Amout {{ formatPrice(serverParams.total_amount) }}</strong></td>
                                <td colspan="5" class="text-right">
                                  <v-btn
                                    class="ma-2"
                                    outlined
                                    color="blue"
                                    small
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn class="my-2" @click.prevent="addRowItems" >
                                    Add New Item
                                  </v-btn>
                                </td>
                              </tr>
                            </tfoot>
                          </template>
                        </v-simple-table>
                      </template>
                    </div>
                  </div>

                  <div class="col-md-12 my-3">
                    <v-divider></v-divider>
                  </div>

                  <v-row>
                    <v-col cols="5">
                      <v-subheader class="text-black font-weight-thin fs-14">Additional Notes</v-subheader>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        v-model="serverParams.notes"
                        filled
                        required
                        outlined
                        hide-details="auto"
                        placeholder="Type additional notes here"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="5">
                      <v-subheader class="text-black font-weight-thin fs-14">Invoice Reminder and Notification</v-subheader>
                    </v-col>
                    <v-col cols="7">
                      <v-switch
                        v-model="serverParams.reminder"
                        flat
                      ></v-switch>
                    </v-col>
                  </v-row>

                </div>
              </div>

              <!-- Preview -->
              <template v-if="!isPreview">
                <div class="col-sm-6 bg-grey">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <v-btn color="secondary" class="ma-1" >
                        PDF Invoice
                      </v-btn>
                      <v-btn color="primary" class="white--text ma-1" outlined>
                        Invoice link page
                      </v-btn>
                    </div>
                  </div>

                  <v-card rounded="lg" class="mt-4 py-4 px-4">
                    <div class="font-weight-regular">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="d-flex justify-content-between">
                            <div>
                              <h2 class="text-black mb-0">Invoice</h2>
                              <p class="text-black mb-0">INVOICE NO. DF12312-002</p>
                            </div>
                            <div>
                              <p><span class="fs-16">Rp</span> <span class="fs-24 text-black mb-0">{{ formatPrice(serverParams.total_amount) }}</span></p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6">
                          <p class="mb-0">ISSUED DATE</p>
                          <p class="font-weight-medium text-black mb-0">{{ serverParams.due_date }}</p>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0">DUE DATE</p>
                          <p class="font-weight-medium text-black mb-0">{{ serverParams.due_date }}</p>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <p class="mb-0">INVOICE FOR</p>
                          <p class="font-weight-medium text-black mb-0">{{ serverParams.fullname }}</p>
                          <p class="font-weight-medium text-black mb-0">{{ serverParams.email }}</p>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <template>
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th>ITEM</th>
                                    <th>QTY</th>
                                    <th>PRICE</th>
                                    <th>TOTAL</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(itemInvoice, idx) in itemInvoices" :key="idx">
                                    <template v-if="idx !== 0">
                                      <td class="text-capitalize">{{ itemInvoice.name }}</td>
                                      <td>{{ itemInvoice.qty }}</td>
                                      <td>Rp {{ formatPrice(itemInvoice.price) }}</td>
                                      <td><strong>Rp {{ formatPrice(itemInvoice.total_price) }}</strong></td>
                                    </template>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colspan="3"><strong>Total Amout</strong></td>
                                    <td><strong>Rp. {{ formatPrice(serverParams.total_amount) }}</strong></td>
                                  </tr>
                                </tfoot>
                              </template>
                            </v-simple-table>
                          </template>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <div class="bg-lightblue py-4 px-4">
                            <p class="mb-0">{{ serverParams.notes }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 text-right">
                          <v-btn color="primary" dark class="ma-1" @click="handleStored" :loading="submiting">
                            Pay Invoice
                          </v-btn>
                        </div>
                      </div>

                    </div>
                  </v-card>

                </div>
              </template>
            </div>
          </v-form>

          <v-snackbar
            v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>

          <v-divider></v-divider>

        </v-card>
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm Disbursement
        </v-card-title>

        <v-card-text class="my-3">          
          <v-simple-table>
            <template v-slot:default>              
              <tbody>
                <tr>
                  <td>TOTAL AMOUNT</td>
                  <td>Rp 31.234.000</td>
                </tr>
                <tr>
                  <td>SEND TO</td>
                  <td>MANDIRI - 123456789012345 <br>Cameron Williamson </td>
                </tr>
                <tr>
                  <td>NO. REFERENCE</td>
                  <td>DF789012345003</td>
                </tr>
                <tr>
                  <td>DESCRIPTION</td>
                  <td>Montes nascetur ridiculus</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-card>
</template>

<script>
import { formatPrice } from "@/lib/formatDate";

export default {
  data: () => ({
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Receive Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Invoice',
        disabled: false,
        href: '/invoice',
      },
      {
        text: 'Create Invoice',
        disabled: true,
        href: '#',
      },
    ],
    isPreview: false,
    isError: false,
    valid: true,
    ex:['a','b'],
    switch1:false,
    switch2:false,
    dialog:false,
    activator: null,
    attach: null,
    editing: null,
    editingIndex: -1,
    methods: [
      { header: 'Select or Add Payment Option ' },
      {
        text: 'Credit Card',
      },
      {
        text: 'Bank Transfer',
      },
      {
        text: 'E-Wallet',
      },
    ],
    nonce: 1,
    menu: false,
    model: [
      {
        text: 'Credit Card',
      },
    ],
    x: 0,
    search: null,
    y: 0,
    dates:[],
    date: new Date().toISOString().substr(0, 7),
    snackbar: false,
    submiting: false,
    showMessage: '',
    menuDate: false,
    modal: false,
    emailRules: [
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    serverParams: {
      notes: "",
      fullname: "",
      mobilephone: "",
      email: "",
      total_amount: "",
      description: "",
      payment_option: [],
      due_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      recurring: false,
      recurring_periode: "",
      reminder: false,
    },
    itemInvoices: [{
      name: "",
      qty: "",
      price: "",
      total_price: 0,
    }],
    addItems: {
      name: "",
      qty: "",
      price: "",
      total_price: 0,
    }
  }),
  mounted() {
    // console.log(this.getTotalAmount);
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    // getTotalAmount() {
    //   return this.itemInvoices.reduce((x, y) => x + y.total_price, 0);
    // },
    getParam() {
      return { 
        ...this.serverParams,
        item: this.itemInvoices
      }
    },
  },
  watch: {
    model (val, prev) {
      if (val.length === prev.length) return
      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: 'primary',
          }
          this.items.push(v)
          this.nonce++
        }
        return v
      })
    },
  },
  methods: {
    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        this.editing = null
        this.editingIndex = -1
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },

    addRowItems() {
      this.itemInvoices.push({
        name: this.addItems.name,
        qty: this.addItems.qty,
        price: this.addItems.price,
        total_price: this.addItems.total_price,
      });
      this.serverParams.total_amount = this.itemInvoices.reduce((x, y) => x + y.total_price, 0)
      this.addItems.name = "";
      this.addItems.qty = "";
      this.addItems.price = "";
      this.addItems.total_price = 0;
    },
    deleteRowItems(itemInvoice) {
      var idx = this.itemInvoices.indexOf(itemInvoice);
      if (idx > -1) {
        this.itemInvoices.splice(idx, 1);
      }
    },
    handleStored() {
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          this.$store.dispatch("createInvoice", this.getParam)
          .then((response) => {
            this.isError = true;
            this.showMessage = response.data.message;
            this.submiting = false;
            this.$refs.form.reset();
            setTimeout( () => {
              this.$router.push({path: 'invoice'})
            }, 1500);
          })
          .catch((error) => {
            this.submiting = false;
            this.isError = true;
            this.showMessage = error.response.data.message;
          });
          this.serverParams.total_amount = 0;
          this.itemInvoices.splice(0, this.itemInvoices.length - 1);
        }
      }, 50);
    },


    updateQty(qty) {
      this.addItems.total_price = qty * this.addItems.price;
    },
    updatePrice(price) {
      this.addItems.total_price = this.addItems.qty * price;
    },

    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .bg-grey {
    background-color: #F7F6F6;
  }
  .bg-lightblue {
    background-color: #F6F9FF;
  }
  .m-0 {
    margin: 0;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .fs-24 {
    font-size: 24px;
  }
</style>