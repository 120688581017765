export default {
  ApiSetting: require('./api-setting').default,
  Inventory: require('./inventory/index.vue').default,
  InventoryProducts: require('./inventory/products.vue').default,
  DetailPacket: require('./inventory/detail-paket.vue').default,
  PaymentPaket: require('./inventory/payment-paket.vue').default,
  PaymentPaketDetail: require('./inventory/payment-paket-detail.vue').default,
  InventorySingleProduct: require('./inventory/single-product.vue').default,
  DetailPaketMeterai: require('./e-meterai/detail-paket-meterai').default,
  Emeterai_Pricing: require('./e-meterai/pricing.vue').default,
  DetailPakeSignature: require('./e-signature/detail-paket-signature').default,
  PurchasingHistory: require('./history/purchasing-history.vue').default,
  DetailPurchase: require('./history/detail-purchase.vue').default,
  SalesHistory: require('./history/sales-history.vue').default,
  ESign_Pricing: require('./e-signature/pricing.vue').default,
}