<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">
      {{ name }}
    </v-card-title>
    <v-card class="pricing-card">
      <div class="pricing-header pt-2">
        <v-card-title class="text-black pt-0 px-0 fs-7 font-weight-bold">
          {{ title }}  
        </v-card-title>
        <v-text-field
          outlined
          append-icon="$search"
          class="mt-1 ms-2 mx-width-85 float-right "
          solo
          flat
          style="max-width: 334px; background-color: white;"
          placeholder="Cari Berdasarkan Judul Template"
        />
      </div>
      <div v-if="!isLoading" class="pricing-wrapper">
        <div v-for="(pricing, index) in pricings" :key="index">
          <div class="pricing-card">
            <div>
              <div class="pricing-card-header">
                <img
                  class="pricing-card-header__img"
                  src="@/assets/images/materai-icon.png"
                  alt="icon"
                  draggable="false"
                  loading="lazy"
                />
                <div>
                  <h1 class="pricing-card-header__title">
                    {{ pricing.title }}
                  </h1>
                  <p class="pricing-header__price">
                    {{ pricing.price }}
                  </p>
                </div>
              </div>
              <div class="pricing-card-feature">
                <ul class="pricing-card-feature-list">
                  <li
                    v-for="(facilities, index) in pricing.items"
                    :key="index"
                    class="pricing-card-feature-item"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="pricing-card-feature-item__icon"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <p class="pricing-card-feature-item__title">
                      {{ facilities }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <v-card-actions class="pricing-card-action">
              <v-btn
                class="pricing-card-action__button"
                width="100%"
                height="90px"
                color="primary"
                :to="{ name: 'detail-paket-meterai', params: { id: pricing.id }}"
              >
                Beli Bundling
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import {reactive, toRefs, onMounted} from "@vue/composition-api";
import axios from 'axios'

// const pricing = [
//   {
//     title: "Paket Bundling A",
//     price: "Rp. 1.000.000",
//     items: [
//       "50 e-Meterai",
//       "100 e-Signature",
//       "Berlaku hingga 31 Desember 2022",
//       "Ultrices mi tempus imperdiet nulla",
//       "Fringilla ut morbi tincidunt augue",
//     ],
//     action: 'Beli Bundling'
//   },
//   {
//     title: "Paket Bundling B",
//     price: "Rp. 2.000.000",
//     items: [
//       "50 e-Meterai",
//       "100 e-Signature",
//       "Berlaku hingga 31 Desember 2022",
//       "Ultrices mi tempus imperdiet nulla",
//       "Fringilla ut morbi tincidunt augue",
//       "Ultrices mi tempus imperdiet nulla",
//     ],
//     action: 'Beli Bundling'
//   },
//   {
//     title: "Paket Bundling C",
//     price: "Rp. 3.000.000",
//     items: [
//       "50 e-Meterai",
//       "100 e-Signature",
//       "Berlaku hingga 31 Desember 2022",
//       "Ultrices mi tempus imperdiet nulla",
//       "Fringilla ut morbi tincidunt augue",
//       "Ultrices mi tempus imperdiet nulla",
//       "Fringilla ut morbi tincidunt augue",
//     ],
//     action: 'Beli Bundling'
//   },
// ];

export default {
  setup() {
    const state = reactive({
      name: 'E-Meterai Pricing',
      title: 'Pilih Paket yang anda inginkan',
      pricings: null,
      isLoading: true
    });

    onMounted(async ()=>{
      state.isLoading = true;
      let { data:data } = await axios.get('https://momofin.herokuapp.com/v1/e-signature/pricing')
      state.pricings = data.data
      state.isLoading = false
    })

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
p {
  margin: 0;
  padding: 0;
}
.pricing-wrapper {
  padding: 0 10px;
  margin: 40px 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (min-width: 768px) {
    gap: 43px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.pricing {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
  }
  &-card {
    border-radius: 12px;
    padding: 10px;
    border: solid 1px #e0e0e0 !important;
    &-header {
      display: flex;
      place-items: center;
      padding: 20px 0;
      padding-top:10;
      &__img {
        margin-right: 0.5rem;
      }
      &__title {
        font-size: 1rem;
      }
      &__price {
        font-size: 0.5rem;
        font-weight: bold;
      }
    }
    &-feature {
      &-list {
        border-top: solid #e0e0e0 1px;
        list-style: none;
        min-height: 270px;
        padding: 20px 0 !important;
      }
      &-item {
        display: flex;
        place-items: center;
        padding: 5px;
        &__title {
          margin: 0;
          margin-bottom: 0 !important;
        }
        &__icon {
          margin-right: 5px;
          width: 20px;
          color: #9ad4fb;
        }
      }
    }
    &-action {
      padding: 20px 0;
      &__button {
        height: 44px !important;
        border-radius: 6px;
      }
    }
  }
}
</style>