export default { 
  CertifList: require('./certificate/index.vue').default,
  CertifDetail: require('./certificate/show.vue').default,
  CertifCreate: require('./certificate/create.vue').default,
  CertifCreateReceive: require('./certificate/create-receive.vue').default,
  CertifEditCreateReceive: require('./certificate/edit-create-receive.vue').default,
  TempList: require('./template/index.vue').default,
  TempDetail: require('./template/view.vue').default,
  TempCreate: require('./template/create.vue').default,
  TempEdit: require('./template/edit.vue').default,
  TempReq: require('./template/request-design.vue').default,
}