<template>
  <v-card rounded="lg" class="transparent">
    <br />
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <p
            class="text-h4 text-black pt-0 px-0 mb-0 pb-0"
            style="word-break: normal"
          >
            Invoice Pembelian Paket Subskripsi
          </p>
        </div>
      </div>
    </v-card-title>
    <br />
    <p class="text-subtitle-1 px-3 d-flex d-sm-none">
      No. Invoice: {{ dataDetail.no_invoice }}
    </p>
    <v-card class="pa-2 ma-3" elevation="2">
      <v-card-text class="pb-2">
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <img src="@/assets/images/logo.png" class="w-100" width="150" />
            <p>
              PT. Mitra Era Teknologi <br />
              Go-Work Menara Rajawali Ground Floor - Jl. DR. Ide Anak Agung Gde
              Agung,<br />
              RT.5/RW.2, Kuningan, Kuningan Tim., Kecamatan Setiabudi, Jakarta,
              <br />Daerah Khusus Ibukota Jakarta 12950
            </p>
          </div>
          <div class="col-md-6 col-sm-6 d-xs-none">
            <p class="text-subtitle-1 text-right">
              No. Invoice: {{ dataDetail.no_invoice }}
            </p>
          </div>
          <div class="col-md-12 overflow-auto">
            <h1 class="text-h6 activate-complete__message-title mb-3">
              Dikeluarkan untuk
            </h1>
            <table v-if="dataDetail.user" class="text-subtitle-1" style="border: 0px solid">
              <!-- <tr>
                <td class="pb-2 text-price-bold">Nama Pengguna</td>
                <td class="pb-2 text-price-bold">:</td>
                <td class="pb-2 text-price-bold">{{ dataDetail.user.name }}</td>
              </tr> -->
              <tr>
                <td class="pb-2 text-price-bold pr-8">Nama Perusahaan</td>
                <td class="pb-2 text-price-bold pr-2">:</td>
                <td class="pb-2 text-price-bold">
                  {{ dataDetail.user.companyName }}
                </td>
              </tr>
              <tr>
                <td class="pb-2 text-price-bold">Email</td>
                <td class="pb-2 text-price-bold">:</td>
                <td class="pb-2 text-price-bold">
                  {{ dataDetail.user.email }}
                </td>
              </tr>
              <tr>
                <td class="pb-2 text-price-bold">No. Telepon</td>
                <td class="pb-2 text-price-bold">:</td>
                <td class="pb-2 text-price-bold">
                  {{ dataDetail.user.phone }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="pa-2 ma-3" elevation="2">
      <v-card-text class="pb-2">
        <div class="row">
          <div class="col-md-12">
            <h1 class="text-h6 activate-complete__message-title mb-3">
              Detail Pembelian
            </h1>
            <div class="overflow-auto">
              <table
                class="text-subtitle-1"
                style="
                  width: 100%;
                  border: 0px solid;
                  border-collapse: collapse;
                "
              >
                <tr>
                  <td class="pb-2 text-price-bold" style="width: 50%">
                    Tanggal & waktu pembelian
                  </td>
                  <td class="pb-2 text-right text-price-bold">
                    {{ formatToDate(dataDetail.order_datetime) }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 text-price-bold" style="width: 50%">
                    Produk
                  </td>
                  <td class="pb-2"></td>
                </tr>
                <tr style="background: #f1f5f9">
                  <td
                    class="pa-4 ma-0 pb-5"
                    style="
                      width: 50%;
                      border-bottom-left-radius: 6.8px;
                      border-top-left-radius: 6.8px;
                    "
                  >
                    <span
                      class="text-black text-subtitle-1 font-weight-medium text-price-bold"
                      >Paket Anda </span
                    ><br />
                    <h3 class="font-weight-bold primary--text text-price-bold">
                      {{ dataDetail.plan_name }} -
                      {{ dataDetail.plan_period }} bulan
                    </h3>
                    <span class="text-body-2 text-muted text-price-bold"
                      >{{ dataDetail.plan_user }} Pengguna</span
                    >
                  </td>
                  <td
                    class="pa-4 ma-0 pb-5 text-right font-weight-bold text-price-bold"
                    style="
                      border-bottom-right-radius: 6.8px;
                      border-top-right-radius: 6.8px;
                    "
                  >
                    <span class="text-price-bold">
                      Rp {{ formatToPrice(dataDetail.plan_price) }}
                    </span>
                  </td>
                </tr>
                <!-- <tr>
                <td class="pa-2 text-price-bold text-body-2" style="width: 50%;">PPN {{ dataDetail.ppn }}</td>
                <td class="pa-2 text-right text-price-bold text-body-2">
                  Rp {{ formatToPrice(dataDetail.plan_price) }}
                </td>
              </tr> -->
                <tr>
                  <td>
                    <v-divider></v-divider>
                  </td>
                  <td>
                    <v-divider></v-divider>
                  </td>
                </tr>
                <tr>
                  <td class="pa-2" style="width: 20%">
                    <p class="text-total-bold">Total</p>
                  </td>
                  <td class="pa-2 text-right">
                    <p class="text-total-bold">
                      Rp {{ formatToPrice(dataDetail.plan_price) }}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="pa-2 ma-3" elevation="2">
      <v-card-text class="">
        <div class="row">
          <div class="col-md-12">
            <h1 class="text-h6 activate-complete__message-title mb-3">
              Detail Pembayaran
            </h1>
            <table
              class="text-subtitle-1"
              style="width: 100%; border: 0px solid"
            >
              <tr>
                <td class="pb-2 text-price-bold">Metode Pembayaran</td>
                <td class="pb-2"></td>
                <td class="pb-2 text-right text-price-bold">
                  {{ dataDetail.payment_channel }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="pa-2 ma-3" elevation="2">
      <v-card-text class="pb-2">
        <div class="row">
          <div class="col-md-12">
            <!-- <h1 class="text-h6 activate-complete__message-title mb-2">
              Status
            </h1> -->
            <table
              class="text-subtitle-1 mb-2"
              style="width: 100%; border: 0px solid"
            >
              <tr>
                <td class="text-h6 text-price-bold">Status</td>
                <td class=""></td>
                <td class="text-right font-weight-bold text-price-bold">
                  {{ dataDetail.status }}
                </td>
              </tr>
            </table>

            <a
              v-if="dataDetail.status === 'PENDING'"
              :href="dataDetail.invoice_url"
              target="_blank"
              class="v-btn v-btn--block v-btn--outlined v-btn--router theme--light elevation-0 v-size--default primary--text"
            >
              Konfirmasi Pembayaran
            </a>
            <v-btn
              v-if="dataDetail.status === 'PAID'"
              color="primary"
              outlined
              block
              @click="download"
              :loading="submiting"
            >
              Unduh PDF
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar.state">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.state = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
import { formatDateTime } from "@/lib/formatDate";
import { formatPrice } from "@/lib/formatDate";

const formatToDate = (value) => {
  return formatDateTime(value);
};
const formatToPrice = (value) => {
  return formatPrice(value);
};
export default {
  setup() {
    return {
      formatToDate,
      formatToPrice,
    };
  },

  data: () => ({
    valid: true,
    isError: false,
    isLoading: false,
    submiting: false,
    showMessage: "",
    disabled: false,
    dataDetail: {},
    serverParams: {
      order_id: "",
      product_id: "",
      qty: "",
    },
    selectedItem: {},
    snackbar: {
      state: false,
      text: "",
    },
  }),

  mounted() {
    this.getListDetailPaket();
  },
  watch: {},
  computed: {},
  methods: {
    getListDetailPaket() {
      return new Promise((resolve, reject) => {
        axios
          .get(`subscription/history/${this.$route.params.id}`)
          .then((response) => {
            const { data } = response.data;
            this.dataDetail = data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    download() {
      this.submiting = true;
      this.axios
        .get(`subscription/history/${this.$route.params.id}/export`)
        .then((res) => {
          this.submiting = false;
          this.snackbar.text = res.data.message;
          this.snackbar.state = true;
          // this.url_ = res.data.data.public_url;
          console.log('url',res)
          this.forceFileDownload(res.data.data.url, this.dataDetail.no_invoice);
        })
        .catch((err) => {
          this.submiting = false;
          this.snackbar.text = err.response.data?.message;
          this.snackbar.state = true;
        });
    },
    forceFileDownload(url) {
      // console.log(url)

      function convertBase64ToBlob(base64Image) {
        // Split into two parts
        const parts = base64Image.split(";base64,");

        // Hold the content type
        const imageType = parts[0].split(":")[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
          uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: imageType });
      }

      const link = document.createElement("a");
      link.href = URL.createObjectURL(convertBase64ToBlob(url))
      link.setAttribute("download", `Invoice ${this.dataDetail.no_invoice}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style>
.bg-grey {
  background-color: #f9f9f9;
}
.text-total-bold {
  font-weight: 700;
  font-size: 14px !important;
}
@media screen and (max-width: 768px) {
  .d-xs-none {
    display: none;
  }
  .text-price-bold {
    font-size: 14px;
  }
}
</style>
