/** @format */

import axios from "axios";
import router from "../router";
import store from "../store";

export default function useFetch() {
    async function fetchData({ url, method, data, headers, responseType }) {
        try {
            let { data: res } = await axios({
                url,
                method,
                data,
                headers,
                responseType,
            });
            if (res.code == 404) {
                return {
                    hasError: true,
                    message: res.message,
                    response: res,
                };
            }
            if (res.code == 500) {
                return {
                    hasError: true,
                    message: res.message,
                    response: res,
                };
            } else if (res.code == 401) {
                localStorage.removeItem("momofin_auth");
                delete axios.defaults.headers.common["x-access-token"];
                store.commit("RESET_USER");
                router.push({
                    name: "login",
                });
            } else {
                return {
                    hasError: false,
                    message: res.message,
                    response: res,
                };
            }
        } catch (error) {
            return {
                hasError: true,
                response: error.response || "undefined error",
                message: error.message,
            };
        }
    }
    return {
        fetchData,
    };
}