<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="7">
        <div class="mb-12">
          <v-btn outlined @click="handleBack">
            <v-icon size="16" left>ri-arrow-left-line</v-icon>
            Kembali
          </v-btn>
        </div>

        <div class="mb-4">
          <div class="text-h5 mb-1">Pilih Durasi</div>
          <div>Silahkan pilih durasi untuk paket {{ (packages[0] || {}).name }} di bawah ini</div>
        </div>

        <v-row>
          <v-col v-for="item in packages" :key="item.id" cols="12" sm="6">
            <v-card outlined>
              <v-row no-gutters class="flex-row-reverse pa-4" justify="space-between" align="start">
                <div>
                  <v-radio-group class="mt-0" v-model="selectedDuration">
                    <v-radio :value="item.id"></v-radio>
                  </v-radio-group>
                </div>
                <div>
                  <div class="font-weight-bold mb-2">{{ item.time_period }} Bulan</div>
                  <div class="text-h4">
                    {{
                      item.price
                        .toLocaleString('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                        })
                        .slice(0, -3)
                    }}
                  </div>
                </div>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card outlined class="pa-4" color="grey lighten-4">
              <div class="text-h5 mb-4">Detail Paket</div>
              <div v-for="detail in details" :key="detail" class="mb-1">
                <v-icon :size="16" color="success" left>ri-checkbox-circle-line</v-icon>
                <span>{{ detail }}</span>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-center mt-4">
            <v-btn large color="primary" :loading="isCheckoutLoading" @click="handleCheckout"
              >Lanjut ke Pembayaran
              <v-icon right>ri-arrow-right-line</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => {
    return {
      isLoading: false,
      isCheckoutLoading: false,
      packages: [],
      selectedDuration: undefined,
    };
  },
  computed: {
    details() {
      return this.packages.find((v) => v.id == this.selectedDuration)?.description || [];
    },
  },
  mounted() {
    this.getDetailPackage();
  },
  methods: {
    handleBack() {
      this.$router.push('/dashboard');
    },
    async getDetailPackage() {
      this.isLoading = true;
      const { data: res } = await this.axios
        .get(`/subscription/plan/${this.$route.params.id}`)
        .finally(() => {
          this.isLoading = false;
        });
      this.packages = res.data;
      this.selectedDuration = this.packages[0].id;
    },
    async handleCheckout() {
      this.isCheckoutLoading = true;
      await this.axios
        .post(`/subscription`, { plan_id: this.selectedDuration })
        .then((res) => {
          this.submitting = false;
          const {
            data: { data },
          } = res;
          let url = '';
          if (data.amount == 0) {
            url = data.successRedirectURL;
          } else {
            url = data.invoice_url;
          }
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.click();
          document.removeChild(anchor);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isCheckoutLoading = false;
        });
    },
  },
};
</script>
