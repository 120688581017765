<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Buat Sertifikat</v-card-title>

    <v-card-text class="px-0">
      <div class="content">        
        <div class="table my-3">          
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">              
              <div class="row">
                <div class="col-md-6">                  
                  <v-text-field
                      outlined
                      append-icon="$search"
                      class="mt-1 ms-2 mx-width-85"
                      placeholder="Cari Berdasarkan Judul Template"
                      solo
                      flat
                    ></v-text-field>
                </div>
                <div class="col-md-6 text-end align-end">
                  <v-text-field
                    outlined
                      append-icon="ri-filter-fill"
                      class="mt-1 ms-2 mx-width-85 float-right"
                      placeholder="Filter"
                      solo
                      flat
                  ></v-text-field>                                  
                </div>
              </div>
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>
              <v-row align="center">
                <v-col lg="6">
                  <p class="fs-16 text-black font-weight-medium mb-0">Pilih Template Sertifikat yang Ingin Anda Gunakan</p>                  
                </v-col>

                <v-col id="search" lg="6" class="justify-end align-end text-right">                  
                  <v-btn
                    class="text-right"
                    color="primary"
                    elevation="0"
                    :to="{
                      name: 'create-certificate'
                    }"
                  >
                    Buat Template
                  </v-btn>
                </v-col>
              </v-row>
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>              
            </v-card-title>
            <div v-if="false" class="col-md-6 mx-auto my-2 text-center">
              <p class="font-weight-bold text-black mb-5">Anda belum memiliki Template</p>
              <p class="text-semu mb-5">Anda harus membuat template terlebih dahulu sebelum anda dapat <br> melanjutkan proses penerbitan sertifikat.</p>              
              <img src="@/assets/images/create-certif.svg"> 
            </div>
            <div v-else class="template px-2">              
              <div class="row">
                <div class="col-md-4">
                  <v-card>
                    <img src="@/assets/images/create-certif.svg" class="" style="width: 100%;">
                  </v-card>
                </div>
                <div class="col-md-4">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-1.png" class="" style="width: 100%;">
                    <p class="px-3 fs-16 text-black font-weight-medium">Product Design (UX/UI) Bootcamp for Beginners</p>
                    <v-card-text class="py-1 px-3">
                      <v-row
                        align="center"
                        class="mx-0"
                      >
                        <div class="col-md-6 px-0">
                          <p class="text-semu fs-12 font-weight-medium mb-2"><v-icon small>ri-calendar-check-line</v-icon> 22 Agustus 2021</p>
                        </div>
                        <div class="col-md-6 px-0">
                          <p class="text-semu fs-12 font-weight-medium mb-2"><v-icon small>ri-user-fill</v-icon> 32 Penerima</p>                        
                        </div>
                      </v-row>
                      <p class="text-semu font-italic fs-12">Terakhir Diubah: 23 Agustus 2021</p>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-4">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-2.png" class="" style="width: 100%;">
                    <p class="px-3 fs-16 text-black font-weight-medium">Webinar Financial Technology Peer to Peer Lending di Indonesia</p>
                    <v-card-text class="py-1 px-3">
                      <v-row
                        align="center"
                        class="mx-0"
                      >
                        <div class="col-md-6 px-0">
                          <p class="text-semu fs-12 font-weight-medium mb-2"><v-icon small>ri-calendar-check-line</v-icon> 22 Agustus 2021</p>
                        </div>
                        <div class="col-md-6 px-0">
                          <p class="text-semu fs-12 font-weight-medium mb-2"><v-icon small>ri-user-fill</v-icon> 32 Penerima</p>                        
                        </div>
                      </v-row>
                      <p class="text-semu font-italic fs-12">Terakhir Diubah: 23 Agustus 2021</p>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-4">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-3.png" class="" style="width: 100%;">
                    <p class="px-3 fs-16 text-black font-weight-medium">Webinar Immersive Media Technology</p>
                    <v-card-text class="py-1 px-3">
                      <v-row
                        align="center"
                        class="mx-0"
                      >
                        <div class="col-md-6 px-0">
                          <p class="text-semu fs-12 font-weight-medium mb-2"><v-icon small>ri-calendar-check-line</v-icon> 22 Agustus 2021</p>
                        </div>
                        <div class="col-md-6 px-0">
                          <p class="text-semu fs-12 font-weight-medium mb-2"><v-icon small>ri-user-fill</v-icon> 32 Penerima</p>                        
                        </div>
                      </v-row>
                      <p class="text-semu font-italic fs-12">Terakhir Diubah: 23 Agustus 2021</p>
                    </v-card-text>
                  </v-card>
                </div>                
              </div>
              <div class="col-md-12 px-0 py-4 align-end text-end">                
                <v-divider class="py-2"></v-divider>
                <v-btn
                    class="text-right my-2 mr-2 px-8"
                    color="primary"
                    elevation="0"
                    :to="{
                      name: 'list-certificate'
                    }"
                    outlined
                  >
                    Cancel
                  </v-btn>
                <v-btn
                    class="text-right my-2 px-8"
                    color="primary"
                    elevation="0"
                    :to="{
                      name: 'create-certificate-receive', params: { id: 123 }
                    }"
                  >
                    Next
                  </v-btn>
                  <v-divider class="mt-3"></v-divider>
              </div>   
            </div>
          </v-card>
        </div>        
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    data: () => ({
      dialog:false,
      menu:false,
      items: [
        {
          text: 'E-Certificate',
          disabled: false,
          href: '#',
        },
        {
          text: 'Certificate',
          disabled: false,
          href: '/list-certificate',
        },
        {
          text: 'Buat Sertifikat',
          disabled: true,
          href: '#',
        }        
      ],
      ex: ['1','2','3'],
      search: '',
        headers: [
          {
            text: 'JUDUL SERTIFIKAT',
            align: 'start',
            sortable: false,
            value: 'judul',
          },
          { text: 'TANGGAL DITERBITKAN', value: 'date' },
          { text: 'JUMLAH PENERIMA', value: 'jumlah' },
          { text: '', value: 'aksi' },          
        ],
        desserts: [
          {
            judul:'Product Design (UX/UI) Bootcamp for Beginners',
            date:'05 Agustus 2021',
            jumlah:'123'
          },
        ],
        dates:[],
    }),
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>

<style>
</style>