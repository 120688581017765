<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Need Approval Detail</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="row mb-5 mt-5 white">
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">DISBURSEMENT TITLE</p>
                <p class="fs-16 text-black font-weight-medium">{{ dataDetail.title }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">DESCRIPTION</p>
                <p class="fs-16 text-black font-weight-medium">{{ dataDetail.description }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-3 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">INPUT BY</p>
                <p class="fs-16 text-black font-weight-medium"><span class="text-capitalize">{{ dataDetail.creator_user_name }}</span> on {{ dataDetail.approved_at }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-3 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">APPROVED BY</p>
                <p class="fs-16 text-black font-weight-medium text-capitalize"><v-icon color="#FFC234">ri-error-warning-fill</v-icon> {{ dataDetail.approved_user_name }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">STATUS</p>
                <p class="fs-16 text-black font-weight-medium">
                  <v-chip
                    class="text-uppercase"                    
                    color="#F0AB0B"
                    outlined
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                </p>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="table col-md-12 px-0">          
          <v-card class="px-2" elevation="1">            
            <div class="row mb-3">
              <div class="col-md-12 align-middle">
                <p class="fs-14 text-black font-weight-medium mt-2 ps-2 mb-0">List of Transaction</p>                
              </div>              
              <div class="col-md-12">
                <v-divider></v-divider>
              </div>
              <div class="col-md-6">
                <v-text-field
                  outlined
                  v-model="searchApproval"
                  append-icon="$search"
                  class="pt-0 mt-0 mr-4 mx-width-85"
                  placeholder="Search"
                  solo
                  flat
                ></v-text-field>           
              </div>
              <div class="col-md-6 text-right">
                <v-btn color="primary" class="white--text ma-1" @click="dialog=true">
                  <v-icon class="mr-2">ri-checkbox-circle-line</v-icon> Approve Disbursement
                </v-btn>                
              </div>
            </div>
            
            <v-data-table
              :headers="headers"
              :items="dataDetailTransaction"
              :search="searchApproval"
              item-key="id"
              class="elevation-1"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.amount`]="{ item }">
                <span>{{ formatPrice(item.amount) }}</span>
              </template>

              <template v-slot:[`item.email`]="{ item }">
                <span>{{ item.email || '-' }}</span>
              </template>

              <template v-slot:[`item.note`]="{ item }">
                <span>{{ item.note || '-' }}</span>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="item.status === 'waiting' ? '#B272AF' : ''"
                  outlined                      
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>

          <v-snackbar
            v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>        
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="700"
    >
      <v-card class="pa-2">
        <p class="fs-18 text-black ma-2">
          Below are the batch disbursement details you are about to approve
        </p>
        <v-divider></v-divider>

        <v-card-text class="my-3">
          <div class="row mb-3">
            <div class="col-md-3 px-0" style="border-right: 1px solid #eee;border-bottom: 1px solid #eee;">
              <v-card rounded="lg" class="transparent px-0">
                <v-card-text class="py-5">
                  <p class="text-semu fs-12">TOTAL TRANSACTION</p>
                  <p class="fs-16 text-black font-weight-medium">{{ dataDetail.total_receipient }}</p>
                </v-card-text>
              </v-card>
            </div>
            <div class="col-md-3 px-0" style="border-right: 1px solid #eee;border-bottom: 1px solid #eee;">
              <v-card rounded="lg" class="transparent px-0">
                <v-card-text class="py-5">
                  <p class="text-semu fs-12">TOTAL AMOUNT</p>
                  <p class="fs-16 text-black font-weight-medium">Rp {{ formatPrice(dataDetail.total_amount) }}</p>
                </v-card-text>
              </v-card>
            </div>
            <div class="col-md-6 px-0" style="border-bottom: 1px solid #eee;">
              <v-card rounded="lg" class="transparent px-0">
                <v-card-text class="py-5">
                  <p class="text-semu fs-12">INPUT BY</p>
                  <p class="fs-16 text-black font-weight-medium"><span class="text-capitalize">{{ dataDetail.creator_user_name }}</span> on {{ dataDetail.approved_at }}</p>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <p class="mb-0"><v-icon color="#FFC234">ri-error-warning-fill</v-icon> Phasellus accumsan imperdiet tempor. Cras tincidunt, arcu nec eleifend porttitor, orci est vehicula velit, scelerisque fringilla ligula mi in urna. Nulla ut odio eu mi congue molestie. Maecenas tortor neque.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center px-5"
            outlined
            large
          >
            Cancel
          </v-btn>
          <!-- @click="dialog = false;pin=true" -->
          <v-btn
            color="primary"            
            class="text-center px-5"
            large
            @click="handleSubmit()"
            :loading="isLoading"
          >
            Approve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="pin"
      width="500"
      persistent
    >
      <v-card class="">
        <div class="text-end">
          
        <v-btn
            icon
            dark
            @click="pin = false"
            color="#333"
            class="float-right"
          >
            <v-icon>ri-close-fill</v-icon>
          </v-btn> 
        </div>

        <v-card-text class="my-3 text-center py-5">
          <img src="@/assets/images/img-otp.png" class="mb-3">
          <p class="text-black fs-16 font-weight-medium">Please input your PIN</p>          
          <v-row>
            <v-col
              id="kode"
              cols="12"
              sm="8"
              class="mx-auto"
            >
              <v-text-field
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>    
  </v-card>
</template>

<script>
import { formatPrice } from "@/lib/formatDate";
import axios from "axios";

export default {
  data: () => ({
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Bulk Disbursement',
        disabled: false,
        href: '/bulk-disbursement?tab=need-approval',
      },
      {
        text: 'Need Approval Detail',
        disabled: true,
        href: '#',
      },
    ],
    dialog:false,
    pin:false,
    searchApproval: "",
    headers: [
      { text: 'TRANSACTION ID.', value: 'bulk_disbursement_id' },
      { text: 'BANK CODE', value: 'receipient_bank_name' },
      { text: 'ACCOUNT NO.', value: 'receipient_account_number' },
      { text: 'ACCOUNT NAME', value: 'receipient_account_name' },
      { text: 'AMOUNT', value: 'amount' },
      { text: 'EMAIL', value: 'email' },
      { text: 'STATUS', value: 'status' },
      { text: 'NOTES', value: 'note' },
    ],
    showMessage: '',
    isError: false,
    isLoading: false,
    dataDetail: {},
    dataDetailTransaction: [],
    serverParams: {
      disbursement_id: ""
      // id: "",
      // type: ""
    }
  }),
  mounted() {
    this.getListBulkDisbursmentDetail2();
  },
  computed: {
    idParam: {
      get() {
        return this.$route.params.id;
      }
    }
  },
  methods: {
    getListBulkDisbursmentDetail2() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
          axios
          .get(`etransfer/disbursement/bulk/receipients/${this.idParam}` )
          .then(response => {
            this.dataDetail = response.data.result.detail;
            this.dataDetailTransaction = response.data.result.receipients.data;
            this.serverParams.disbursement_id = this.dataDetail.id;
            // this.serverParams.id = this.dataDetail.id;
            // if(this.dataDetail.type == "bulk") {
            //   this.serverParams.type = this.dataDetail.type;
            // } else {
            //   this.serverParams.type = "";
            // }
            // this.serverParams.type = this.dataDetail.type;
            this.isLoading = false;
            resolve(response);
          })
          .catch(error => {
              reject(error);
              this.isLoading = false;
          });
      });
    },

    handleSubmit() {
      // console.log('object', this.serverParams);
      setTimeout(() => {
        this.isLoading = true;
        this.$store.dispatch("validateNeedApproval2", this.serverParams)
        .then((response) => {
          this.isError = true;
          this.showMessage = response.data.message;
          this.isLoading = false;
          setTimeout( () => {
            this.$router.push({path: '/bulk-disbursement', query: { tab: 'approval' }})
            // this.$router.push({path: '/history-money'})
          }, 1000);
        })
        .catch((error) => {
          this.isLoading = false;
          this.isError = true;
          this.showMessage = error.response.data.message;
        });
      }, 50);
    },

    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style>
</style>