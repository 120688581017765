<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="7">
        <div class="text-center mb-4">
          <v-icon size="48" color="success">ri-checkbox-circle-fill</v-icon>
        </div>
        <div class="text-h5 text-center mb-2">{{ detail.title }}</div>

        <div class="text-center mb-6">{{ detail.subtitle }}</div>

        <v-card outlined class="pa-4 mx-0 mx-sm-16" color="grey lighten-4">
          <div class="text-h5 mb-4">Detail Paket</div>
          <div v-for="item in descriptions" :key="item" class="mb-1">
            <v-icon :size="16" color="success" left>ri-checkbox-circle-line</v-icon>
            <span>{{ item }}</span>
          </div>
        </v-card>

        <div class="text-center mt-4">
          <v-btn color="primary" @click="handleBack">Lanjut</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return { isLoading: false, detail: {} };
  },
  computed: {
    descriptions() {
      return this.detail.infoPackage?.detail || [];
    },
  },
  mounted() {
    this.getDetailPackage();
  },
  methods: {
    async getDetailPackage() {
      this.isLoading = true;
      const { data: res } = await this.axios
        .get(`/subscription/success/${this.$route.params.id}`)
        .catch(() => {
          this.$router.push('/404');
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.detail = res.data;
    },
    handleBack() {
      this.$router.push('/dashboard');
    },
  },
};
</script>
