<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black">Transaction Details</v-card-title>

    <v-card-text>
      <div class="content">
        <div class="table my-3 col-md-8">          
          <v-card class="mt-5" elevation="1">            
            <v-card-actions class="grey lighten-3">
              <v-list-item class="grow">                
                <v-list-item-content>
                  <v-list-item-title>AMOUNT</v-list-item-title>
                  <p class="text-black font-weight-medium fs-18">
                    Rp. 20.000.000
                  </p>
                </v-list-item-content>

                <v-row
                  align="center"
                  justify="end"
                >
                  <v-chip
                    class="ma-2"                    
                    color="green"
                    outlined
                  >
                    Completed
                  </v-chip>
                </v-row>
              </v-list-item>
            </v-card-actions>
            <v-simple-table height="300px">
              <template v-slot:default>
                <!-- <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Calories
                    </th>
                  </tr>
                </thead> -->
                <tbody>
                  <tr>
                    <td>DATE</td>
                    <td>15 May 2020, 9:30 WIB</td>
                  </tr>
                  <tr>
                    <td>BANK ACCOUNT NO.</td>
                    <td>7725068610</td>
                  </tr>
                  <tr>
                    <td>BANK CODE</td>
                    <td>MANDIRI</td>
                  </tr>
                  <tr>
                    <td>ACCOUNT NAME</td>
                    <td>Ronald Richards</td>
                  </tr>
                  <tr>
                    <td>REMARKS</td>
                    <td>Elit eget gravida</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>        
      </div>
    </v-card-text>    
  </v-card>
</template>

<script>
export default {
    data: () => ({
      items: [
        {
          text: 'E-Transfer',
          disabled: false,
          href: '#',
        },
        {
          text: 'Send Money',
          disabled: false,
          href: '#',
        },
        {
          text: 'Disbursement',
          disabled: false,
          href: '/disbursement',
        },
        {
          text: 'Transaction Details',
          disabled: true,
          href: '#',
        },
      ],      
    }),
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>

<style>
</style>