var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent",attrs:{"rounded":"lg"}},[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("ri-arrow-right-s-line")])]},proxy:true}])}),_c('v-card-title',{staticClass:"text-black pt-0 px-0 fs-20 font-weight-bold"},[_vm._v("Certificate")]),_c('v-card-text',{staticClass:"px-0"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"table my-3"},[_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',{staticClass:"font-weight-regular"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"lg":"6"}},[_c('p',{staticClass:"fs-16 text-black font-weight-medium"},[_vm._v(" List of Certificate ")])]),_c('v-col',{staticClass:"justify-end align-end text-right",attrs:{"id":"search","lg":"6"}},[_c('v-btn',{staticClass:"text-right",attrs:{"color":"primary","elevation":"0","to":{
                    name: 'create-certificate',
                  }}},[_vm._v(" Create Certificate ")])],1)],1),_c('div',{staticClass:"col-md-12 px-0 py-4"},[_c('v-divider')],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('v-text-field',{staticClass:"mt-1 ms-2 mx-width-85",attrs:{"outlined":"","append-icon":"$search","placeholder":"Search","solo":"","flat":""},on:{"change":_vm.searchQuery},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-md-6 text-end align-end"},[_c('v-text-field',{staticClass:"mt-1 ms-2 mx-width-85 float-right",attrs:{"outlined":"","append-icon":"ri-filter-fill","placeholder":"Filter","solo":"","flat":""}})],1)])],1),(!_vm.data.certificate && _vm.hasError && !_vm.isLoading)?_c('div',{staticClass:"col-md-6 mx-auto my-2"},[_c('img',{attrs:{"src":require("@/assets/images/certif-empty.svg")}}),_c('p',{staticClass:"font-weight-bold text-black mb-2"},[_vm._v("Sertifikat Kosong")]),_c('p',{staticClass:"text-semu"},[_vm._v(" Create a new certificate or try to narrow the search if you can't find the certificate you're looking for ")])]):_c('v-data-table',{attrs:{"loading":_vm.loadingData || _vm.isLoading,"loading-text":"Loading","hide-default-footer":"","headers":_vm.headers,"items":_vm.data.certificate},scopedSlots:_vm._u([{key:"item.judul",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{staticClass:"primary--text bg-none",attrs:{"to":{name: 'certificate-detail', params: {id: item.id}}}},[_c('v-list',{staticClass:"bg-none",attrs:{"three-line":""}},[_c('v-list-item',{staticClass:"pl-0"},[_c('img',{staticClass:"img-table mr-1 ms-0",attrs:{"src":item.preview,"loading":"lazy"}}),_c('v-list-item-content',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1)]}},{key:"item.issuance",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"dddd, D MMMM YYYY"))+" ")]}},{key:"item.recipient",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.recipient)+" "+_vm._s(item.recipient == 1 ? 'Recipient' : 'Recipients')+" ")]}},{key:"item.aksi",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 mb-1",attrs:{"outlined":"","color":"primary","small":""},on:{"click":function($event){return _vm.openPreview(item)}}},[_vm._v("Preview ")]),_c('v-btn',{staticClass:"mb-1",attrs:{"outlined":"","color":"primary","small":"","to":{
                  name: 'edit-create-certificate-receive',
                  params: {
                    id: item.id,
                  },
                }}},[_vm._v("Edit")])]}}],null,true)}),(!_vm.isLoading && !_vm.hasError)?_c('div',{staticClass:"text-center py-5"},[_c('v-pagination',{attrs:{"length":_vm.data.pagination && _vm.data.pagination.total_page},on:{"input":_vm.nextPage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)],1)])]),_c('v-dialog',{attrs:{"max-width":"60%"},model:{value:(_vm.preview),callback:function ($$v) {_vm.preview=$$v},expression:"preview"}},[_c('v-card',{staticClass:"d-flex justify-center items-center"},[_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"loading":"lazy","src":_vm.previewData.preview,"alt":"image"}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }