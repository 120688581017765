<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Create Scheduled Disbursement</v-card-title>

    <!-- <template v-if="snackbar === true">
      <v-alert
        v-model="snackbar"
        type="success"
        class="mb-0"
        transition="scale-transition"
        dismissible
      >
        {{ messageSuccess }}
      </v-alert>
    </template> -->

    <v-card-text class="px-0 pt-0">
      <div class="content">
        <v-form
          ref="form"
          @submit.prevent="submit"
          v-model="valid"
        >

          <div class="table my-3">          
            <v-card class="mt-2 pa-2" elevation="1">
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Select Disbursement type </v-subheader>
                </v-col>
                <v-col cols="7">

                  <v-radio-group
                    v-model="serverParams.type"
                    row
                    :rules="[v => !!v || 'Type disbursement is required']"
                  >
                    <v-radio
                      class="type"
                      label="Single Disbursement"
                      value="single"
                    ></v-radio>
                    <v-radio
                      class="type"
                      label="Bulk Disbursement"
                      value="bulk"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <div class="col-md-12 px-0">              
                <v-divider class="py-2"></v-divider>
              </div>

              <p class="text-black font-weight-medium fs-14 ps-4">Transaction Info</p>            
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Disbursement Title</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    filled
                    required
                    :rules="[v => !!v || 'Disbursement title is required']"
                    outlined
                    hide-details="auto"
                    v-model="serverParams.title"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Description</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-textarea
                    outlined
                    v-model="serverParams.description"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Enable Recurring Bulk Transfer</v-subheader>
                </v-col>
                <v-col cols="7">
                  <div class="d-flex align-center">                  
                    <v-switch
                      v-model="serverParams.recurring"
                      @change="enableRecurring(serverParams.recurring)"
                    ></v-switch>

                    <v-subheader class="text-black font-weight-thin fs-14">Select Schedule</v-subheader>
                    <v-select
                      :disabled="disabled"
                      v-model="serverParams.recurring_periode"
                      :items="periode"
                      :item-value="`value`"
                      item-text="label"
                      placeholder="Select Periode"
                      :rules="[v => !!v || 'Periode is required']"
                      class="ma-1"
                    ></v-select>
                    <!-- @change="getListScheduleDisbursment(columnFilter.ex)" -->

                    <v-dialog
                      :disabled="disabled"
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="serverParams.recurring_date"
                      :rules="[v => !!v || 'Date is required']"
                      persistent
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="serverParams.recurring_date"
                          prepend-icon="ri-calendar-fill"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="serverParams.recurring_date"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(serverParams.recurring_date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>

                    <v-btn color="grey darken-4" class="white--text ms-2 d-none" style="min-height: 48px;">
                      Save
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <div class="col-md-12 px-0">              
                <v-divider class="py-2"></v-divider>
              </div>

              <p class="text-black font-weight-medium fs-14 ps-4">Recipient</p>            
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Select from Group Data</v-subheader>
                </v-col>
                <v-col cols="7">
                  <div class="d-flex">
                    <v-btn color="primary" class="white--text ma-1" v-on:click="isListDisbursement = !isListDisbursement">
                      New Disbursement List
                    </v-btn>
                    <p class="text-black my-auto mx-4">or</p>
                    <v-btn color="primary" outlined class="ma-1" >
                      Select from Receiver Group 
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <template v-if="isListDisbursement">
                <v-row>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">BANK CODE</th>
                            <th class="text-left">ACCOUNT NUMBER</th>
                            <th class="text-left">ACCOUNT NAME</th>
                            <th class="text-left">AMOUNT</th>
                            <th class="text-left">NOTES</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(receipient, idx) in receipients" :key="idx"  class="pb-2">
                            <td class="py-2">
                              <v-select
                                v-model="receipients[idx].receipient_bank_id"
                                :rules="[v => !!v || 'Bank name is required']"
                                :items="getBankName"
                                item-text="name"
                                item-value="id"
                                placeholder="Select bank"
                              ></v-select>
                            </td>
                            <td>
                              <v-text-field
                                v-model="receipients[idx].receipient_account_number"
                                :rules="[v => !!v || 'Account number is required']"
                                type="number"
                                min="0"
                                filled
                                required
                                outlined
                                hide-details="auto"
                                placeholder="Account number"
                              ></v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                v-model="receipients[idx].receipient_account_name"
                                :rules="[v => !!v || 'Account name is required']"
                                filled
                                required
                                outlined
                                hide-details="auto"
                                placeholder="Account name"
                              ></v-text-field>
                            </td>
                            <td>
                              <!-- <v-text-field
                                v-model.number="receipients[idx].amount"
                                :rules="[v => !!v || 'Amount is required']"
                                type="number"
                                min="0"
                                filled
                                required
                                outlined
                                hide-details="auto"
                                placeholder="Rp. "
                              ></v-text-field> -->
                              <MyCurrencyInput v-model="receipients[idx].amount"></MyCurrencyInput>
                            </td>
                            <td>
                              <v-text-field
                                v-model="receipients[idx].note"
                                filled
                                required
                                outlined
                                hide-details="auto"
                                placeholder="Add note"
                              ></v-text-field>
                            </td>
                            <td>
                              <template v-if="idx !== 0">
                                <v-btn
                                  small
                                  dark
                                  @click="deleteRowRecipient(idx, receipient)"
                                >
                                  <v-icon dark>
                                  ri-delete-bin-6-line
                                  </v-icon>
                                </v-btn>
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <template>
                      <v-btn block large class="my-4" @click.prevent="addNewRowRecipient">
                        <v-icon class="mr-2">ri-add-circle-fill</v-icon> ADD MORE
                      </v-btn>
                    </template>

                  </v-col>
                </v-row>
              </template>

              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Import Data</v-subheader>
                </v-col>
                <v-col cols="7">
                  <div
                    :class="{'has-file': showRemovedBtn}"
                    class="c-file-input js-file-input"
                    @dragover.prevent @drop="onDrop"
                    >
                    <div class="c-file-input__indicator">
                      <span class="c-file-input__indicator__icon c-icon c-icon--attach"></span>
                    </div>
                    <label class="c-file-input__label js-file-input__label" for="inputfile">
                      <span>{{ theFileName }}</span>
                      <input
                        id="inputfile"
                        type="file" @change="fileChangeHandler"
                        name="attachment" class="c-file-input__field js-file-input__field">
                    </label>
                    <!-- <div @click.stop="clearFileHandler" class="c-file-input__remove js-file-input__remove">
                      <span class="c-file-input__remove__icon c-icon c-icon--remove-circle"></span>
                      <v-icon class="c-file-input__remove__icon c-icon c-icon--remove-circle">ri-close-circle-fill</v-icon>
                    </div> -->
                  </div>
                  <p class="text-black font-weight-thin">Download this <a href="#">excel</a> or <a href="#">csv</a> template</p>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-card-actions>
                <div class="row">
                  <div class="col-sm-12 text-right">
                    <v-btn color="primary" outlined class="ma-1 px-5" @click="validateField">
                        VALIDATE
                    </v-btn>
                    <!-- <v-btn color="primary" dark class="ma-1 px-3" @click="dialog=true"> -->
                    <v-btn color="primary" dark class="ma-1 px-3" @click="handleStored" :loading="submiting">
                        SUBMIT
                    </v-btn>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </div>  

        </v-form>

        <v-snackbar
          v-model="isError"
        >
          {{ showMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="isError = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>

      </div>
    </v-card-text>    
  </v-card>
</template>

<script>
export default {
  props: {
    labelPlaceholder: {
      type: String,
      default: 'Add File or drag and drop file here',
    },
  },
  data: () => ({
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Scheduled Disbursement',
        disabled: false,
        href: '/schedule-disbursement',
      },
      {
        text: 'Create Scheduled Disbursement',
        disabled: true,
        href: '#',
      },
    ],
    disabled: true,
    isListDisbursement: false,
    isError: false,
    valid: true,
    selectedItem:0, 
    menu: false,
    date: new Date().toISOString(0, 7),
    modal: false,     
    image: '',
    value: '',
    files: [],
    periode: [
      {value: "weekly", label: "Weekly"},
      {value: "monthly", label: "Monthly"},
      {value: "anually", label: "Anually"}
    ],
    showRemovedBtn: false,
    snackbar: false,
    submiting: false,
    messageSuccess: '',
    showMessage: '',
    serverParams: {
      title: '',
      description: '',
      recurring: false,
      recurring_periode: "",
      recurring_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      type: "",
    },
    receipients: [{
      receipient_bank_id: "",
      receipient_account_name: "",
      receipient_account_number: "",
      amount: 0,
      note: "",
    }],
  }),
  beforeCreate: function () {
    this.$options.components.MyCurrencyInput = require('../user/MyCurrencyInput.vue').default
  },
  mounted() {
    this.loadBankName();
    this.loadBankAccount();
  },
  computed: {
    theFileName() {
      if ((this.files.length > 0) && 'name' in this.files[0]) {
        return this.files[0].name;
      }
      return this.labelPlaceholder;
    },
    getBankName() {
      return this.$store.state.sendMoney.getBankName;
    },
    getBankAccount() {
      return this.$store.state.sendMoney.getBankAccount;
    },
    getParam() {
      return { 
        ...this.serverParams,
        receipients: this.receipients
      }
    },
  },
  methods: {
    onDrop: function(e) {
        e.stopPropagation();
        e.preventDefault();
        var files = e.dataTransfer.files;
        this.fileChangeHandler(files[0]);
    },
    fileChangeHandler(e) {
      this.files = Array.from(e.target.files);
      this.showRemovedBtn = true;
    },
    clearFileHandler() {
      const el = this.$el.querySelector('.js-file-input__field');
      const wrapper = document.createElement('form');
      this.wrapInputAndReset(el, wrapper);
    },
    wrapInputAndReset(el, wrapper) {
      // wrap input with form tag
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);

      // reset input with form.reset()
      wrapper.reset();

      // place childNodes in document fragment
      const docFrag = document.createDocumentFragment();
      while (wrapper.firstChild) {
        const child = wrapper.removeChild(wrapper.firstChild);
        docFrag.appendChild(child);
      }

      // replace wrapper with document fragment
      wrapper.parentNode.replaceChild(docFrag, wrapper);

      this.files = [];
      this.showRemovedBtn = false;
    },
    loadBankName() {
      this.$store.dispatch("getBankName");
    },
    loadBankAccount() {
      this.$store.dispatch("getBankAccount");
    },

    handleStored() {
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          this.$store.dispatch("createSchedule", this.getParam)
          .then((response) => {
            this.isError = true;
            this.showMessage = response.data.message;
            this.submiting = false;
            // this.$refs.form.reset();
            setTimeout( () => {
              this.$router.push({path: 'schedule-disbursement'})
            }, 1500);
          })
          .catch((error) => {
            this.submiting = false;
            this.isError = true;
            this.showMessage = error.response.data.message;
          });
          this.snackbar = false;
        }
      }, 50);
    },

    addNewRowRecipient() {
      this.receipients.push({
        receipient_bank_id: "",
        receipient_account_name: "",
        receipient_account_number: "",
        amount: 0,
        note: "",
      });
    },
    deleteRowRecipient(index, receipient) {
      var idx = this.receipients.indexOf(receipient);
      if (idx > -1) {
        this.receipients.splice(idx, 1);
      }
    },

    enableRecurring(val) {
      if (val === true) this.disabled = false;
      else this.disabled = true;
    },

    validateField() {
      this.$refs.form.validate();
    }
  },
};
</script>

<style scoped lang="scss">
  .v-radio.type.v-item--active {
    border-color: #409eff;
    color: #409eff !important;
  }
  
  .v-radio.type {
    padding: 10px 20px 10px 10px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
  }
</style>

<style>
  .type .theme--light.v-label {
    color: inherit;
  }
</style>