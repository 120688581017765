<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Create Request Transfer</v-card-title>

    <v-card-text class="px-0">
      <div class="content col-md-12">
        <v-form
          ref="form"
          @submit.prevent="submit"
          v-model="valid"
        >
          <v-card class="mt-2" elevation="1">            
            <div class="table my-3">          
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Description</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    filled
                    required
                    :rules="[v => !!v || 'Description is required']"
                    outlined
                    hide-details="auto"
                    placeholder="Type description here"
                    v-model="serverParams.description"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Amount</v-subheader>
                </v-col>
                <v-col cols="7">
                  <!-- <v-text-field
                    filled
                    required
                    outlined
                    hide-details="auto"
                    placeholder="Rp."
                    type="number"
                    min="0"
                    v-model.number="serverParams.total_amount"
                  ></v-text-field> -->
                  <MyCurrencyInput v-model="serverParams.total_amount"></MyCurrencyInput>
                </v-col>
              </v-row>
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Enable Recurring</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-switch
                    v-model="serverParams.recurring"
                    flat
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Transaction ID</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    filled
                    required
                    :rules="[v => !!v || 'Transaction ID is required']"
                    outlined
                    hide-details="auto"
                    placeholder="Unique transaction ID"
                    v-model="serverParams.transaction_id"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Fund Source</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-combobox
                    v-model="model"
                    :filter="filter"
                    :hide-no-data="!search"
                    :items="methods"
                    :search-input.sync="search"
                    hide-selected
                    label="Search for an option"
                    multiple
                    small-chips
                    solo
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <span class="subheading">Create</span>
                        <v-chip
                          color="primary"
                          label
                          small
                          outlined
                        >
                          {{ search }}
                        </v-chip>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        color="primary"
                        :input-value="selected"
                        label
                        small
                        outlined
                      >
                        <span class="pr-2">
                          {{ item.text }}
                        </span>
                        <v-icon
                          small
                          @click="parent.selectItem(item)"
                        >
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ index, item }">
                      <v-text-field
                        v-if="editing === item"
                        v-model="editing.text"
                        autofocus
                        flat
                        background-color="transparent"
                        hide-details
                        solo
                        @keyup.enter="edit(index, item)"
                      ></v-text-field>
                      <v-chip
                        v-else
                        color="primary"
                        dark
                        label
                        small
                        outlined
                      >
                        {{ item.text }}
                      </v-chip>
                      <v-spacer></v-spacer>
                      <v-list-item-action @click.stop>
                        <v-btn
                          icon
                          @click.stop.prevent="edit(index, item)"
                        >
                          <v-icon>{{ editing !== item ? 'ri-pencil-fill' : 'ri-check-circle-fill' }}</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-combobox>   
                </v-col>
              </v-row>
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Additional Note</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Type additional notes here"
                    v-model="serverParams.note"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="col-md-12 my-3">
                <v-divider></v-divider>
              </div>
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Send request transfer link to customer by email </v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-switch
                    v-model="serverParams.send_email"
                    flat
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Invoice Due Date</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="serverParams.due_date"
                    persistent
                    required
                    :rules="[v => !!v || 'Due date is required']"
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="serverParams.due_date"
                        prepend-icon="ri-calendar-fill"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="serverParams.due_date"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(serverParams.due_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <div class="col-md-12 my-3">
                <v-divider></v-divider>
              </div>
              <p class="text-black font-weight-medium ps-4">Customer Detail (optional)</p>
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Full Name</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    filled
                    required
                    :rules="[v => !!v || 'Full name is required']"
                    outlined
                    hide-details="auto"
                    placeholder="Name of your customer"
                    v-model="serverParams.fullname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Phone Number</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    filled
                    required
                    :rules="[v => !!v || 'Phone number is required']"
                    outlined
                    hide-details="auto"
                    placeholder="+62"
                    v-model="serverParams.mobilephone"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin: 0;">
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Email Address</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    filled
                    required
                    outlined
                    hide-details="auto"
                    type="email"
                    placeholder="example@mail.com"
                    v-model="serverParams.email"
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>             
              <v-btn color="primary" dark class="ma-1 px-3" @click="handleStored" :loading="submiting">
                Create Request Transfer Link
              </v-btn>  
            </v-card-actions>        
          </v-card>
        </v-form>

        <v-snackbar
          v-model="isError"
        >
          {{ showMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="isError = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm Disbursement
        </v-card-title>

        <v-card-text class="my-3">          
          <v-simple-table>
            <template v-slot:default>              
              <tbody>
                <tr>
                  <td>TOTAL AMOUNT</td>
                  <td>Rp 31.234.000</td>
                </tr>
                <tr>
                  <td>SEND TO</td>
                  <td>MANDIRI - 123456789012345 <br>Cameron Williamson </td>
                </tr>
                <tr>
                  <td>NO. REFERENCE</td>
                  <td>DF789012345003</td>
                </tr>
                <tr>
                  <td>DESCRIPTION</td>
                  <td>Montes nascetur ridiculus</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-card>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Receive Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Request Ttransfer',
        disabled: false,
        href: '/request-transfer',
      },
      {
        text: 'Create Request Transfer',
        disabled: true,
        href: '#',
      },
    ], 
    ex:['a','b'],
    switch1:false,
    switch2:false,
    dialog:false,
    activator: null,
    attach: null,
    editing: null,
    editingIndex: -1,
    methods: [
      { header: 'Select or Add Request Transfer Option ' },
      {
        text: 'Credit Card',
      },
      {
        text: 'Bank Transfer',
      },
      {
        text: 'E-Wallet',
      },
    ],
    nonce: 1,
    menu: false,
    model: [
      {
        text: 'Credit Card',
      },
    ],
    x: 0,
    search: null,
    y: 0,
    date: new Date().toISOString().substr(0, 7),
    menuDate: false,
    modal: false,
    valid: true,
    submiting: false,
    isError: false,
    showMessage: "",
    emailRules: [
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    serverParams: {
      fullname: "",
      mobilephone: "",
      email: "",
      description: "",
      transaction_id: "",
      payment_option: [],
      total_amount: 0,
      due_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      note: "",
      send_email: false,
      recurring: false,
      recurring_periode: "",
    }
  }),
  beforeCreate: function () {
    this.$options.components.MyCurrencyInput = require('../user/MyCurrencyInput.vue').default
  },
  mounted() {
    
  },
  watch: {
    model (val, prev) {
      if (val.length === prev.length) return

      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: 'primary',
          }

          this.items.push(v)

          this.nonce++
        }

        return v
      })
    },
  },
  methods: {
    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        this.editing = null
        this.editingIndex = -1
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },

    handleStored() {
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          this.$store.dispatch("createPayment", this.serverParams)
          .then((response) => {
            this.isError = true;
            this.showMessage = response.data.message;
            this.submiting = false;
            // this.$refs.form.reset();
            setTimeout( () => {
              this.$router.push({path: 'request-transfer'})
            }, 1500);
          })
          .catch((error) => {
            this.submiting = false;
            this.isError = true;
            this.showMessage = error.response.data.message;
          });
        }
      }, 50);
    },
  },
};
</script>

<style>
</style>