<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Buat Template</v-card-title>

    <v-card-text class="px-0">
      <div class="content">        
        <div class="table my-3">          
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">                            
              <p class="ms-4 fs-16 text-black font-weight-medium mb-0">Certificate Info</p>
              <div class="col-md-12 px-0">                                 
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">Certificate Title</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      class=""
                      placeholder="Certificate title"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">Description</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-textarea
                      outlined
                      class=""
                      placeholder="Description"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>
              <p class="ms-4 fs-16 text-black font-weight-medium mb-0">Jenis Sertifikat</p>              
              <div class="col-md-12">
                <v-radio-group
                  v-model="type"
                  column
                >
                  <v-radio
                    label="Degree"
                    color="success"
                    value="Degree"
                  ></v-radio>
                  <v-radio
                    label="Certificate"
                    color="success"
                    value="Certificate"
                  ></v-radio>
                </v-radio-group>                                 
                <!-- <v-checkbox
                  v-model=""
                  label="Degree"
                  color="success"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-model=""
                  label="Certificate"
                  color="success"
                  hide-details
                ></v-checkbox>   -->    
              </div>              
            </v-card-title>
            <div class="col-md-12 px-0 py-4">                
              <v-divider></v-divider>
            </div>
            <div v-show="type==='Certificate'" class="template px-2">
              <p class="ms-4 fs-16 text-black font-weight-medium mb-0 py-4">Pilih desain yang ingin digunakan</p> 
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>                                    
              <div class="row">                
                <div class="col-md-3">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-1.png" class="" style="width: 100%;">
                    <v-card-text class="py-1 px-3">
                      <p class="s-16 text-black font-weight-medium">Desain 1</p>                      
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-3">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-2.png" class="" style="width: 100%;">
                    <v-card-text class="py-1 px-3">
                      <p class="s-16 text-black font-weight-medium">Desain 2</p>                      
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-3">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-3.png" class="" style="width: 100%;">
                    <v-card-text class="py-1 px-3">
                      <p class="s-16 text-black font-weight-medium">Desain 3</p>                      
                    </v-card-text>                    
                  </v-card>
                </div>                
                <div class="col-md-3">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-3.png" class="" style="width: 100%;">
                    <v-card-text class="py-1 px-3">
                      <p class="s-16 text-black font-weight-medium">Desain 4</p>                      
                    </v-card-text>
                  </v-card>
                </div> 
              </div>  
            </div>
            <div v-show="type==='Degree'" class="template px-2">
              <p class="ms-4 fs-14 text-black font-weight-medium mb-0 py-4">Complete Data Credential</p>               
              <div class="col-md-12 px-0">                                  
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">University Accreditation Number</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      class=""
                      placeholder="Input University Accreditation Number "
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">Degree Type</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      class=""
                      placeholder="Input Degree Type"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">Degree Major</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      class=""
                      placeholder="Input Degree Major"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">Degree Title</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      class=""
                      placeholder="Input Degree Title"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">Major Accreditation Number</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      class=""
                      placeholder="Input Major Accreditation Number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">Issuance Place</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      class=""
                      placeholder="Input Issuance Place"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">QR Code</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      elevation="0"
                    >
                      <v-date-picker
                        ref="picker"
                        v-model="date"
                        min="1950-01-01"
                        :max="(new Date()).toISOString()"
                        outlined
                        required
                      ></v-date-picker>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          append-icon="ri-calendar-line"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          class=""
                          placeholder="DD-MM-YYYY"
                        ></v-text-field>
                      </template>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>
              <p class="ms-4 fs-14 text-black font-weight-medium mb-0 py-4">Masukkan Penanda Tangan</p>
              <p class="fs-14 ms-4">Tambahkan penanda tangan untuk credential template. Credential minimal memiliki 1 penanda tangan.</p>
              <div class="col-md-12 px-0">                                  
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">Penandatangan 1</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <div class="d-flex"> 
                      <v-text-field
                        outlined
                        class="mr-2"
                        placeholder="Name"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        class=""
                        placeholder="Title"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="align-center text-center">
                    <v-btn
                      class="text-right px-8"
                      color="primary"
                      outlined
                      elevation="0"
                    >
                      Tambah Penandatangan
                    </v-btn>
                  </v-col>
                </v-row>
              </div> 
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>
              <p class="ms-4 fs-14 text-black font-weight-medium mb-0 py-4">Pilih desain yang ingin digunakan</p> 
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>
              <div class="row">                
                <div class="col-md-3">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-1.png" class="" style="width: 100%;">
                    <v-card-text class="py-1 px-3">
                      <p class="s-16 text-black font-weight-medium">Desain 1</p>                      
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-3">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-2.png" class="" style="width: 100%;">
                    <v-card-text class="py-1 px-3">
                      <p class="s-16 text-black font-weight-medium">Desain 2</p>                      
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-3">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-3.png" class="" style="width: 100%;">
                    <v-card-text class="py-1 px-3">
                      <p class="s-16 text-black font-weight-medium">Desain 3</p>                      
                    </v-card-text>                    
                  </v-card>
                </div>                
                <div class="col-md-3">
                  <v-card elevation="1">
                    <img src="@/assets/images/certif-3.png" class="" style="width: 100%;">
                    <v-card-text class="py-1 px-3">
                      <p class="s-16 text-black font-weight-medium">Desain 4</p>                      
                    </v-card-text>
                  </v-card>
                </div> 
              </div>  
            </div>
            <div class="col-md-12 px-0 py-4">                
              <v-divider></v-divider>
            </div>
            <div class="col-md-12 align-end text-end">                
              <v-btn
                class="text-right mr-2 px-8"
                color="primary"
                elevation="0"
                :to="{
                name: 'list-template'
                }"
                outlined
              >
                Cancel
              </v-btn>
              <v-btn
                class="text-right px-8"
                color="primary"
                elevation="0"
                @click="dialog=true"
              >
                Create Template
              </v-btn>
            </div>   
          </v-card>
          <div class="py-5 text-center">
            <img src="@/assets/images/faq.png">
            <p class="text-black fs-14 ">Tidak menemukan yang anda cari? Anda dapat mengajukan permintaan desain kepada kami</p>
            <v-btn
                class="text-right px-8"
                color="primary"
                elevation="0"
                :to="{name: 'request-desain'
                }"
              >
                Ajukan Request Desain
              </v-btn>
          </div>
        </div>        
      </div>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      width="600"
      persistent      
    >
      <v-card>
        <v-card-title class="text-h5 text-black text-center mx-auto">
          Anda yakin akan membuat Template
        </v-card-title>

        <v-card-text class="my-3">
          <v-alert
            outlined
            type="warning"
            class="fs-14"
          >
            Periksa pilihan anda sebelum melanjutkan ke pembuatan template
          </v-alert>                        
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center px-3"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center px-3"
          >
            Create Template
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
    data: () => ({
      dialog:false,
      menu:false,
      date:null,      
      type:'',
      items: [
        {
          text: 'E-Certificate',
          disabled: false,
          href: '#',
        },
        {
          text: 'Template',
          disabled: false,
          href: '/list-template',
        },
        {
          text: 'Create Template',
          disabled: true,
          href: '#',
        }        
      ],
      ex: ['1','2','3'],
      search: '',
        headers: [
          {
            text: 'JUDUL SERTIFIKAT',
            align: 'start',
            sortable: false,
            value: 'judul',
          },
          { text: 'TANGGAL DITERBITKAN', value: 'date' },
          { text: 'JUMLAH PENERIMA', value: 'jumlah' },
          { text: '', value: 'aksi' },          
        ],
        desserts: [
          {
            judul:'Product Design (UX/UI) Bootcamp for Beginners',
            date:'05 Agustus 2021',
            jumlah:'123'
          },
        ],
        dates:[],
    }),
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>

<style>
</style>