/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import axios from "axios";
import url from "./api";

const state = () => {
    return {
        inputForm: {}
    };
};

const mutations = {
    SET_inputForm: (state, value) => {
        state.inputForm = value
    }
};

const getters = {

};

const actions = {
    getListInvoiceDisbursement({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_invoice + 'status=' + inputForm.status + '&date=' + inputForm.date + '&limit=100' )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    
    getListPaymentLink({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_payment_link + 'status=' + inputForm.status + '&date=' + inputForm.date + '&limit=100' )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    getListIncomingTransfer({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .get(url.list_incoming_transfer + '&status=' + inputForm.status + '&transaction_type=' + inputForm.type + '&date=' + inputForm.date + '&limit=100' )
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    createPayment({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .post(url.create_payment, inputForm, { headers: {"Content-Type": "application/json"} })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },

    createInvoice({ commit }, inputForm) {
        return new Promise((resolve, reject) => {
            axios
            .post(url.create_invoice, inputForm, { headers: {"Content-Type": "application/json"} })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
};

export default { state, mutations, getters, actions };
