<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Approved Detail</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
				<div class="row mb-5 mt-5">
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">TOTAL AMOUNT</p>
                <p class="fs-18 text-black font-weight-medium">Rp {{ formatPrice(dataDetail.total_amount) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">TOTAL TRANSACTION</p>
                <p class="fs-18 text-black font-weight-medium">{{ dataDetail.total_receipient }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">TOTAL SUCCESS AMOUNT</p>
                <p class="fs-18 text-black font-weight-medium">Rp {{ formatPrice(dataDetail.total_amount) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">TRANSACTION ISSUES</p>
                <p class="fs-18 text-black font-weight-medium">{{ dataDetail.recurring_date || '-' }}</p>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="row mb-5 white">
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">DISBURSEMENT TITLE</p>
                <p class="fs-16 text-black font-weight-medium">{{ dataDetail.title }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">DESCRIPTION</p>
                <p class="fs-16 text-black font-weight-medium">{{ dataDetail.description }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-3 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">INPUT BY</p>
                <p class="fs-16 text-black font-weight-medium"><span class="text-capitalize">{{ dataDetail.creator_user_name }}</span> on {{ dataDetail.approved_at }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-3 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">APPROVED BY</p>
                <p class="fs-16 text-black font-weight-medium text-capitalize">{{ dataDetail.approved_user_name }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">STATUS</p>
                <p class="fs-16 text-black font-weight-medium">
                  <v-chip
                    class="text-uppercase"                    
                    color="success"
                    outlined
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                </p>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="table col-md-12 px-0">          
          <v-card class="px-2" elevation="1">            
            <div class="row mb-3">
              <div class="col-md-12 align-middle">
                <p class="fs-14 text-black font-weight-medium mt-2 ps-2 mb-0">List of Transaction</p>                
              </div>              
              <div class="col-md-12">
                <v-divider></v-divider>
              </div>
              <div class="col-md-6 d-none">
								<v-select
                  :items="ex"
                  placeholder="Select Status"
                  class="ma-1 mx-width-85"
                ></v-select>
              </div>
              <div class="col-md-6 text-right">
								<div class="d-flex">              		
                <v-text-field
                  outlined
                  v-model="searchApproved"
                  append-icon="$search"
                  class="pt-0 mt-1 mr-4 mx-width-85"
                  placeholder="Search"
                  solo
                  flat
                ></v-text-field>           
                <v-btn color="grey darken-4" class="white--text ma-1" style="min-height: 48px;">
                  <v-icon class="mr-2">ri-download-cloud-fill</v-icon> Download Report
                </v-btn>                
								</div>
              </div>
            </div>
            <v-data-table
              :headers="headers"
              :items="dataDetailTransaction"
              :search="searchApproved"
              item-key="id"
              class="elevation-1"
              :loading="isLoading"
              loading-text="Loading... Please wait"
						>
              <template v-slot:[`item.amount`]="{ item }">
                <span>{{ formatPrice(item.amount) }}</span>
              </template>

              <template v-slot:[`item.email`]="{ item }">
                <span>{{ item.email || '-' }}</span>
              </template>

              <template v-slot:[`item.note`]="{ item }">
                <span>{{ item.note || '-' }}</span>
              </template>

              <template v-slot:[`item.receipient_bank_code`]="{ item }">
                <span class="text-uppercase">{{ item.receipient_bank_code }}</span>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="item.status === 'success' ? '#B272AF' : ''"
                  outlined                      
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>

            <v-divider></v-divider>

            <v-card-actions>
              <div class="mr-5">
                <p class="mb-0">NUMBER OF RECIPIENT</p>
                <p class="font-weight-bold mb-0">{{ dataDetail.total_receipient }}</p>
              </div>
              <div>
                <p class="mb-0">TOTAL AMOUNT</p>
                <p class="font-weight-bold mb-0">Rp {{ formatPrice(dataDetail.total_amount) }}</p>
              </div>
              <v-spacer></v-spacer>
              <!-- <v-btn color="primary" dark class="ma-1 px-3" :to="'/bulk-rilis-disbursement'"> -->
              <v-btn color="primary" dark class="ma-1 px-3" :to="`/bulk-rilis-disbursement/${serverParams.id}`">
                Process Transaction
              </v-btn>
            </v-card-actions>
            
          </v-card>
        </div>        
      </div>
    </v-card-text>  
  </v-card>
</template>

<script>
import { formatPrice } from "@/lib/formatDate";
import axios from "axios";

export default {
  data: () => ({
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Bulk Disbursement',
        disabled: false,
        href: '/bulk-disbursement?tab=approval',
      },
      {
        text: 'Approved Detail',
        disabled: true,
        href: '#',
      },
    ],
    ex: [],
    searchApproved: "",
    headers: [
      { text: 'TRANSACTION ID.', value: 'id' },
      { text: 'BANK CODE', value: 'receipient_bank_code' },
      { text: 'ACCOUNT NO.', value: 'receipient_account_number' },
      { text: 'ACCOUNT NAME', value: 'receipient_account_name' },
      { text: 'AMOUNT', value: 'amount' },
      { text: 'EMAIL', value: 'email' },
      { text: 'STATUS', value: 'status' },
      { text: 'NOTES', value: 'note' },
    ],
    dataDetail: {},
    dataDetailTransaction: [],
    serverParams: {
      id: "",
      type: ""
    },
    isLoading:false,
  }),
  mounted() {
    this.getListBulkDisbursmentDetail2();
  },
  computed: {
    idParam: {
      get() {
        return this.$route.params.id;
      }
    }
  },
  methods: {
    getListBulkDisbursmentDetail2() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
          axios
          .get(`etransfer/disbursement/bulk/receipients/${this.idParam}` )
          .then(response => {
            this.dataDetail = response.data.result.detail;
            this.dataDetailTransaction = response.data.result.receipients.data;
            this.serverParams.id = this.dataDetail.id;
            if(this.dataDetail.type == "bulk") {
              this.serverParams.type = this.dataDetail.type;
            } else {
              this.serverParams.type = "";
            }
            this.serverParams.type = this.dataDetail.type;
            this.isLoading = false;
            resolve(response);
          })
          .catch(error => {
              reject(error);
              this.isLoading = false;
          });
      });
    },

    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style>
</style>