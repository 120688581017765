<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 pb-0 px-0 fs-20 font-weight-bold">Create Single Disbursement</v-card-title>

    <!-- <template v-if="snackbar === true">
      <v-alert
        v-model="snackbar"
        type="success"
        class="mb-0 mt-3"
        transition="scale-transition"
        dismissible
      >
        {{ showMessage }}
      </v-alert>
    </template> -->

    <v-card-text class="px-0 pt-0">
      <div class="content col-md-12 px-0">
        <v-card elevation="1">
          <v-form
            ref="form"
            class="px-8"
            @submit.prevent="submit"
            v-model="valid"
          >
            <div class="table my-3 col-md-9">          
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Recipient Bank Name</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-select
                    v-model="serverParams.receipient_bank_id"
                    :rules="[v => !!v || 'Bank name is required']"
                    :items="getBankName"
                    item-text="name"
                    item-value="id"
                    placeholder="Select"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Recipient Account Number</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="serverParams.receipient_account_number"
                    :rules="[v => !!v || 'Account number is required']"
                    type="number"
                    min="0"
                    filled
                    required
                    outlined
                    hide-details="auto"
                    placeholder="123 - xxx - xxx"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Recipient Name</v-subheader>
                </v-col>
                <v-col cols="7" class="text-right">
                  <v-text-field
                    v-model="serverParams.receipient_account_name"
                    :rules="[v => !!v || 'Recipient name is required']"
                    filled
                    required
                    outlined
                    hide-details="auto"
                    placeholder="Name of Recipient"
                  ></v-text-field>
                  <v-btn color="primary" class="mt-2" outlined>
                    Validate
                  </v-btn>  
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Enter Amount to send</v-subheader>
                </v-col>
                <v-col cols="7">
                  <!-- <v-text-field
                    v-model.number="serverParams.amount"
                    :rules="[v => !!v || 'Amount is required']"
                    type="number"
                    min="0"
                    filled
                    required
                    outlined
                    hide-details="auto"
                    placeholder="Rp. "
                  ></v-text-field> -->
                  <MyCurrencyInput v-model="serverParams.amount"></MyCurrencyInput>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Recipient Email</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="serverParams.receipient_email"
                    :rules="emailRules"
                    type="email"
                    filled
                    required
                    outlined
                    hide-details="auto"
                    placeholder="Receipt will be sent to the email address"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Reference</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="serverParams.remark"
                    :rules="[v => !!v || 'Reference is required']"
                    filled
                    required
                    outlined
                    hide-details="auto"
                    placeholder="Reference"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Description</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-textarea
                    v-model="serverParams.description"
                    :rules="[v => !!v || 'Description is required']"
                    required
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-subheader class="text-black font-weight-thin fs-14">Recuring</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-switch
                    v-model="serverParams.recurring"
                    flat
                  ></v-switch>
                </v-col>
              </v-row>
            </div>
          </v-form>

          <v-snackbar
            v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined class="ma-1 px-5" @click="resetField">
              Cancel
            </v-btn>
            <v-btn color="primary" dark class="ma-1 px-3" @click="dialog=true">
              Create Disbursement
            </v-btn>  
          </v-card-actions>        
        </v-card>
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm Disbursement
        </v-card-title>

        <v-card-text class="my-3">          
          <v-simple-table>
            <template v-slot:default>              
              <tbody>
                <tr>
                  <td>TOTAL AMOUNT</td>
                  <td>Rp {{ formatPrice(serverParams.amount) }}</td>
                </tr>
                <tr>
                  <td>SEND TO</td>
                  <td>{{ serverParams.receipient_account_number }} <br>{{ serverParams.receipient_account_name }} </td>
                </tr>
                <tr>
                  <td>NO. REFERENCE</td>
                  <td>{{ serverParams.remark }}</td>
                </tr>
                <tr>
                  <td>DESCRIPTION</td>
                  <td>{{ serverParams.description }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"            
            @click="handleStored"
            class="text-center"
            :loading="submiting"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { formatPrice } from "@/lib/formatDate";

export default {
  data: () => ({
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Single Disbursement',
        disabled: false,
        href: '/disbursement',
      },
      {
        text: 'Create Single Disbursement',
        disabled: true,
        href: '#',
      },
    ],
    valid: true,
    submiting: false,
    isError: false,
    switch1:false,
    dialog:false,
    snackbar: false,
    showMessage: '',
    emailRules: [
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    serverParams: {
      receipient_bank_id: '',
      receipient_account_name: '',
      receipient_account_number: '',
      amount: 0,
      receipient_email: '',
      remark: '',
      description: '',
      recurring: false,
    }
  }),
  computed: {
    getBankName() {
      return this.$store.state.sendMoney.getBankName;
    },
    getBankAccount() {
      return this.$store.state.sendMoney.getBankAccount;
    },
  },
  beforeCreate: function () {
    this.$options.components.MyCurrencyInput = require('../user/MyCurrencyInput.vue').default
  },
  mounted() {
    this.loadBankName();
    this.loadBankAccount();
    // console.log('object', this.getBankOpt);
  },
  methods: {
    loadBankName() {
      this.$store.dispatch("getBankName");
    },
    loadBankAccount() {
      this.$store.dispatch("getBankAccount");
    },
    handleStored() {
      setTimeout(() => {
      if (this.$refs.form.validate()) {
        this.submiting = true;
        this.axios
          .post(
            `etransfer/disbursement/create`, this.serverParams,
            {
              headers: {"Content-Type": "application/json"},
            })
          .then((response) => {
            this.isError = true;
            this.showMessage = response.data.message
            // this.$refs.form.reset();
            this.submiting = false;
            // this.dialog = false;
            setTimeout( () => {
              this.$router.push({path: '/disbursement'})
              // this.$router.push({name: 'disbursement'})
            }, 1500);
          })
          .catch((error) => {
            this.dialog = false;
            this.submiting = false;
            this.isError = true;
            this.$refs.form.reset();   
            this.showMessage = error.response.data.message;
          });
          // this.snackbar = false;
        } 
      }, 50);
    },

    resetField() {
      this.$refs.form.reset();
    },
    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style>
</style>