<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Bulk Disbursement</v-card-title>

    <v-card-text class="px-0">
      <div class="content">        
        <div class="table my-3">          
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <v-row align="center">
                <v-col lg="7">
                  <v-tabs
                    v-model="tab"
                    class="badged-tabs"
                    color="primary"
                    show-arrows="mobile"
                  >
                    <v-tab class="text-capitalize" href="#all-transaction">
                      All Transaction
                    </v-tab>
                    <v-tab class="text-capitalize" href="#need-validation">
                      Needs Validation
                    </v-tab>
                    <v-tab class="text-capitalize" href="#need-approval">
                      Needs Approval
                    </v-tab>
                    <v-tab class="text-capitalize" href="#approval">
                      Approved
                    </v-tab>                    
                  </v-tabs>
                </v-col>

                <v-col id="search" lg="5" class="justify-end align-end text-right">                  
                  <v-btn
                    class="text-right"
                    color="primary"
                    elevation="0"
                    :to="{
                      name: 'bulk-disbursement-create'
                    }"
                  >
                    Create Bulk Disbursement
                  </v-btn>
                </v-col>
              </v-row>
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>              
            </v-card-title>

            <v-tabs-items v-model="tab" class="transparent" :value="tab">
              <!-- All Transaction -->
              <v-tab-item value="all-transaction">
                <div class="row px-2 mb-3">
                  <div class="col-md-6 pr-0">
                    <div class="d-flex">                      
                      <v-select
                        :items="listStatusAllTransaction"
                        v-model="columnFilterAllTransaction.status"
                        :item-value="`value`"
                        item-text="label"
                        placeholder="Select Status"
                        class="ma-1"
                        @change="getListBulkDisbursment(columnFilterAllTransaction.listStatusAllTransaction)"
                      ></v-select>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        elevation="0"
                      >
                        <!-- :max="(new Date()).toISOString()" -->
                        <v-date-picker
                          ref="picker"
                          v-model="dates"
                          min="1950-01-01"
                          outlined
                          required
                          range
                        ></v-date-picker>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateRangeText"
                            placeholder="Start date - End date"                    
                            append-icon="ri-calendar-line"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mt-1"
                          ></v-text-field>                   
                        </template>
                      </v-menu>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex" style="justify-content: end;">
                      <v-text-field
                        outlined
                        v-model="searchAllTransaction"
                        append-icon="$search"
                        class="ma-1 mx-width-85"
                        placeholder="Search"
                        solo
                        flat
                      ></v-text-field>
                      <v-btn color="grey darken-4" class="white--text ma-2" @click="dialog=true">
                        <v-icon class="mr-2">ri-download-cloud-fill</v-icon> Export
                      </v-btn>                  
                    </div>
                  </div>
                </div>
                
                <div v-if="!listAllTransaction" class="col-md-6 mx-auto my-2">
                  <img src="@/assets/images/empty.png"> 
                  <p class="font-weight-bold text-black mb-2">No transaction history found.</p>
                  <p class="text-semu">All transaction of your Momofin account will appear here.</p>              
                </div>

                <v-data-table
                  v-else
                  v-model="selected"
                  :headers="headerslistAllTransaction"
                  :items="listAllTransaction"
                  :search="searchAllTransaction"
                  class="elevation-1"
                  :loading="isLoading"
                  loading-text="Loading... Please wait"
                  @input="rowSelected()"
                >
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ formatDate(item.created_at) }}
                  </template>

                  <template v-slot:[`item.approved_at`]="{ item }">
                    {{ parseTime(item.created_at) }}
                  </template>

                  <template v-slot:[`item.total_amount`]="{ item }">
                    {{ formatPrice(item.total_amount) }}
                  </template>

                  <template v-slot:[`item.id`]="{ item }">
                    <router-link
                      class=""
                      :to="{ name: 'bulk-detail-all', params: { id: item.id } }"
                    >
                    {{item.id}}
                    </router-link>
                  </template>
                  
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      v-if="item.status === 'wait validation'"
                      color="#FF6F00"
                      outlined
                      class="text-capitalize"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      v-else-if="item.status === 'wait approval'"
                      color="#F0AB0B"
                      outlined
                      class="text-capitalize"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      v-else-if="item.status === 'failed'"
                      color="#E53935"
                      outlined
                      class="text-capitalize"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      v-else-if="item.status === 'processing'"
                      color="#3949AB"
                      outlined
                      class="text-capitalize"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      v-else-if="item.status === 'canceled'"
                      color="#D81B60"
                      outlined
                      class="text-capitalize"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      v-else-if="item.status === 'completed'"
                      color="#0277BD"
                      outlined
                      class="text-capitalize"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      v-else
                      :color="item.status == 'approved' ? 'success' : '#ADADAD'"
                      outlined
                      class="text-capitalize"
                    >
                      {{ item.status }}
                    </v-chip>
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <template v-if="item.status === 'wait approval' || item.status === 'wait validation'">
                      <router-link :to="'/bulk-disbursement-edit/'+item.id">
                        <v-icon>
                          ri-pencil-fill
                        </v-icon>
                      </router-link>
                    </template>
                    <template v-else>
                      <div>
                        -
                      </div>
                    </template>
                  </template>
                </v-data-table>
              </v-tab-item>

              <!-- Needs Validation -->
              <v-tab-item value="need-validation">
                <div class="row px-2 mb-3">
                  <div class="col-md-6">
                    <v-text-field
                      outlined
                      v-model="searchNeedValidation"
                      append-icon="$search"
                      class="pt-0 mt-0 mr-4 mx-width-85"
                      placeholder="Search"
                      solo
                      flat
                    ></v-text-field>                
                  </div>
                  <div class="col-md-6 text-right">
                    <v-btn class="ma-1" @click="deleteItem">
                      <v-icon class="mr-2">ri-delete-bin-6-line </v-icon> Delete
                    </v-btn> 
                    <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true">
                      <v-icon class="mr-2">ri-checkbox-circle-line</v-icon> Validate
                    </v-btn>                  
                  </div>
                </div>
                
                <div v-if="!listNeedValidation" class="col-md-6 mx-auto my-2">
                  <img src="@/assets/images/empty.png"> 
                  <p class="font-weight-bold text-black mb-2">No transaction history found.</p>
                  <p class="text-semu">All transaction of your Momofin account will appear here.</p>              
                </div>

                <v-data-table
                  v-else
                  v-model="selected"
                  :headers="headersNeedValidation"
                  :items="listNeedValidation"
                  :single-select="singleSelect"
                  show-select
                  :search="searchNeedValidation"
                  class="elevation-1"
                  :loading="isLoading"
                  loading-text="Loading... Please wait"
                  @input="rowSelected()"
                >
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ formatDate(item.created_at) }}
                  </template>

                  <template v-slot:[`item.approved_at`]="{ item }">
                    {{ parseTime(item.created_at) }}
                  </template>

                  <template v-slot:[`item.total_amount`]="{ item }">
                    {{ formatPrice(item.total_amount) }}
                  </template>

                  <template v-slot:[`item.id`]="{ item }">
                    <router-link
                      class=""
                      :to="{ name: 'bulk-detail', params: { id: item.id } }"
                    >
                    {{item.id}}
                    </router-link>
                  </template>
                  
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      v-if="item.status === 'wait validation'"
                      color="#FF6F00"
                      outlined
                      class="text-capitalize"
                    >
                      {{ item.status }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-tab-item>
              
              <!-- Needs Approval -->
              <v-tab-item value="need-approval">
                <div class="row px-2 mb-3">
                  <div class="col-md-6">
                    <v-text-field
                      outlined
                      v-model="searchNeedApproval"
                      append-icon="$search"
                      class="pt-0 mt-0 mr-4 mx-width-85"
                      placeholder="Search"
                      solo
                      flat
                    ></v-text-field>                
                  </div>
                  <div class="col-md-6 text-right">
                    <v-btn class="ma-1" @click="deleteItem">
                      <v-icon class="mr-2">ri-delete-bin-6-line </v-icon> Delete
                    </v-btn> 
                    <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true">
                      <v-icon class="mr-2">ri-checkbox-circle-line</v-icon> Approve
                    </v-btn>                  
                  </div>
                </div>

                <div v-if="!listNeedApproval" class="col-md-6 mx-auto my-2">
                  <img src="@/assets/images/empty.png"> 
                  <p class="font-weight-bold text-black mb-2">No transaction history found.</p>
                  <p class="text-semu">All transaction of your Momofin account will appear here.</p>              
                </div>

                <v-data-table
                  v-else
                  v-model="selected2"
                  :headers="headersNeedApproval"
                  :items="listNeedApproval"
                  :single-select="singleSelect"
                  item-key="id"
                  :search="searchNeedApproval"
                  show-select
                  :loading="isLoading"
                  loading-text="Loading... Please wait"
                  class="elevation-1"
                >
                  <template v-slot:[`item.validated_at`]="{ item }">
                    {{ formatDate(item.validated_at) }}
                  </template>

                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ parseTime(item.validated_at) }}
                  </template>

                  <template v-slot:[`item.total_amount`]="{ item }">
                    {{ formatPrice(item.total_amount) }}
                  </template>

                  <template v-slot:[`item.id`]="{ item }">
                    <router-link
                      class=""
                      :to="{ name: 'bulk-detail-approval', params: { id: item.id } }"
                    >
                    {{item.id}}
                    </router-link>
                  </template>
                  
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      :color="item.status == 'wait approval' ? '#F0AB0B' : ''"
                      outlined
                      class="text-capitalize"
                    >
                      {{ item.status }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-tab-item>

              <!-- Approved -->
              <v-tab-item value="approval">
                <div class="row px-2 mb-3">
                  <div class="col-md-6">
                    <v-text-field
                      outlined
                      v-model="searchApproved"
                      append-icon="$search"
                      class="pt-0 mt-0 mr-4 mx-width-85"
                      placeholder="Search"
                      solo
                      flat
                    ></v-text-field>                
                  </div>
                  <div class="col-md-6 text-right">
                    <v-btn color="grey darken-4" class="white--text ma-2" @click="dialog=true">
                      <v-icon class="mr-2">ri-download-cloud-fill</v-icon> Export
                    </v-btn>                 
                  </div>
                </div>

                <div v-if="!listApproved" class="col-md-6 mx-auto my-2">
                  <img src="@/assets/images/empty.png"> 
                  <p class="font-weight-bold text-black mb-2">No transaction history found.</p>
                  <p class="text-semu">All transaction of your Momofin account will appear here.</p>              
                </div>

                <v-data-table
                  v-else
                  v-model="selected2"
                  :headers="headersApproved"
                  :items="listApproved"
                  item-key="id"
                  :search="searchApproved"
                  :loading="isLoading"
                  loading-text="Loading... Please wait"
                  class="elevation-1"
                >
                  <template v-slot:[`item.approved_at`]="{ item }">
                    {{ formatDate(item.approved_at) }}
                  </template>

                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ parseTime(item.approved_at) }}
                  </template>

                  <template v-slot:[`item.total_amount`]="{ item }">
                    {{ formatPrice(item.total_amount) }}
                  </template>

                  <template v-slot:[`item.id`]="{ item }">
                    <router-link
                      class=""
                      :to="{ name: 'bulk-detail-approved', params: { id: item.id } }"
                    >
                    {{item.id}}
                    </router-link>
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      :color="item.status == 'approved' ? 'success' : ''"
                      outlined
                      class="text-capitalize"
                    >
                      {{ item.status }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <v-snackbar
            v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>        
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Export Transaction History Report
        </v-card-title>

        <v-card-text class="my-3">
          <p class="mb-0">Select File Type</p>
          <v-select
            :items="ex"
            placeholder="Select Type"
            class="ma-1"
          ></v-select>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import axios from "axios";
import store from "../../store";
import { parseTime, formatDate, formatPrice } from "@/lib/formatDate";

export default {
  data: () => ({
    activeTab: "",
    dialog:false,
    menu:false,
    isLoading:false,
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Bulk Disbursement',
        disabled: true,
        href: '#',
      }
    ],
    ex: [],
    searchAllTransaction: '',
    listStatusAllTransaction: [],
    listAllTransaction: [],
    headerslistAllTransaction: [
      { text: 'DATE', value: 'created_at' },
      { text: 'TIME', value: 'approved_at' },
      { text: 'REF CODE', value: 'id' },
      { text: 'TITLE OF TRANSFER', value: 'title' },
      { text: 'DESCRIPTION', value: 'description' },
      { text: 'RECIPIENT', value: 'total_receipient' },
      { text: 'INPUTTER', value: 'creator_user_name' },
      { text: 'AMOUNT', value: 'total_amount', align: 'right' },
      { text: 'STATUS', value: 'status' },
      { text: 'ACTION', value: 'action', align: 'center' },
    ],
    columnFilterAllTransaction: {
      status: [],
      date: [],
    },
    searchNeedValidation: '',
    listNeedValidation: [],
    headersNeedValidation: [
      { text: 'DATE', value: 'created_at' },
      { text: 'TIME', value: 'approved_at' },
      { text: 'REF CODE', value: 'id' },
      { text: 'TITLE OF TRANSFER', value: 'title' },
      { text: 'DESCRIPTION', value: 'description' },
      { text: 'RECIPIENT', value: 'total_receipient' },
      { text: 'INPUTTER', value: 'creator_user_name' },
      { text: 'AMOUNT', value: 'total_amount', align: 'right' },
      { text: 'STATUS', value: 'status' },
    ],
    searchNeedApproval: '',
    headersNeedApproval: [
      { text: 'DATE', value: 'validated_at' },
      { text: 'TIME', value: 'created_at' },
      { text: 'REF CODE', value: 'id' },
      { text: 'TITLE OF TRANSFER', value: 'title' },
      { text: 'DESCRIPTION', value: 'description' },
      { text: 'RECIPIENT', value: 'total_receipient' },
      { text: 'INPUTTER', value: 'creator_user_name' },
      { text: 'AMOUNT', value: 'total_amount', align: 'right' },
      { text: 'STATUS', value: 'status' },
    ],
    listNeedApproval: [],
    searchApproved: '',
    headersApproved: [
      { text: 'DATE', value: 'approved_at' },
      { text: 'TIME', value: 'created_at' },
      { text: 'REF CODE', value: 'id' },
      { text: 'TITLE OF TRANSFER', value: 'title' },
      { text: 'DESCRIPTION', value: 'description' },
      { text: 'RECIPIENT', value: 'total_receipient' },
      { text: 'INPUTTER', value: 'creator_user_name' },
      { text: 'AMOUNT', value: 'total_amount', align: 'right' },
      { text: 'STATUS', value: 'status' },
    ],
    listApproved: [],
    headers: [
      { text: 'DATE', value: 'date' },
      { text: 'TIME', value: 'time' },
      { text: 'REF CODE', value: 'ref' },
      { text: 'TITLE OF TRANSFER', value: 'title' },
      { text: 'DESCRIPTION', value: 'desc' },
      { text: 'RECIPIENT', value: 'recip' },
      { text: 'INPUTTER', value: 'input' },
      { text: 'AMOUNT', value: 'amount', align: 'right' },
      { text: 'STATUS', value: 'status' },
    ],
    dates:[],
    singleSelect: false,
    selected: [],
    selected2: [],
    // tab:null,
    showMessage: '',
    isError: false,
    
  }),
  async created() {
    await this.getListBulkDisbursment();
    await this.getListBulkDisbursmentNeedValidation();
    await this.getListBulkDisbursmentNeedApproval();
    await this.getListBulkDisbursmentApproved();
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    }
  },
  methods: {
    getListBulkDisbursment() {
      this.isLoading = true;
      this.$store.dispatch("getListBulkDisbursment", this.columnFilterAllTransaction)
      .then(response => {
        if (response.data.code == 401) {
          this.isError = true;
          this.showMessage = response.data.message;
          setTimeout( () => {
            localStorage.removeItem("momofin_auth");
            delete axios.defaults.headers.common["x-access-token"];
            store.commit("RESET_USER");
            this.$router.push({name: "login"})
          }, 1000);
        } else {
          const { data } = response;
          this.listAllTransaction = data.result.disbursement;
          this.listStatusAllTransaction = data.result.filter.status;
          this.isLoading = false;
        }
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },

    getListBulkDisbursmentNeedValidation() {
      this.isLoading = true;
      this.$store.dispatch("getListBulkDisbursmentNeedValidation")
      .then(response => {
        const { data } = response;
        this.listNeedValidation = data.result.disbursement;
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },

    getListBulkDisbursmentNeedApproval() {
      this.isLoading = true;
      this.$store.dispatch("getListBulkDisbursmentNeedApproval")
      .then(response => {
        const { data } = response;
        this.listNeedApproval = data.result.disbursement;
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },

    getListBulkDisbursmentApproved() {
      this.isLoading = true;
      this.$store.dispatch("getListBulkDisbursmentApproved")
      .then(response => {
        const { data } = response;
        this.listApproved = data.result.disbursement;
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },

    rowSelected() {
      console.log(this.selected.map(e => e.id));
      if (this.selected.length == this.listAllTransaction) {
        alert('selected all')
      }
    },
    
    deleteItem () {
      for(var i = 0; i < this.selected.length; i++){
        const index = this.listAllTransaction.indexOf(this.selected[i]);
        console.log('object',index);
        this.listAllTransaction.splice(index, 1);
      }
      this.dialog = false
    },

    formatDate(value) {
      return formatDate(value);
    },
    formatPrice(value) {
      return formatPrice(value);
    },
    parseTime(value) {
      return parseTime(value);
    },
  },
};
</script>

<style>
</style>