export default {
    admin_login: 'auth/dashboard/login',
    admin_detail: 'auth/users/profile',
    certificate_list: 'certificate/certificate',
    recipient_list: 'certificate/certificate/recipient/',
    recipient_all: 'certificate/recipient/list',
    recipient_create: 'certificate/recipient/create',
    recipient_delete: 'certificate/recipient/delete',
    publish_certificate: 'certificate/publish',
    download_example_excel: 'certificate/recipient/download_excel',
    design_request: 'certificate/design/upload_design',
    certificate_template: 'certificate/template/',
    design_list: 'certificate/design',
    template_create: 'certificate/template/create/',
    template_edit: 'certificate/template/edit/',
    template_list: 'certificate/template/',
    template_detail: 'certificate/template/',
    cridetial_list: 'certificate/credential',
    cridetial_detail: 'certificate/credential/',
    certificate_templates: 'certificate/template',
    recipient_edit: 'certificate/recipient/edit',
    recipient_import: 'certificate/recipient/import-excel',
    recipient_list_not_publish: 'certificate/recipient/list/',

    get_bank_name: 'etransfer/bank',
    get_bank_account: 'etransfer/bank_account',
    // Deposit
    get_deposit_balance: 'etransfer/balance',
    get_company_bank: 'etransfer/bank/destination',
    request_deposit: 'etransfer/balance/deposit',
    request_deposit_confirmation: 'etransfer/balance/deposit-confirmation',
    // Invoice
    list_invoice: 'etransfer/invoice?',
    create_invoice: 'etransfer/invoice/create',
    // Payment Link
    list_payment_link: 'etransfer/payment_link?',
    create_payment: 'etransfer/payment_link/create',
    // Disbursemenet
    list_disbursement: 'etransfer/disbursement/list?',
    summary_transaction_disbursement: 'etransfer/disbursement/summary',
    // Bulk Disbursemenet
    list_bulk_disbursement: 'etransfer/disbursement/bulk/list?',
    validate_need_validation: 'etransfer/disbursement/validate',
    validate_need_approval: 'etransfer/disbursement/approval',
    // Rilis Disbursemenet
    approve_disbursement: 'etransfer/disbursement/approve',
    release_code: 'etransfer/disbursement/release_code',
    release_disbursement: 'etransfer/disbursement/release',
    // Schedule Disbursemenet
    list_schedule_disbursement: 'etransfer/schedule?',
    create_schedule: 'etransfer/schedule/create',
    // Transaction History
    list_transaction_history: 'etransfer/transaction?',
    balance_transaction_history: 'etransfer/balance',
    // Incoming Transfer
    list_incoming_transfer: 'etransfer/transaction?type=in',
    // Pembelian Product
    all_inventory: 'econtract/inventory/all',
    create_order: 'econtract/inventory/client/order'
}