<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Certificate</v-card-title>

    <v-card-text class="px-0">
      <div class="content">        
        <div class="table my-3">          
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <v-row align="center">
                <v-col lg="6">
                  <p class="fs-16 text-black font-weight-medium">List of Certificate</p>                  
                </v-col>

                <v-col id="search" lg="6" class="justify-end align-end text-right">                  
                  <v-btn
                    class="text-right"
                    color="primary"
                    elevation="0"
                    :to="{
                      name: 'create-certificate'
                    }"
                  >
                    Buat Sertifikat
                  </v-btn>
                </v-col>
              </v-row>
              <div class="col-md-12 px-0 py-4">                
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-6">                  
                  <v-text-field
                      outlined
                      append-icon="$search"
                      class="mt-1 ms-2 mx-width-85"
                      placeholder="Search"
                      solo
                      flat
                    ></v-text-field>
                </div>
                <div class="col-md-6 text-end align-end">
                  <v-text-field
                    outlined
                      append-icon="ri-filter-fill"
                      class="mt-1 ms-2 mx-width-85 float-right"
                      placeholder="Filter"
                      solo
                      flat
                  ></v-text-field>                                  
                </div>
              </div>              
            </v-card-title>
            <div v-if="!desserts" class="col-md-6 mx-auto my-2">
              <img src="@/assets/images/certif-empty.svg"> 
              <p class="font-weight-bold text-black mb-2">Sertifikat Kosong</p>
              <p class="text-semu">Buat sertifikat baru atau coba persempit pencarian jika anda tidak dapat menemukan sertifikat yang anda cari</p>              
            </div>
            <v-data-table
              v-else
              :headers="headers"
              :items="desserts"
              :search="search"
            >
              <template v-slot:item.judul="{ item }">
                <router-link
                  class="primary--text"
                  :to="{ name: 'certificate-detail', params: { id: 123 } }"
                >
                <!-- <div class="align-self-center">                  
                  <img src="@/assets/images/example.png" class="img-table my-2 mr-1">
                  <span>{{item.judul}}</span>
                </div> -->
                <v-list three-line>                  
                  <v-list-item class="pl-0">
                    <img src="@/assets/images/example.png" class="img-table mr-1 ms-0">
                    <v-list-item-content class="primary--text">
                      {{item.judul}}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>                  
                </router-link>
              </template>
              <template v-slot:[`item.aksi`]="{ item }">
                <v-btn outlined color="primary" class="mr-2 mb-1" small @click="ubah(item)">Pratinjau</v-btn>
                <v-btn outlined color="primary" class="mb-1" small @click="ubah(item)">Ubah</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </div>        
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    data: () => ({
      dialog:false,
      menu:false,
      items: [
        {
          text: 'E-Certificate',
          disabled: false,
          href: '#',
        },
        {
          text: 'Certificate',
          disabled: true,
          href: '#',
        },        
      ],
      ex: ['1','2','3'],
      search: '',
        headers: [
          {
            text: 'JUDUL SERTIFIKAT',
            align: 'start',
            sortable: false,
            value: 'judul',
          },
          { text: 'TANGGAL DITERBITKAN', value: 'date' },
          { text: 'JUMLAH PENERIMA', value: 'jumlah' },
          { text: '', value: 'aksi' },          
        ],
        desserts: [
          {
            judul:'Product Design (UX/UI) Bootcamp for Beginners',
            date:'05 Agustus 2021',
            jumlah:'123'
          },
        ],
        dates:[],
    }),
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>

<style>
</style>