<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Single Disbursement</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="row mb-5">
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">TOTAL COMPLETED</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(total_completed) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">TOTAL PENDING</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(total_pending) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">TOTAL FAILED</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(total_failed) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">TOTAL TRANSACTION</p>
                <p class="fs-18 text-black">{{ count }}</p>
              </v-card-text>
            </v-card>
          </div>
        </div>

        <div class="table my-3">          
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <div class="row">
                <div class="col-md-6">
                  <p class="fs-16 text-black font-weight-medium">List of Transaction</p>
                </div>
                <div class="col-md-6 text-right">
                  <v-btn color="primary" class="white--text" :to="{ name: 'disbursement-create'}">
                    Create Single Disbursement
                  </v-btn>
                </div>
              </div>
            </v-card-title>

            <div class="row">
              <div class="col-md-6 pr-0">
                <div class="d-flex">
                  <v-select
                    v-model="columnFilter.status"
                    :items="ex"
                    :item-value="`value`"
                    item-text="label"
                    placeholder="Select Status"
                    @change="getListDisbursement(columnFilter.ex)"
                    class="ma-1"
                  ></v-select>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    elevation="0"
                  >
                    <v-date-picker
                      ref="picker"
                      v-model="columnFilter.date"
                      min="1950-01-01"
                      outlined
                      required
                      range
                      @change="getListDisbursement(columnFilter.date)"
                    ></v-date-picker>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        placeholder="Start date - End date"                    
                        append-icon="ri-calendar-line"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mt-1"
                      ></v-text-field>                   
                    </template>
                  </v-menu>
                </div>
              </div>
              <div class="col-md-6 px-0 align-end text-end">
                <div class="d-flex">
                  <v-text-field
                    outlined
                    v-model="search"
                    append-icon="$search"
                    class="mt-1 ms-2 mx-width-85"
                    placeholder="Search"
                    solo
                    flat
                  ></v-text-field>
                  <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true" style="min-height: 48px;">
                    <v-icon class="mr-2">ri-download-cloud-fill </v-icon> Download Report
                  </v-btn>                  
                </div>               
              </div>
              <!-- <div class="col-md-3">
              </div> -->
            </div>

            <div v-if="!listTransaction" class="col-md-6 mx-auto my-2">
              <img src="@/assets/images/empty.png"> 
              <p class="font-weight-bold text-black mb-2">No Disbursement transaction found.</p>
              <p class="text-semu">All transaction of your Momofin account will appear here.</p>
              <v-btn color="primary" class="ma-1" outlined>
                Create Disbursement
              </v-btn>
            </div>

            <v-data-table
              v-else
              :headers="headers"
              :items="listTransaction"
              :search="search"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >
              <!-- :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event" -->
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDate(item.created_at) }}
              </template>

              <template v-slot:[`item.approved_at`]="{ item }">
                {{ parseTime(item.approved_at) }}
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                {{ formatPrice(item.amount) }}
              </template>

              <!-- <template v-slot:item.id="{ item }"> -->
              <template v-slot:[`item.id`]="{ item }">
                <router-link
                  class=""
                  :to="{ name: 'disbursement-detail', params: { id: item.id } }"
                >
                {{item.id}}
                </router-link>
              </template>
              <!-- <template v-slot:item.status="{ item }"> -->
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status === 'wait validation'"
                  color="#FF6F00"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'wait approval'"
                  color="#F0AB0B"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'failed'"
                  color="#E53935"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'canceled'"
                  color="#D81B60"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'completed'"
                  color="#0277BD"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else
                  :color="item.status == 'approved' ? 'success' : '#ADADAD'"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
            <!-- <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="pageCount"
              ></v-pagination>
              <v-text-field
                :value="itemsPerPage"
                label="Items per page"
                type="number"
                min="-1"
                max="15"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-text-field>
            </div> -->

          </v-card>

          <v-snackbar
            v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>        
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Export Transaction History Report
        </v-card-title>

        <v-card-text class="my-3">
          <p class="mb-0">Select File Type</p>
          <v-select
            :items="ex"
            placeholder="Select Type"
            class="ma-1"
          ></v-select>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import store from "../../store";
import { parseTime, formatDate, formatPrice } from "@/lib/formatDate";
import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    dialog:false,
    menu:false,
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Single Disbursement',
        disabled: true,
        href: '#',
      }
    ],
    search: '',
    headers: [
      {
        text: 'TRANSACTION ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      { text: 'DATE', value: 'created_at' },
      { text: 'TIME', value: 'approved_at' },
      { text: 'DESCRIPTION', value: 'description' },
      { text: 'REMARK', value: 'remark' },
      { text: 'CHANNEL', value: 'receipient_bank_name' },
      { text: 'AMOUNT (RP)', value: 'amount' },
      { text: 'STATUS', value: 'status' },
    ],
    ex: [],
    columnFilter: {
      status: [],
      date: [],
    },
    showMessage: '',
    isError: false,
    isLoading:false,
    listTransaction: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),
  mounted() {
    this.getListDisbursement();
    this.getSummaryDisbursement();
  },
  computed: {
    dateRangeText () {
      return this.columnFilter.date.join(' ~ ')
    },
    ...mapGetters({
      total_completed: 'getTotCompleted',
      total_failed: 'getTotFailed',
      total_pending: 'getTotPending',
      count: 'getCount',
    }),
  },
  methods: {
    ...mapActions({ getSummaryDisbursement: 'getSummaryDisbursement' }),

    getListDisbursement() {
      this.isLoading = true;
      this.$store.dispatch("getListDisbursement", this.columnFilter)
      .then(response => {
        if (response.data.code == 401) {
          this.isError = true;
          this.showMessage = response.data.message;
          setTimeout( () => {
            localStorage.removeItem("momofin_auth");
            delete axios.defaults.headers.common["x-access-token"];
            store.commit("RESET_USER");
            this.$router.push({name: "login"})
          }, 500);
        } else {
          const { data } = response;
          this.listTransaction = data.result.disbursement;
          this.ex = data.result.filter.status;
          this.page = data.result.pagination.total_page;
          this.pageCount = data.result.pagination.total_data;
          // this.itemsPerPage = data.result.pagination.total_display;
          this.isLoading = false;
        }
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
        this.isError = true;
        this.showMessage = error.response.data.message;
      });
    },

    formatDate(value) {
      return formatDate(value);
    },
    formatPrice(value) {
      return formatPrice(value);
    },
    parseTime(value) {
      return parseTime(value);
    },
  },
};
</script>

<style>
</style>