<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 pb-0 px-0 fs-20 font-weight-bold">Rilis Disbursement</v-card-title>

    <v-row>
      <v-col cols="8">
        <v-card-text class="px-0">
          <v-form
            ref="forms"
            @submit.prevent="submit"
            v-model="valid"
          >
            <v-card elevation="1" class="mt-4">  
              <v-card-title class="text-black fs-20 font-weight-bold">Select a funding source</v-card-title>

              <v-divider></v-divider>

              <div class="px-5 py-5 bg-grey">
                <v-row class="bg-grey">
                  <v-col cols="6">
                    <!-- <p class="font-weight-thin text-black mb-0">Amount to Add Fund</p> -->
                    <p class="font-weight-thin text-black mb-0">Your Balance</p>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-content-end">
                    <div class="d-flex" style="flex-direction:column;">
                      <h2 class="text-black mb-0">Rp {{ formatPrice(dataDetail.total_amount) }}</h2>
                      <!-- <h2 class="text-red mb-0">Rp 20.000.000</h2>
                      <p class="mt-2"><v-icon class="mr-1" color="#FF9800">ri-error-warning-fill</v-icon> Balance is insufficient. </p> -->
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <v-row class="py-6 px-5">
                <v-col cols="12">
                  <v-radio-group
                    v-model="serverParams.payment_channel"
                    row
                    :rules="[v => !!v || 'Type disbursement is required']"
                    class="mt-0"
                    @change="checkBankTransfer(serverParams.payment_channel)"
                  >
                    <v-radio
                      :disabled="disabled"
                      class="type_fund"
                      label="Use Balance"
                      value="balance"
                    ></v-radio>
                    <v-radio
                      class="type_fund d-none"
                      label="Bank Transfer"
                      value="transfer"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row class="py-6 px-5" v-show="showBankList">
                <v-col cols="12">
                  <v-radio-group
                    v-model="serverParams.bank_id"
                    row
                    v-for="(list, idx) in listBank" :key="idx"
                  >
                    <v-radio
                      class="deposit"
                      :value="list.id"
                    >
                      <template v-slot:label>
                        <div><img :src="list.image"></div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row class="py-6 px-5">
                <v-col cols="12">
                  <p class="font-weight-thin text-black mb-0">
                    You have the option to process the transaction with your balance or with a direct bank transfer. If your balance is insufficient, your transaction will be completed by bank transfer.
                  </p>
                </v-col>
              </v-row>

              <v-row class="px-5 py-5 d-none">
                <v-col cols="4">
                  <v-subheader class="text-black font-weight-thin fs-14">Select Bank </v-subheader>
                </v-col>
                <v-col cols="8" class="d-flex flex-column-sm">
                  <v-radio-group
                    v-model="serverParams.bank_id"
                    row
                    v-for="(list, idx) in listBank" :key="idx"
                  >
                    <v-radio
                      class="deposit"
                      :value="list.id"
                    >
                      <template v-slot:label>
                        <div><img :src="list.image"></div>
                      </template>
                    </v-radio>
                  </v-radio-group>

                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-card-actions>
                <div class="row">
                  <div class="col-sm-12 text-right px-0">
                    <v-btn color="primary" outlined class="mr-2 px-5" @click="validateField">
                      Cancel
                    </v-btn>
                    <v-btn color="primary" dark class="px-3" v-on:click="requestOtp()" :loading="isLoading">
                      Continue
                    </v-btn>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>

          <v-snackbar
              v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
              Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card-text>
      </v-col>
    </v-row>

    <v-dialog
      v-model="pin"
      width="500"
      persistent
    >
      <v-card class="">
        <div class="text-end">
          
        <v-btn
          icon
          dark
          @click="pin = false"
          color="#333"
          class="float-right"
          >
            <v-icon>ri-close-fill</v-icon>
          </v-btn> 
        </div>

        <v-card-text class="my-3 text-center py-5">
          <img src="@/assets/images/img-otp.png" class="mb-3">
          <p class="text-black fs-16 font-weight-medium">Please input your PIN</p>          
          <v-row>
            <v-col
              id="kode"
              cols="12"
              sm="8"
              class="mx-auto"
            >
              <v-text-field
                required
                class="mb-4"
                type="number"
                min="0"
                v-model="code"
                :rules="nameRules"
              ></v-text-field>

              <v-btn color="primary" dark class="mt-4" v-on:click="handleSubmit()" :loading="isLoading">
                Continue
              </v-btn>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { formatPrice } from "@/lib/formatDate";
import axios from "axios";

export default {
  data: () => ({
    valid: true,
    isLoading: false,
    disabled: false,
    showBankList: false,
    pin:false,
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Bulk Disbursement',
        disabled: false,
        href: '/bulk-disbursement',
      },
      {
        text: 'Approved',
        disabled: false,
        href: '/bulk-disbursement',
      },
      {
        text: 'Rilis Disbursement',
        disabled: true,
        href: '#',
      },
    ],
    isError: false,
    submiting: false,
    showMessage: "",
    listBank: [],
    serverParams: {
      // bank_id: "",
      disbursement_id: "",
      otp_code: "",
      payment_channel: ""
    },
    paramOtp: {
      disbursement_id: ""
    },
    code: "",
    dataDetail: {},
    nameRules: [
      v => !!v || 'Pin is required',
      v => (v && v.length == 6) || 'Pin must be 6 characters',
    ],
  }),
  mounted() {
    this.getListBulkDisbursmentDetail2();
    this.getListBankDestination();
  },
  computed: {
    idParam: {
      get() {
        return this.$route.params.id;
      }
    },
  },
  methods: {
    getListBulkDisbursmentDetail2() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
          axios
          .get(`etransfer/disbursement/bulk/receipients/${this.idParam}` )
          .then(response => {
            this.dataDetail = response.data.result.detail;
            // console.log('object', this.dataDetail);
            this.paramOtp.disbursement_id = this.dataDetail.id;
            this.isLoading = false;
            resolve(response);
          })
          .catch(error => {
              reject(error);
              this.isLoading = false;
          });
      });
    },
    
    getListBankDestination() {
      this.$store.dispatch("getListBankDestination")
      .then(response => {
        const { data } = response;
        this.listBank = data.data;
        // console.log('cek', this.idParam, this.typeParam );
      })
      .catch(error => {
        console.log(error);
      });
    },

    requestOtp() {
      this.pin = true;
      setTimeout(() => {
        this.isLoading = true;
        this.$store.dispatch("requestOtp", this.paramOtp)
        .then((response) => {
          const { data } = response;
          this.code = data.data.code;
          this.isError = true;
          this.showMessage = response.data.message;
          this.isLoading = false;
          this.serverParams.disbursement_id = this.dataDetail.id;
          this.serverParams.otp_code = this.code;
        })
        .catch((error) => {
          this.isLoading = false;
          this.isError = true;
          this.showMessage = error.response.data.message;
        });
      }, 500);
    },

    handleSubmit() {
      // console.log('cek', this.serverParams );
      setTimeout(() => {
        this.isLoading = true;
        this.$store.dispatch("releaseDisbursement", this.serverParams)
        .then((response) => {
          this.isError = true;
          this.showMessage = response.data.message;
          this.isLoading = false;
          setTimeout( () => {
            this.$router.push({path: '/history-money'})
          }, 1000);
        })
        .catch((error) => {
          this.isLoading = false;
          this.isError = true;
          this.showMessage = error.response.data.message;
        });
      }, 50);
    },

    checkBankTransfer(type) {
      if(type === 'bank_transfer') {
        this.disabled = true;
        this.showBankList = true;
      }
    },

    formatPrice(value) {
      return formatPrice(value);
    },

    validateField() {
      this.$refs.forms.validate();
    }
  },
};
</script>

<style scoped lang="scss">
  .justify-content-end {
    justify-content: end;
  }
  .justify-content-center {
    justify-content: center;
  }
  .bg-grey {
    background-color: #F9F9F9;
  }
  .v-radio.type_fund.v-radio--is-disabled  {
    border-color: #E0E0E0;
    background-color: #BDBDBD;
    color: #EEEEEE !important;
  }
  .v-radio.type_fund.v-item--active {
    border-color: #409eff;
    background-color: #409eff;
    color: #fff !important;
  }
  .v-radio.type_fund:hover {
    border-color: #409eff;
    background-color: #409eff;
    color: #fff !important;
  }
  
  .v-radio.type_fund {
    padding: 10px 20px 10px 10px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
  }
</style>

<style>
  .v-radio.type_fund .v-input--selection-controls__input {
    display: none !important;
  }
  .type_fund .theme--light.v-label {
    color: inherit;
  }

  .v-radio.deposit.theme--light.v-item--active {
    border: 2px solid #409eff;
  }
  .v-radio.deposit:hover {
    border-color: #409eff;
  }
  .v-radio.deposit .v-input--selection-controls__input {
    display: none;
  }
  .v-radio.deposit {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
  }
  .v-radio.deposit img {
    width: 100px;
  }
  .deposit .theme--light.v-label {
    color: inherit;
  }
</style>